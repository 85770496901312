import { Alert, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { resetSnackBarAction } from '../../redux/actions/snackbarAction';
import { styled } from '@mui/styles';
import moment from 'moment';
import { updateToken } from '../../redux/actions/loginaction';
import { sessionExpired } from '../../redux/actions/commonAction';


const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: 'white', // your custom color
  color: "black"
 }));
 

const CustomSnackBar = ({snackBarData,resetSnackBarData, updateTokens,sessionExpired}) => {
 
    const [snackBarState, setSnackBarState] = useState(undefined);
    const [open, setOpen] = useState(false);
    
    const [isMatched, setIsMatched] = useState(false);

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          // Do something when tab is switched
        } else {
          // Do something when tab is entered
          const localTimes = moment().format('YYYY-MM-DD HH:mm:ss'); // Get current local time
          const localRefreshExp = moment.utc(localStorage.getItem("refreshTokenExpiryTime")).local().format('YYYY-MM-DD HH:mm:ss')
          const localExpTimes = localStorage.getItem("tokenExpiryTime")
          const expiryTimes =  moment.utc(localExpTimes).local().format('YYYY-MM-DD HH:mm:ss')
          const timeObjects = moment(expiryTimes, 'YYYY-MM-DD HH:mm:ss');
          const decreasedTimes = timeObjects.subtract(30, 'seconds').format('YYYY-MM-DD HH:mm:ss');
          if (localTimes > decreasedTimes) {
            setIsMatched(true);
          } else {
            setIsMatched(false);
          }
    
          if(localTimes > localRefreshExp) {
            sessionExpired('Session Expired');
          } else {

          }
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, []);

    useEffect(() => {
            const localRefreshExp = moment.utc(localStorage.getItem("refreshTokenExpiryTime")).local().format('YYYY-MM-DD HH:mm:ss')
            // const localRefreshExp = moment.utc('2024-10-11 10:30:01').local().format('YYYY-MM-DD HH:mm:ss')
      const initApi = async() => {
        const localTimes = moment().format('YYYY-MM-DD HH:mm:ss'); // Get current local time

        const localExpTimes = localStorage.getItem("tokenExpiryTime")
        const expiryTimes =  moment.utc(localExpTimes).local().format('YYYY-MM-DD HH:mm:ss')
        const timeObjects = moment(expiryTimes, 'YYYY-MM-DD HH:mm:ss');
        const decreasedTimes = timeObjects.subtract(30, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        if (localTimes > decreasedTimes) {
          setIsMatched(true);
        } else {
          setIsMatched(false);
        }
  
        if(localTimes > localRefreshExp) {
          await sessionExpired('Session Expired');
        } else {
  
        }
      }
      initApi()
      const checkDateTimeMatch = async() => {
        const localExpTime = localStorage.getItem("tokenExpiryTime")
        const expiryTime =  moment.utc(localExpTime).local().format('YYYY-MM-DD HH:mm:ss')
        const localTime = moment().format('YYYY-MM-DD HH:mm:ss'); 
        const timeObject = moment(expiryTime, 'YYYY-MM-DD HH:mm:ss');
        const decreasedTime = timeObject.subtract(30, 'seconds').format('YYYY-MM-DD HH:mm:ss');
  
        // Compare current date and time with the target date and time
        if (localTime > decreasedTime) {
          setIsMatched(true);
        } else {
          setIsMatched(false);
        }
        if(localTime > localRefreshExp) {
         await sessionExpired('Session Expired');
        }
      };
  
      const intervalId = setInterval(checkDateTimeMatch, 20000); 
  
      return () => clearInterval(intervalId); 
    }, []);
  
    useEffect(() => {
      if (isMatched) {
        const localRefreshToken = localStorage.getItem("refreshToken")
        // Run your code here if the current time and date match the desired time and date
        updateTokens({refresh_token: localRefreshToken})
        setIsMatched(false);
      }
    }, [isMatched]);

    useEffect(() => {
      if(snackBarData?.openStatus === true && !snackBarState){
          setSnackBarState({...snackBarData});
          resetSnackBarData();
          setOpen(true);
          }else if(snackBarData?.openStatus === true && snackBarState && open){
          setOpen(false);
          }
    },[snackBarData, snackBarState, open])
  

    const handleExited = () => {
      setSnackBarState(undefined);
    };
  
    const resetSnackBar = () => {   
      setOpen(false);
    }

    const borderColor = {
      success:"green",
      error: "red"      
    }
  
    return (
      <>
      <Snackbar open={open} autoHideDuration={4000} onClose={() => resetSnackBar()} TransitionProps={{ onExited: handleExited }} ClickAwayListenerProps={{ onClickAway: () => null }} anchorOrigin={{vertical: 'bottom', horizontal: "center"}}>
    <StyledAlert onClose={() => resetSnackBar()} sx={{ width: '100%', borderLeft: `10px solid ${borderColor[snackBarState?.severity]}`}} variant="filled" severity={snackBarState?.severity} >
      {snackBarState?.message}
    </StyledAlert>
  </Snackbar>
  </>
    )
}

const mapStateToProps = (state) => {
    return {
        snackBarData: state.SnackBarReducer.snackBarData,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        resetSnackBarData: () => dispatch(resetSnackBarAction()),
        updateTokens:(data) => dispatch(updateToken(data)),
        sessionExpired:(data) => dispatch(sessionExpired(data)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(CustomSnackBar)

 