import { Box, Button, Checkbox, Fade, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Modal, OutlinedInput, Pagination, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from '../../Assets/icons/search.svg';
import { ReactComponent as CheckboxIcon } from '../../Assets/icons/CheckboxIcon.svg';
import { ReactComponent as NotificationIcon } from '../../Assets/icons/OutlinedNotification.svg';
import { ReactComponent as NotificationTrueIcon } from '../../Assets/icons/NotificationBell.svg';
import { ReactComponent as MoreIcons } from '../../Assets/icons/more-alt.svg';
 import { AnnouncementActiveColumn, AnnouncementInactiveColumn, AnnouncementScheduledColumn } from '../../components/Data/Data'
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as announcementAction from '../../redux/actions/announcement'
import { getTimeStamp } from "../../Utils/utils";
import ToastAlert from "../common/Alert";
import AnnouncementModalView from "./AnnouncementModalView";

const StyledTableCell = styled(TableCell)({
    color: '#0F2C6F',
    fontSize: '13px',
    fontWeight: 550
})

const StyledBodyTableCell = styled(TableCell)({
    color: "#0F2C6F",
    fontSize: "14px",
    fontWeight: 500
})


const StyledTableRow = styled(TableRow)({
    '&:nth-of-type(odd)': {
        backgroundColor: "#F8F9FB"
    }
})

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "10px",
     boxShadow: 24,
   };

const AnnouncementTable = ({ activetype, announcementList, getAnnouncementsList, announcementCounts,announcementTypes,getAnnouncementTypes,statusInActive,announcementStatus, tabValue,setAnnouncementStateNull, announcementPinStatus,  updateAnnouncementPinStatus }) => {


    let pinStatusClickFlag = false;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const optionsOpen = Boolean(anchorEl);
    const [selectedRow, setSelectedRow] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const [selectedId, setSelectedId] = React.useState(null);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [filterData,setFilterData] = useState({
        type:"",
        search:""
    })
    const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: '' });
    const [inactiveConfirmationModal, setInactiveConfirmationModal] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        getAnnouncementTypes()
    }, [])


    useEffect(()=>{
        let timer;
        
        timer = setTimeout(() => {            
        let data = { limit: 10, offset: 0, status: activetype,type_id:filterData.type,keyword:filterData.search,via:"list" }
        getAnnouncementsList(data)
        }, 1000);

        return () => clearTimeout(timer);
    },[filterData])

    useEffect(() => {
        if(announcementStatus?.response_status === 'success'){
            setSnackbarStatus({ message: announcementStatus.response_message, status: true, color: 'green', icon: 'tick' })
            setAnnouncementStateNull();
            let data =  { limit: 10, offset: 0, status: activetype,type_id:filterData.type,keyword:filterData.search,via:"list" };
            getAnnouncementsList(data); 
      setTimeout(() => {
                setSnackbarStatus({message: '', status: false, color: '', icon: ''})
            }, 3000)
        }   

    }, [announcementStatus])

    useEffect(() => {
        if(announcementPinStatus?.response_status === 'success'){
            setAnnouncementStateNull();
            let data =  { limit: 10, offset: 0, status: activetype,type_id:filterData.type,keyword:filterData.search,via:"list" };
            getAnnouncementsList(data); 
            pinStatusClickFlag = false;
        }
    }, [announcementPinStatus])

    const handleInactiveConfirmationModalClose = () => setInactiveConfirmationModal(false);


    const handleClick = (event, id) => {
        setSelectedId(id);
       setAnchorEl(event.currentTarget);
     };
     const handleClose = () => {                    
       setAnchorEl(null);
     };

     const handleModalClose = () => {
        setModalOpen(false);
     }

     const handleModalOpen = () => {
         setModalOpen(true);
     }


     const handleRowClick = selectedRowItem => {
         setSelectedRow(selectedRowItem);
         handleModalOpen();
     }

     const handleEdit = () => {
        navigate(`/edit_announcement/${selectedId}/${activetype}`)
     }
 
     const handleStatusInactive = () => {
        // statusInActive({id: Number(selectedId), status: "Inactive"})
        setInactiveConfirmationModal(true);
         handleClose();
     }


     const handleInactiveConfirmation = () => {
            statusInActive({id: Number(selectedId), status: "Inactive"})
            setInactiveConfirmationModal(false);
     }

     const handlePinStatus = (id, status) =>  {
         if(status === 1){            
            if(pinStatusClickFlag === false){
                updateAnnouncementPinStatus({id: id, status: 'pin'});
                pinStatusClickFlag = true;
            }            
        }else{
            if(pinStatusClickFlag === false){
                updateAnnouncementPinStatus({id: id, status: 'unpin'});
                pinStatusClickFlag = true;
            }
        }       
     }
    const columns = () => {
        let colname = activetype === 'Inactive' ? AnnouncementInactiveColumn : activetype=='Active' ? AnnouncementActiveColumn : AnnouncementScheduledColumn;
        return colname;

    }
    const handlePagignation = (event, value) => {
        let data = { limit: 10, offset: value - 1, status: activetype,via:"list" }
        getAnnouncementsList(data)
        setCurrentPage(value);
    };
    const getColumnName = () => {
        const name = columns();
        return (
            <>
                {name.map((value) => (
                    value.name === 'CheckBox' ? <TableCell><Checkbox icon={<CheckboxIcon />} /></TableCell> :
                        value.name === 'Notification' ? <TableCell /> :
                            <StyledTableCell>{value.name}</StyledTableCell>
                ))}

            </>
        )
    }
    const getColumnData = (value) => {
        let column = columns();
        return (
            <>
                {/* {column.map((value) => (
                    value.name === 'CheckBox' ? <TableCell><Checkbox icon={<CheckboxIcon />} size="small" /></TableCell> : ''
                ))} */}
                {activetype!='Inactive' && <TableCell>{value.priority === 1 ? <NotificationTrueIcon onClick={() => handlePinStatus(value.announcement_id,2)} /> : <NotificationIcon onClick={() =>   handlePinStatus(value.announcement_id,1)} />}</TableCell>}
                <StyledBodyTableCell sx={{ textDecoration: 'underline',textUnderlineOffset: "4px", cursor: "pointer",maxWidth:150 }} onClick={() => handleRowClick(value)}>
                   <Typography sx={{overflow:'hidden',textOverflow:"ellipsis"}}>{value.title}</Typography> 
                </StyledBodyTableCell>
                <StyledBodyTableCell>{getTimeStamp(value.created_at)}</StyledBodyTableCell>
                <StyledBodyTableCell>{getTimeStamp(value.published_on)}</StyledBodyTableCell>
                {tabValue ==0 && <StyledBodyTableCell sx={{textAlign:"center"}}>{value.announcement_read_count}</StyledBodyTableCell>}
                <StyledBodyTableCell>{value.submitted_by}</StyledBodyTableCell>
                {tabValue ==0 && <StyledBodyTableCell sx={{textAlign:"center"}}>{value?.is_popup_display == 1 ? 'Yes' : 'No'}</StyledBodyTableCell>}
                <StyledBodyTableCell>{value.job_role}</StyledBodyTableCell>
                {(tabValue === 0  || tabValue === 1 )&& <StyledBodyTableCell><IconButton><MoreIcons onClick={(e) => handleClick(e, value.announcement_id)} /></IconButton></StyledBodyTableCell>}
            </>
        )
    }



    return (
        <>
        { 
        inactiveConfirmationModal &&   <div>
         <Modal
          open={inactiveConfirmationModal}
          onClose={handleInactiveConfirmationModalClose}
         >
          <Box sx={style}>
            <Typography sx={{px: 4,pt: 3,pb: 1, fontSize: "20px", fontWeight: 500, color: "#002D74",}}>
                Do you want to In-active ?
            </Typography>
            <Box sx={{ borderBottom: "1px solid gray"}}></Box>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 4,py:3}}>
                <Button variant="outlined" sx={{textTransform: "none"}} onClick={() => handleInactiveConfirmationModalClose()}>Cancel</Button>
                <Button variant="contained" sx={{textTransform: "none"}} onClick={() => handleInactiveConfirmation()} >Yes</Button>
            </Box>
          </Box>
        </Modal>
      </div>
        }
        {modalOpen && <AnnouncementModalView item={selectedRow} handleModalClose={handleModalClose} />}
        <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />
         <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={optionsOpen}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => handleEdit()} sx={{margin: "0 20px"}}>Edit</MenuItem>        
        <MenuItem onClick={() => handleStatusInactive()} sx={{margin: "0 20px"}}>In-active</MenuItem>
      </Menu>
             <>
                <Grid container columnSpacing={2} sx={{ py: 1.5,px:3 }} >
                    <Grid item xs={12} sm={2}>
                        <FormControl fullWidth size="small">
                            <InputLabel sx={{ color: '#123FA9',fontWeight:600,fontSize:"14px" }}>Type</InputLabel>
                            <Select label='Type' value={filterData.type} onChange={(e)=>setFilterData({
                                ...filterData,
                                type:e.target.value
                            })} sx={{ height: '40px',boxShadow:'0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF',borderRadius:'6px' }}>
                                {announcementTypes?.map((value)=>(
                                    <MenuItem sx={{color:'#0F2C6F'}} value={value.anno_type_id}>{value.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={12} sm={1} >
                       <Typography sx={{color:"#2764F4",textDecoration:"underline",fontSize:"16px",mt:1,textUnderlineOffset:"5px",cursor:"pointer"}} onClick={()=>setFilterData({
                        type:"",
                        search:""
                       })}>Reset</Typography>
                    </Grid>
                    <Grid item xs={12} sm={7} />
                    <Grid item xs={12} sm={2} sx={{display:'flex',justifyContent:"end"}}>
                        <OutlinedInput value={filterData.search} onChange={(e)=>{
                            setFilterData({
                                ...filterData,
                                search:e.target.value
                            })
                        }} placeholder="Search..." endAdornment={<SearchIcon />} sx={{ height: '40px' }} />
                    </Grid>
                </Grid>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {getColumnName()}
                            </TableRow>
                        </TableHead>
                        {announcementList?.length > 0 && 
                        <TableBody>
                            {announcementList.map((value,index) => (
                                <StyledTableRow key={index}>
                                    {getColumnData(value)}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        }
                    </Table>
                    
                </TableContainer>

                {announcementList?.length<1 && 
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 5,backgroundColor:"rgba(143, 146, 161,0.05)" }}>
                            <Typography sx={{color:"#0F2C6F"}} variant='h6'>No Data Found</Typography>
                        </Box>
                }
                
               {announcementCounts > 0 &&  <Box sx={{ display: 'flex', justifyContent: 'center',backgroundColor:"rgba(143, 146, 161,0.05)",py:2.5 }}>
                        <Pagination count={Math.ceil(announcementCounts / 10)} page={currentPage} size="large" shape="rounded" onChange={handlePagignation} />
                    </Box>
                }
            </> 
            <div>
     
     
    </div>
                
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        announcementList: state.AnnouncementReducer.announcementList,
        announcementCounts: state.AnnouncementReducer.announcementCounts,
        announcementTypes: state.AnnouncementReducer.announcementTypes,
        announcementStatus: state.AnnouncementReducer.updateAnnouncementStatus,   
        announcementPinStatus: state.AnnouncementReducer.updateAnnouncementPinStatus,     
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAnnouncementsList: (data) => (dispatch(announcementAction.getAnnouncementsList(data))),
        getAnnouncementTypes: () => (dispatch(announcementAction.getAnnouncementTypes())),
        statusInActive: (data) => (dispatch(announcementAction.updateAnnouncement(data))),
        setAnnouncementStateNull: () => (dispatch(announcementAction.setAnnouncementStateNull())),
        updateAnnouncementPinStatus: (data) => (dispatch(announcementAction.updateAnnouncementPinStatus(data))),
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementTable);