import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import React from "react";
import { ReactComponent as Clock } from "../../Assets/icons/clock.svg";

import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import BatteryCharging50Icon from "@mui/icons-material/BatteryCharging50";

import WifiIcon from "@mui/icons-material/Wifi";
const IsToggleComponent = ({ eventData, coverImage, title }) => {
  const isWrap = useMediaQuery("(max-width:1909px)");
  const getDescriptionAsInnerText = (nodeString) => {
    let spanTag = document.createElement("span");
    spanTag.innerHTML = nodeString;
    return spanTag?.textContent || spanTag?.innerText;
  };
  return (
    <Grid item sx={{ width: "320px", ml: 4, mt: isWrap ? 4 : 0 }}>
      <Box
        sx={{
          height: "600px",
          width: "320px",
          position: "relative",
          borderRadius: "8px",
          border: "1px solid #F3F4F8",
          mt: 2,
          backgroundColor: "#F3F4F8",
        }}
      >
        <Box
          sx={{
            px: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ fontSize: "15px" }}>
            {moment(new Date()).local().format("HH:mm")}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <WifiIcon style={{ fontSize: "15px" }} />
            <SignalCellularAltIcon style={{ fontSize: "15px", mx: 1 }} />
            <BatteryCharging50Icon style={{ fontSize: "15px" }} />
          </Box>
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: "0",
            width: "100%",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
            borderTopLeftRadius: "24px",
            borderTopRightRadius: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "-20px",
              width: "100%",
              mb: 1,
            }}
          >
            <Typography
              sx={{
                width: "72px",
                height: "5px",
                borderRadius: "100px",
                backgroundColor: "#DBDFE9",
              }}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              display: "flex",
              p: 1,
              justifyContent: "center",
              borderTopLeftRadius: "24px",
              borderTopRightRadius: "24px",
            }}
          >
            <Box
              sx={{
                minWidth: "200px",
                minHeight: "200px",
                borderRadius: "6px",
                background: `url(${coverImage[0]?.src})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                mt: 2,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: "0%",
                  // px: 2,
                  overflowWrap: "anywhere!important",
                  width: "100%",
                  backgroundImage:
                    "linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.6),rgba(0,0,0,0.8))",
                  borderRadius: "6px",
                  p: "10px 0",
                }}
              >
                <Typography
                  style={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: "#fff",
                    fontSize: "12px",
                    whiteSpace: "nowrap",
                    fontFamily: "Inter",
                    fontWeight: "600",
                    letterSpacing: "-0.28px",
                    lineHeight: "20px",
                    marginLeft: "12px",
                    marginRight: "12px",
                  }}
                >
                  {eventData?.title}
                </Typography>
                <Box sx={{ px: "12px" }}>
                  <Typography
                    sx={{
                      color: "#FFF",
                      fontSize: "10px",
                      fontFamily: "Inter",
                      fontWeight: "400!important",
                    }}
                  >
                    {eventData?.eventStartDate && eventData?.eventEndDate
                      ? `${moment(eventData.eventStartDate).format(
                          "DD MMM"
                        )}  ${eventData?.eventStartTime || ""} - ${moment(
                          eventData.eventEndDate
                        ).format("DD MMM")} ${eventData?.eventEndTime || ""}`
                      : ""}
                  </Typography>

                  {/* <Typography
                            sx={{
                              color: "#FFF",
                              fontSize: "12px",
                              fontFamily: "Inter",
                              maxWidth: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontWeight: "400!important",
                            }}
                          >
                            {eventData?.eventStartTime && eventData?.eventEndTime
                              ? `${moment(
                                  eventData.eventStartTime,
                                  "HH:mm"
                                ).format("h:mm A")} - ${moment(
                                  eventData.eventEndTime,
                                  "HH:mm"
                                ).format("h:mm A")}`
                              : eventData?.eventStartTime
                              ? moment(eventData.eventStartTime, "HH:mm").format(
                                  "h:mm A"
                                )
                              : ""}
                          </Typography> */}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%", backgroundColor: "white" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                backgroundColor: "white",
                width: "90%",
                margin: "0 auto",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  textOverflow: "ellipsis",
                  color: "#02194D",
                  fontSize: "18px",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  letterSpacing: "-0.25px",
                  lineHeight: "24px",
                  p: "0 10px",
                  mt: "8px",
                }}
              >
                New {title} Launched
              </Typography>
              <Typography
                sx={{ fontSize: "14px" }}
                style={{
                  textAlign: "center",
                  color: "#02194D",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontWeight: "400",
                  letterSpacing: "-0.25px",
                  lineHeight: "20px",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  display: " -webkit-box",
                  overflow: "hidden",
                  p: "0 10px",
                }}
              >
                {eventData?.description
                  ? getDescriptionAsInnerText(eventData?.description)
                  : ""}
              </Typography>
              <Button
                sx={{
                  p: "16px 40px",
                  height: "56px",
                  borderRadius: "40px",
                  color: "white",
                  textTransform: "Capitalize",
                  fontSize: "16px",
                  backgroundColor: "#173C92",
                  "&:hover": { backgroundColor: "#173C92" },
                }}
              >
                View Details
              </Button>
              <Typography
                sx={{
                  mb: 3,
                  fontFamily: "Inter",
                  textAlign: "center",
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "-0.5px",
                  fontWeight: "500",
                  color: "#0F2C6F",
                  textTransform: "Capitalize",
                }}
              >
                Return Home
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <Typography
                  sx={{
                    width: "134px",
                    height: "5px",
                    borderRadius: "100px",
                    backgroundColor: "#02194D",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default IsToggleComponent;
