import React from "react";
import ReactApexChart from "react-apexcharts";

const BmsVisitorBarChart = ({ chartData }) => {
  const xAxisData = chartData?.x_axis_data;
  console.log("chartData ",chartData)
  const tooltipData = chartData?.tooltip_data;
  const tooltipMapping = xAxisData?.reduce((acc, date, index) => {
    acc[date] = tooltipData[index]?.title || '';
    return acc;
  }, {});

  const yAxisData = chartData?.y_axis_data?.map((value) => parseInt(value, 10));
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: xAxisData,
    },
    // tooltip: {
    //   y: {
    //     formatter: (val) => `${val}`,
    //   },
    // },
    tooltip: {
      custom: ({ seriesIndex, dataPointIndex }) => {
        const xValue = xAxisData[dataPointIndex];
        const title = tooltipMapping[xValue] || '';
        return `
          <div style="padding: 10px; background: white;  border-radius: 4px;">
            <div style="text-align: center; font-weight: bold;">
              ${title}
            </div>
          </div>
        `;
      },
    },
  
  };
  const series = [
    {
      name: "Eot",
      data: yAxisData,
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={350}
      className="chart2"
    />
  );
};

export default BmsVisitorBarChart;
