import React from "react";
import ReactApexChart from "react-apexcharts";

const BmsVisitorPieChart = ({ chartData }) => {
  console.log(chartData);
  const labels = chartData?.labels;
  const series = chartData?.values;

  const options = {
    chart: {
      type: "donut",
    },
    labels: labels,
    colors: ["#5b9bd5", "#ffa500"], // Adjust colors if needed
    legend: {
      position: "bottom",
    },
    dataLabels: {
      formatter: (val) => `${val.toFixed(2)}%`,
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}%`,
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height={480}
      className="chart3"
    />
  );
};

export default BmsVisitorPieChart;
