import React, { useState, useEffect } from "react";
import { Box, Button, Input, InputLabel, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../Assets/icons/Logo.svg";
import { ReactComponent as BackArrow } from "../../Assets/icons/backIcon.svg";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as loginaction from "../../redux/actions/loginaction";
import Signup from "./Signup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ForgotPassword from "./ForgotPassword";
import loginBgImage from "../../Assets/icons/loginbg.svg";
import moment from "moment";
import { sessionExpired } from "../../redux/actions/commonAction";
import { store } from "../../redux/store";
import axios from "axios";

export const initApi = async (callback) => {
  // const localRefreshExp = moment.utc('2024-10-11 10:30:01').local().format('YYYY-MM-DD HH:mm:ss')
  const localRefreshExp = moment
    .utc(localStorage.getItem("refreshTokenExpiryTime"))
    .local()
    .format("YYYY-MM-DD HH:mm:ss");
  const localTimes = moment().format("YYYY-MM-DD HH:mm:ss"); // Get current local time
  if (localTimes > localRefreshExp) {
    store.dispatch(sessionExpired("Session Expired"));
    localStorage.removeItem("userData");
    window.location.reload();
  } else {
    callback();
  }
};

const Index = ({
  loginMessage,
  loginService,
  loginNullService,
  sessionExpired,
}) => {
  const navigate = useNavigate();
  const [loginCrdentials, setLoginCredentials] = useState({
    email: "",
    password: "",
    type_of_login: "web_app",
  });
  const [errorStatus, setErrorStatus] = useState({
    email: "",
    password: "",
    loginError: "",
    commonError: "",
    confirmEmailError: "",
  });
  const [showSignupScreen, setShowSignupScreen] = useState(false);
  const [showForgotScreen, setShowForgotScreen] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("userToken") && localStorage.getItem("userData")) {
      if (JSON.parse(localStorage.getItem("userData"))?.role_id === 15) {
        navigate("/manage_faults/0");
      } else if (JSON.parse(localStorage.getItem("userData"))?.role_id === 16) {
        navigate("/manage_facility_booking");
      } else if (JSON.parse(localStorage.getItem("userData"))?.role_id === 13) {
        navigate("/register_visitor");
      } else if (JSON.parse(localStorage.getItem("userData"))?.role_id === 17) {
        navigate("/auditreport");
      } else {
        navigate("/dashboard");
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (loginMessage?.response_json?.token) {
      loginNullService();

      const navigateUser = () => {
        if (loginMessage.response_json?.userData?.role_id === 15) {
          navigate("/manage_faults/0");
        } else if (loginMessage.response_json?.userData?.role_id === 16) {
          navigate("/manage_facility_booking");
        } else if (loginMessage.response_json?.userData?.role_id === 13) {
          navigate("/register_visitor");
        } else if (
          JSON.parse(localStorage.getItem("userData"))?.role_id === 17
        ) {
          navigate("/auditreport");
        } else {
          navigate("/dashboard");
        }
      };
      initApi(navigateUser);
    } else if (
      loginMessage === "New User Login" ||
      loginMessage === "Email id not verified. Please use first time login"
    ) {
      loginNullService();
      setErrorStatus({ loginError: "Account not verified!" });
      setTimeout(() => {
        setShowSignupScreen(true);
        setErrorStatus({ loginError: "" });
      }, 5000);
    } else if (loginMessage) {
      loginNullService();
      setErrorStatus({ loginError: loginMessage });
    }
  }, [loginMessage, loginNullService, navigate]);

  const validateCrdentials = () => {
    let returnStatus = false;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!loginCrdentials.email && !loginCrdentials.password) {
      setErrorStatus({
        commonError: "Please enter Email and Password",
      });
      returnStatus = false;
    } else {
      if (
        loginCrdentials.email.match(emailRegex) &&
        loginCrdentials.password.length >= 8
      ) {
        setErrorStatus({ email: "", password: "" });
        returnStatus = true;
      } else if (
        loginCrdentials.email.match(emailRegex) &&
        loginCrdentials.password.length === 0
      ) {
        setErrorStatus({
          email: "",
          password: "Please enter password",
        });
        returnStatus = false;
      } else if (
        loginCrdentials.email.match(emailRegex) &&
        loginCrdentials.password.length < 8
      ) {
        setErrorStatus({
          email: "",
          password: "Password should be 8 characters",
        });
        returnStatus = false;
      } else if (
        !loginCrdentials.email &&
        loginCrdentials.password.length >= 8
      ) {
        setErrorStatus({ email: "Enter email", password: "" });
        returnStatus = false;
      } else if (
        !loginCrdentials.email.match(emailRegex) &&
        loginCrdentials.password.length >= 8
      ) {
        setErrorStatus({ email: "Invalid email", password: "" });
        returnStatus = false;
      } else if (
        !loginCrdentials.email &&
        loginCrdentials.password.length < 8
      ) {
        setErrorStatus({
          email: "Enter email",
          password: "Password should be 8 characters",
        });
        returnStatus = false;
      } else if (
        !loginCrdentials.email.match(emailRegex) &&
        !loginCrdentials.password
      ) {
        setErrorStatus({
          email: "Invalid email",
          password: "Enter Password",
        });
        returnStatus = false;
      } else if (
        !loginCrdentials.email.match(emailRegex) &&
        loginCrdentials.password.length < 8
      ) {
        setErrorStatus({
          email: "Invalid email",
          password: "Password should be 8 characters",
        });
        returnStatus = false;
      }
    }

    return returnStatus;
  };

  const validateEmail = () => {
    let returnStatus = false;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!loginCrdentials.email) {
      setErrorStatus({
        email: "Please enter a valid email address",
      });
      returnStatus = false;
    } else {
      if (loginCrdentials.email.match(emailRegex)) {
        setErrorStatus({ email: "", password: "" });
        returnStatus = true;
      } else {
        setErrorStatus({
          email: "Please enter a valid email address",
          password: "",
          ConfirmEmailError: "Please enter a valid email address.",
        });
        returnStatus = false;
      }
    }

    return returnStatus;
  };

  const LoginHandler = () => {
    if (validateCrdentials()) {
      loginService(loginCrdentials);
    }
  };

  const ConfirmEmail = (requestData) => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/checkUserExists`,
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        console.log("ConfirmEmailData ", response.data);
        if (response?.data?.response_status === 1) {
          setEmailVerified(true);
        } else if (response?.data?.response_status === 0) {
          setEmailVerified(false);
          setErrorStatus((prev) => ({
            ...prev,
            email: response?.data?.message,
          }));
          console.log("errorStatus", errorStatus);
        }
      })
      .catch((error) => {
        console.log("ConfirmEmailDataError ", error);
      });
  };
  const ConfirmEmailHandler = () => {
    if (validateEmail()) {
      ConfirmEmail({ email: loginCrdentials.email, type_of_login: "web_app" });
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setLoginCredentials({ ...loginCrdentials, [name]: value });
    setErrorStatus({
      ...errorStatus,
      [name]: "",
      commonError: "",
      confirmEmailError: "",
    });
    if (errorStatus.loginError) {
      setErrorStatus({ loginError: "" });
    }
  };

  const handleContinueEnter = (e) => {
    if(e.key === "Enter") ConfirmEmailHandler();
  }

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      LoginHandler();
    }
  };
  if (showSignupScreen)
    return (
      <Signup email={loginCrdentials.email} hideSignUp={setShowSignupScreen} />
    );
  if (showForgotScreen)
    return <ForgotPassword hideForgotscreen={setShowForgotScreen} />;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        backgroundImage: `url(${loginBgImage})`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          background: "white",
          padding: { md: "42px 34px", sm: "30px", xs: "20px" },
          flexDirection: "column",
          borderRadius: "8px",
          width: { sm: "360px", xs: "270px" },
          mx: "auto",

          boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",

            alignItems: "center",
          }}
        >
          <Logo
            style={{
              width: "224px",
              height: "48px",
            }}
          />
          {/* <Typography
            sx={{
              fontSize: "9px",
              fontWeight: 500,
              color: "#0F2C6F",
              textAlign: "center",
            }}
          >
            Tenant Office Manager Dashboard
          </Typography> */}
        </Box>
        {emailVerified && (
          <BackArrow
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setEmailVerified(false);
              setLoginCredentials({ ...loginCrdentials, password: "" });
              setErrorStatus({
                email: "",
                password: "",
                loginError: "",
                commonError: "",
                confirmEmailError: "",
              });
            }}
          />
        )}
        <Box
          sx={{
            padding: "20px 0 ",
            display: "flex",
            flexDirection: "column",
          }}
          rowGap={1}
        >
          {!emailVerified && (
            <>
              <InputLabel
                sx={{
                  fontSize: { sm: "14px", xs: "12px" },
                  fontWeight: 600,
                  color: "#0F2C6F",
                  marginTop: "25px",
                }}
              >
                Corporate Email Address
              </InputLabel>
              <Input
                type="email"
                name="email"
                placeholder="Johndoe@example.com"
                value={loginCrdentials?.email}
                style={{
                  padding: "12px 6px",
                  border: "1px solid #DBDFE9",
                  borderRadius: "6px",
                  outlineColor: "#123FA9",
                }}
                onKeyDown={(e) => handleContinueEnter(e)}
                onChange={(e) => handleChange(e)}
                inputProps={{
                  sx: {
                    height: { md: "25px", sm: "20px", xs: "4px" },
                    fontSize: { md: "16px", sm: "14px", xs: "12px" },
                    color: "#0F2C6F",
                  },
                }}
                autoComplete="off"
                autoFocus
                disableUnderline
              />
              <Typography variant="p" color="error" sx={{ fontSize: "15px" }}>
                {errorStatus.email}
              </Typography>
              {errorStatus?.confirmEmailError && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="p"
                    color="error"
                    sx={{ fontSize: "15px", mt: 2 }}
                  >
                    {errorStatus?.confirmEmailError}
                  </Typography>
                </Box>
              )}
            </>
          )}
          {emailVerified && (
            <>
              <InputLabel
                sx={{
                  fontSize: { sm: "14px", xs: "12px" },
                  fontWeight: 600,
                  color: "#0F2C6F",
                  marginTop: "10px",
                }}
              >
                Password
              </InputLabel>
              <Input
                type={showPassword ? "text" : "password"}
                endAdornment={
                  showPassword ? (
                    <VisibilityIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPassword(true)}
                    />
                  )
                }
                placeholder="xxxxx"
                name="password"
                sx={{
                  padding: "12px 6px",
                  border: "1px solid #DBDFE9",
                  borderRadius: "6px",
                  outlineColor: "#123FA9",
                  color: "#0F2C6F",
                }}
                onKeyDown={(e) => handleEnter(e)}
                onChange={(e) => handleChange(e)}
                inputProps={{
                  sx: {
                    height: { md: "25px", sm: "20px", xs: "4px" },
                    fontSize: { md: "16px", sm: "14px", xs: "12px" },
                  },
                }}
                disableUnderline
              />
              {errorStatus.password && (
                <Typography variant="p" color="error" sx={{ fontSize: "15px" }}>
                  {errorStatus.password}
                </Typography>
              )}
            </>
          )}
        </Box>

        {errorStatus.commonError && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="p"
              color="error"
              sx={{ fontSize: "15px", mt: 2 }}
            >
              {errorStatus.commonError}
            </Typography>
          </Box>
        )}

        {!emailVerified && (
          <Box
            sx={{
              paddingBotton: "50px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              variant="contained"
              sx={{ textTransform: "none", background: "#123FA9" }}
              onClick={() => ConfirmEmailHandler()}
            >
              Continue
            </Button>
          </Box>
        )}

          <Box
            sx={{
              paddingBotton: "50px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {emailVerified && <Button
              variant="contained"
              sx={{ textTransform: "none", background: "#123FA9" }}
              onClick={() => LoginHandler()}
            >
              Log In
            </Button>}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  cursor: "pointer",
                  textTransform: "none",
                  textAlign: "center",
                  mt: 2,
                  color: "#0F2C6F",
                  fontSize: "14px",
                  textDecoration: "underline",
                  textUnderlineOffset: "5px",
                }}
                onClick={() => setShowForgotScreen(true)}
              >
                Forget Password
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  textTransform: "none",
                  textAlign: "center",
                  mt: 2,
                  color: "#0F2C6F",
                  fontSize: "14px",
                  textDecoration: "underline",
                  textUnderlineOffset: "5px",
                }}
                onClick={() => setShowSignupScreen(true)}
              >
                Sign Up
              </Typography>
            </Box>
            <Typography
              variant="p"
              color="error"
              sx={{ fontSize: "15px", mt: 1 }}
            >
              {errorStatus.loginError}
            </Typography>
          </Box>
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    loginMessage: state.LoginReducer.loginMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginService: (data) => dispatch(loginaction.loginService(data)),

    loginNullService: () => dispatch(loginaction.loginNullService()),
    sessionExpired: (data) => dispatch(sessionExpired(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
