import {
  Box,
  FormControl,
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Checkbox,
  TextField,
  ListItemText,
  TableCell,
  tableCellClasses,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Switch,
  List,
  ListItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../Assets/icons/back.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import PhoneInput from "react-phone-input-2";
import Modal from "../common/faultalert";
import * as BmsStaffAction from "../../redux/actions/bmsStaffAction";
import * as commonAction from "../../redux/actions/commonAction";
import * as CommonAction from "../../redux/actions/commonAction";
import { connect } from "react-redux";
import ToastAlert from "../common/Alert";
import AvatarRemove from "../../Assets/icons/avatarRemove.png";
import EmptyJpg from "../../Assets/icons/EmptyJpg.png";
import Loader from "../common/Loader";
import { MenuProps, preventDatePickerKeyboard } from "../../Utils/utils";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import axios from "axios";
import EmptyAvatar from "../../Assets/icons/Default.png";
import ApiService from "../../AxiosConfig/ApiService";
import Close from "@mui/icons-material/Close";

let alertData = {
  label: "Confirm Individual Edit Staff",
  description:
    "Please confirm that you wish to edit staff. If the above details is correct, please press confirm below. You may click the cross button on top right to return to form.",
  buttonvalue: "Confirm",
};

const StyledTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#0F2C6F",
  marginBottom: "5px",
});

const StyledOutlinedInput = styled(OutlinedInput)({
  height: "48px",
  borderColor: " #9AA6C3",
  borderRadius: "6px",
  width: "90%",
  color: "#0F2C6F",
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: " #0F2C6F",
  [`&.${tableCellClasses.head}`]: {
    fontSize: "13px",
    fontWeight: 550,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontWeight: 500,
  },
}));
const EditStaff = ({
  bmsRole,
  getStaffRole,
  getAllLevels,
  getUnitsByLevelid,
  levelDatas,
  unitDatas,
  setBmsStaffReducernull,
  levelList,
  getStaffLevel,
  getEmpDetailsById,
  empDetails,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [personName, setPersonName] = React.useState([]);
  const [personalDetails, setPersonalDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    bmsRole: "",
    accessStartDate: "",
    officeLevel: "",
    officeUnit: "",
    mobile: "",
    office: "",
    mobileCode: "",
    officeCode: "",
    officeContact: "",
    mobileContact: "",
  });
  console.log(personalDetails);
  const [personalDetailsError, setPersonalDetailsError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    bmsRole: "",
    accessStartDate: "",
    officeLevel: "",
    officeUnit: "",
    mobile: "",
    office: "",
  });
  const [supportingFiles, setSupportingFiles] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [toast, setToast] = useState({ message: "", status: false, color: "" });
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [levelError, setLevelError] = useState("");
  const [tableData, setTableData] = useState([]);
  const [restrictEdit, setRestrictEdit] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const inputImg = useRef();
  const [isExists, setIsExists] = useState(false);
  const [selectedLevelArray, setSelectedArray] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    getStaffRole();
    getAllLevels();
    getStaffLevel();
    getEmpDetailsById({ employee_id: id });
    return () => {
      setBmsStaffReducernull();
    };
  }, []);

  const handleChange = (event) => {
    setSelectedArray([]);
    setPersonalDetails({
      ...personalDetails,
      officeLevel: "",
      officeUnit: "",
    });
    setLevelError("");
    const value = event.target.value;
    const allIds = levelList.map((item) => item.level_name);

    if (value.includes("All")) {
      if (personName.length === allIds.length) {
        setPersonName([]);
      } else {
        setPersonName(allIds);
      }
    } else {
      if (personName.length === allIds.length) {
        setPersonName([value[value.length - 1]]);
      } else {
        setPersonName(value);
      }
    }
  };
  useEffect(() => {
    if (empDetails?.employeeArrayList?.length > 0) {
      let EmpDataMap = empDetails?.employeeArrayList;
      setTableData(empDetails?.employeeArrayList);
      EmpDataMap?.map((value) => {
        setPersonalDetails({
          firstName: value?.first_name,
          lastName: value?.last_name,
          email: value?.email,
          jobTitle: value?.job_title,
          mobile: value?.mobile_no,
          office: value?.office_phone_no || "",
          bmsRole: value?.employee_role_id,
          accessStartDate: moment(value?.access_start_date),
          officeLevel: value?.level_id,
          officeUnit: value?.unit_id,
          mobileCode: value?.mobile_country_code,
          officeCode: value?.office_country_code || "+65",
          officeContact: value?.office_country_code
            ? value?.office_country_code + value?.office_phone_no
            : "+65" + (value?.office_phone_no ? value?.office_phone_no : ""),
          mobileContact: value?.mobile_country_code + value?.mobile_no,
        });

        if (value?.is_fr_enabled === 1) {
          setIsToggle(true);
          setIsExists(true);
        } else {
          setIsToggle(false);
          setIsExists(false);
        }

        if (value?.approved_status === "submitted") {
          setRestrictEdit(true);
        } else {
          setRestrictEdit(false);
        }
      });
    }

    if (empDetails?.assignedLevelArrayList?.length > 0) {
      let levelDataMap = empDetails?.assignedLevelArrayList;
      setSelectedArray(levelDataMap);
      const selectedLevels = levelDataMap?.map((value) => value.level_name);
      setPersonName(selectedLevels || []);
    }
  }, [empDetails]);

  useEffect(() => {
    ApiService()
      .get(`${process.env.REACT_APP_API_URL}/getFRconfig`)
      .then((res) => setModalData(res.data?.response_json?.fr_contents))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (personalDetails.officeLevel?.level_id)
      getUnitsByLevelid({
        level_id: personalDetails.officeLevel?.level_id,
      });
  }, [personalDetails.officeLevel]);

  useEffect(() => {
    const unitFind = unitDatas?.find(
      (value) => personalDetails.officeUnit === value?.unit_id
    );
    if (unitFind) {
      setLoaderStatus(false);
      setPersonalDetails((prev) => ({
        ...prev,
        officeUnit: unitFind,
      }));
    }
  }, [unitDatas]);

  useEffect(() => {
    if (personName.length > 0) {
      const filteredData = levelDatas.filter((item) =>
        personName.includes(item.level_name)
      );
      setSelectedArray(filteredData);
    }
  }, [personName, levelDatas]);

  useEffect(() => {
    if (personalDetails.officeLevel) {
      const levelFind = levelDatas?.find(
        (value) => personalDetails.officeLevel === value?.level_id
      );
      if (levelFind) {
        setPersonalDetails((prev) => ({
          ...prev,
          officeLevel: levelFind,
        }));
      }
    }
  }, [levelDatas, personalDetails.officeLevel]);

  useEffect(() => {
    if (personalDetails.bmsRole) {
      const bmsFind = bmsRole?.find(
        (value) => personalDetails.bmsRole === value?.role_id
      );
      if (bmsFind) {
        setPersonalDetails((prev) => ({
          ...prev,
          bmsRole: bmsFind,
        }));
      }
    }
  }, [personalDetails.bmsRole, bmsRole]);

  const handleClose = () => {
    setOpenModal(false);
  };


  const ModalAlert = () => {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        sx={{ "& .MuiDialog-paper": { width: "60%" } }} // Fixed width for the dialog
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "25px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottom: "1px solid #e0e0e0", // Bottom border
            gap: "10px", // Gap between content
          }}
        >
          <Typography
            sx={{ fontWeight: "500", fontSize: "18px", color: "#0F2C6F" }}
          >
            {modalData?.fr_title ||
              "Do you want to turn on the facial recognition?"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              color: "#0F2C6F",
              marginTop: "15px",
            }}
            gutterBottom
          >
            {modalData?.fr_desc ||
              "  An update is available with new features, a faster experiences,fixes and more. Please update to continue using it."}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              borderRadius: "6px",
              p: "8px 10px",
              color: "#0F2C6F",
              borderColor: "#AAC3FF",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Inter",
              }}
            >
              {modalData?.fr_cancel_btn || "Cancel"}
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={handleConfrim}
            sx={{
              borderRadius: "6px",
              p: "8px 10px",
              textTransform: "capitalize",
              backgroundColor: "#123FA9",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Inter",
              }}
            >
              {modalData?.fr_confirm_btn || "Confirm and Turn On"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  

  const handlePersonalDetails = (e) => {
    const { name, value } = e.target;

    setPersonalDetails({
      ...personalDetails,
      [name]: value,
    });

    setPersonalDetailsError({
      ...personalDetailsError,
      [name]: "",
    });
  };
  const handleMobileInput = (data, country, event) => {
    const { name, value } = event.target;
    if (name) {
      setPersonalDetails({
        ...personalDetails,
        [name]: value.slice(country.dialCode.length),
        [`${name}Code`]: `+${country.dialCode}`,
      });
      setPersonalDetailsError({
        ...personalDetailsError,
        [name]: "",
      });
    }
  };
  const handlePhoneInput = (type, data, country) => {
    if (type == "office") {
      setPersonalDetails({
        ...personalDetails,
        office: data.slice(country.dialCode.length),
        officeCode: `+${country.dialCode}`,
      });

      setPersonalDetailsError({
        ...personalDetailsError,
        office: "",
      });
    }
    if (type == "mobile") {
      setPersonalDetails({
        ...personalDetails,
        mobile: data.slice(country.dialCode.length),
        mobileCode: `+${country.dialCode}`,
      });

      setPersonalDetailsError({
        ...personalDetailsError,
        office: "",
      });
    }
  };
  const handleToggleChange = () => {
    if (!isToggle) {
      setOpenModal(true);
    } else {
      setImageErr(false);
      setSupportingFiles(null);
      setIsToggle((prev) => !prev);
    }
  };

  const handleConfrim = () => {
    setImageErr(false);
    setSupportingFiles(null);
    setIsToggle((prev) => !prev);
    setOpenModal(false);
  };
  const IOSSwitch = styled((SwitchProps) => (
    <Switch
      checked={isToggle}
      onChange={handleToggleChange}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...SwitchProps}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#123FA9",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleDropImage = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setSupportingFiles({
        file: file,
        fileName: file.name,
        size: file.size,
        src: URL.createObjectURL(file), // Create a blob URL for image preview
      });
      setImageErr(false);
      e.target.value = null;
    }
  };
  const handleDragImage = (e) => {
    e.preventDefault();
  };

  const handleImage = () => {
    inputImg.current.click();
  };

  const handleInputImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSupportingFiles({
        file: file,
        fileName: file.name,
        size: file.size,
        src: URL.createObjectURL(file), // Create a blob URL for image preview
      });
      e.target.value = null;
      setImageErr(false);
    }
  };

  const removeSupportImage = () => {
    setSupportingFiles(null);
  };

  const handleDateFormat = (value) => {
    let date = `${value.getFullYear()}-${
      value.getMonth() < 9 ? `0${value.getMonth() + 1}` : value.getMonth() + 1
    }-${value.getDate() < 10 ? `0${value.getDate()}` : value.getDate()}`;
    setPersonalDetails({
      ...personalDetails,
      accessStartDate: value,
      dateStr: date,
    });
  };
  const validateData = () => {
    let isValid = true;
    let errors = {
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      bmsRole: "",
      accessStartDate: "",
      officeLevel: "",
      officeUnit: "",
      mobile: "",
      office: "",
    };

    // Check for empty fields
    if (!personalDetails.firstName.trim()) {
      errors.firstName = "First name is required";
      isValid = false;
    }
    if (!personalDetails.lastName.trim()) {
      errors.lastName = "Last name is required";
      isValid = false;
    }
    if (!personalDetails.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    }
    if (!personalDetails.jobTitle.trim()) {
      errors.jobTitle = "Job title is required";
      isValid = false;
    }
    if (!personalDetails.mobile.trim()) {
      errors.mobile = "Mobile number is required";
      isValid = false;
    }
    if (!personalDetails.bmsRole) {
      errors.bmsRole = "BMS role is required";
      isValid = false;
    }
    if (!personalDetails.officeLevel) {
      errors.officeLevel = "Office level is required";
      isValid = false;
    }
    if (!personalDetails.officeUnit) {
      errors.officeUnit = "Office unit is required";
      isValid = false;
    }
    if (!personalDetails.accessStartDate) {
      errors.accessStartDate = "Access start date is required";
      isValid = false;
    }
    if (personName.length === 0) {
      setLevelError("At least one level must be selected");
      isValid = false;
    } else {
      setLevelError("");
    }
    if (isToggle && !isExists) {
      if (!supportingFiles?.src) {
        setImageErr("Please upload the image");
        isValid = false;
      }
    }

    // Set errors to state
    setPersonalDetailsError(errors);

    return isValid;
  };

  const handleUpdate = () => {
    if (validateData()) {
      setModalOpen(true);
    }
  };

  const isAllSelected = personName.length === levelList.length;

  const modalClose = () => {
    setModalOpen(false);
  };

  const modalConfirm = () => {
    setModalOpen(false);
    setLoaderStatus(true);
    let token = localStorage.getItem("userToken");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    const levels = [...levelList];
    let level_array = [];
    personName?.forEach((item, index) => {
      levels?.forEach((item2, index2) => {
        if (item2.level_name == item) {
          level_array.push(parseInt(item2.assigned_units_id));
        }
      });
    });
    let data = {
      employee_id: id,
      first_name: personalDetails.firstName,
      last_name: personalDetails.lastName,
      mobile_country_code: personalDetails.mobileCode,
      mobile_no: personalDetails.mobile,
      office_country_code: personalDetails.office
        ? personalDetails.officeCode
        : "",
      office_phone_no: personalDetails.office,
      access_start_date: personalDetails.accessStartDate,
      level_no: personalDetails.officeLevel.level_id,
      unit_no: personalDetails.officeUnit.unit_id,
      role: personalDetails.bmsRole.role,
      job_title: personalDetails.jobTitle,
      email: personalDetails.email,
      assigned_unit_id_str: JSON.stringify(level_array),
      app_accesss: 1,
      dashboard_accesss: 1,
      // enable_fr: isToggle ? Number(1) : Number(0),
    };
    if (isToggle && supportingFiles?.size) {
      const formData = new FormData();
      // formData.append("face_photo", supportingFiles?.file);
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/editBmsEmployee`,
          formData,
          config
        )
        .then((response) => {
          if (response?.data?.response_status === "success") {
            setLoaderStatus(false);
            setToast({
              status: true,
              message: "Employee was updated successfully",
              color: "green",
            });
            setTimeout(() => {
              setToast({});
              setBmsStaffReducernull();
              navigate("/manage_staff/1");
            }, 3000);
          } else {
            setLoaderStatus(false);
            setToast({
              status: true,
              message: "Failed to update employee",
              color: "red",
            });
            setTimeout(() => {
              setToast({});
              setBmsStaffReducernull();
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/editBmsEmployee`, data, config)
        .then((response) => {
          if (response?.data?.response_status === "success") {
            setLoaderStatus(false);
            setToast({
              status: true,
              message: "Employee was updated successfully",
              color: "green",
            });
            setTimeout(() => {
              setToast({});
              setBmsStaffReducernull();
              navigate("/manage_staff/1");
            }, 3000);
          } else {
            setLoaderStatus(false);
            setToast({
              status: true,
              message: "Failed to update employee",
              color: "red",
            });
            setTimeout(() => {
              setToast({});
              setBmsStaffReducernull();
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      {modalOpen && (
        <Modal
          alertData={alertData}
          closeFunc={modalClose}
          confirmFunc={modalConfirm}
        />
      )}
      {toast.status && (
        <ToastAlert
          status={toast.status}
          message={toast.message}
          color={toast.color}
        />
      )}
           {openModal && ModalAlert()}
      {loaderStatus && <Loader status={loaderStatus} />}
      <Grid container sx={{ backgroundColor: "rgba(143, 146, 161,0.05)" }}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Box sx={{ mb: 5, mt: 7 }}>
            <BackIcon
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            />
            <Typography
              sx={{
                fontSize: "26px",
                fontWeight: 500,
                color: "#0F2C6F",
                my: 1,
              }}
            >
              Edit BMS Staff
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#6F80A9" }}
            >
              You may edit BMS staff here.
            </Typography>
          </Box>
          <Box sx={{ backgroundColor: "#FFFFFF" }}>
            <Box sx={{ p: 3 }}>
              {!restrictEdit && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    my: 2,
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#0F2C6F",
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Personal Details
                    </Typography>
                    <Typography
                      sx={{
                        color: "#6F80A9",
                        fontSize: "14px",
                        fontWeight: 500,
                        mt: 1,
                        mb: 3,
                      }}
                    >
                      Please fill up the details of your employee below.
                    </Typography>
                  </Box>

                  {/* <img
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "231px",
                      border: "1px solid #DBDFE9",
                    }}
                    src={
                      supportingFiles?.src ? supportingFiles?.src : EmptyAvatar
                    }
                  /> */}
                </Box>
              )}
              <Grid container rowSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <StyledTypography>First Name *</StyledTypography>
                  <StyledOutlinedInput
                    onChange={(e) => handlePersonalDetails(e)}
                    name="firstName"
                    value={personalDetails.firstName}
                  />
                  {personalDetailsError.firstName && (
                    <Typography sx={{ color: "#f44336" }}>
                      {personalDetailsError.firstName}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTypography>Last Name *</StyledTypography>
                  <StyledOutlinedInput
                    onChange={(e) => handlePersonalDetails(e)}
                    name="lastName"
                    value={personalDetails.lastName}
                  />
                  {personalDetailsError.lastName && (
                    <Typography sx={{ color: "#f44336" }}>
                      {personalDetailsError.lastName}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTypography>Work Email Address*</StyledTypography>
                  <StyledOutlinedInput
                    onChange={(e) => handlePersonalDetails(e)}
                    name="email"
                    value={personalDetails.email}
                    disabled
                  />
                  {personalDetailsError.email && (
                    <Typography sx={{ color: "#f44336" }}>
                      {personalDetailsError.email}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTypography>Job Title *</StyledTypography>
                  <StyledOutlinedInput
                    onChange={(e) => handlePersonalDetails(e)}
                    name="jobTitle"
                    value={personalDetails.jobTitle}
                  />
                  {personalDetailsError.jobTitle && (
                    <Typography sx={{ color: "#f44336" }}>
                      {personalDetailsError.jobTitle}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTypography>Mobile Number*</StyledTypography>
                  <PhoneInput
                    inputStyle={{
                      width: "90%",
                      height: "48px",
                      borderRadius: "6px",
                      color: "#0F2C6F",
                    }}
                    onChange={(data, country) =>
                      handlePhoneInput("mobile", data, country)
                    }
                    value={personalDetails.mobileContact}
                    inputProps={{ name: "mobile" }}
                  />
                  {personalDetailsError.mobile && (
                    <Typography sx={{ color: "#f44336" }}>
                      {personalDetailsError.mobile}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTypography>Office Number</StyledTypography>
                  <PhoneInput
                    inputStyle={{
                      width: "90%",
                      height: "48px",
                      borderRadius: "6px",
                      color: "#0F2C6F",
                    }}
                    onChange={(data, country) =>
                      handlePhoneInput("office", data, country)
                    }
                    value={personalDetails.officeContact}
                    inputProps={{ name: "office" }}
                  />
                  {personalDetailsError.office && (
                    <Typography sx={{ color: "#f44336" }}>
                      {personalDetailsError.office}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTypography>BMS Role*</StyledTypography>
                  <Select
                    sx={{ width: "90%", color: "#0F2C6F" }}
                    value={personalDetails.bmsRole}
                    onChange={(e) => handlePersonalDetails(e)}
                    name="bmsRole"
                    disabled
                  >
                    {bmsRole.length > 0 &&
                      bmsRole.map((value) => {
                        return <MenuItem value={value}>{value.role}</MenuItem>;
                      })}
                  </Select>
                  {personalDetailsError.bmsRole && (
                    <Typography sx={{ color: "#f44336" }}>
                      {personalDetailsError.bmsRole}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTypography>Level</StyledTypography>
                  <FormControl style={{ width: "90%" }}>
                    <Select
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      disabled={restrictEdit}
                    >
                      <MenuItem value="All">
                        <Checkbox checked={isAllSelected} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {levelList.map((item, index) => (
                        <MenuItem key={index} value={item.level_name}>
                          <Checkbox
                            checked={personName.indexOf(item.level_name) > -1}
                          />
                          <ListItemText primary={item.level_name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {levelError != "" && (
                    <Typography sx={{ color: "#f44336" }}>
                      {levelError}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} />
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: "#0F2C6F",
                      fontSize: "18px",
                      fontWeight: 500,
                      mt: 3,
                    }}
                  >
                    Access Details
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ position: "relative", width: "40%" }}>
                    <StyledTypography>Access Start date*</StyledTypography>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      disablePast
                      value={personalDetails.accessStartDate}
                      onChange={(value) => handleDateFormat(value)}
                      components={{
                        OpenPickerIcon: DatePickerIcon,
                      }}
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => preventDatePickerKeyboard(e)}
                          {...params}
                        />
                      )}
                      disabled
                    />
                  </Box>
                  {personalDetailsError.accessStartDate && (
                    <Typography sx={{ color: "#f44336" }}>
                      {personalDetailsError.accessStartDate}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTypography>Office Unit Number*</StyledTypography>
                  <FormControl>
                    <Select
                      disabled={restrictEdit}
                      MenuProps={MenuProps}
                      onChange={(e) => handlePersonalDetails(e)}
                      name="officeLevel"
                      sx={{
                        height: "40px",
                        width: "70px",
                        mr: 3,
                        color: "#0F2C6F",
                      }}
                      value={personalDetails.officeLevel}
                    >
                      {selectedLevelArray?.length > 0 &&
                        selectedLevelArray.map((value, index) => {
                          return (
                            <MenuItem
                              sx={{ color: "#0F2C6F" }}
                              key={index}
                              value={value}
                              onClick={() => {
                                getUnitsByLevelid({
                                  level_id: value.level_id,
                                });
                              }}
                            >
                              {value.level_name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  <Select
                    disabled={restrictEdit}
                    onChange={(e) => handlePersonalDetails(e)}
                    name="officeUnit"
                    sx={{ height: "40px", width: "120px", color: "#0F2C6F" }}
                    value={personalDetails.officeUnit}
                  >
                    {unitDatas.length > 0 &&
                      unitDatas.map((value) => {
                        return (
                          <MenuItem value={value}>{value.unit_name}</MenuItem>
                        );
                      })}
                  </Select>
                  {personalDetailsError.officeUnit && (
                    <Typography sx={{ color: "#f44336" }}>
                      {personalDetailsError.officeUnit}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              {/* {!restrictEdit && (
                <Grid sx={{ mt: 6 }} item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "fit-content",
                        borderRadius: "6px",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "-0.25px",
                        fontFamily: "inter",
                        color: "#0F2C6F",
                        padding: "0 8px",
                        backgroundColor: "#CFDDFE",
                      }}
                    >
                      NEW
                    </Typography>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          letterSpacing: "-0.25px",
                          fontFamily: "inter",
                          color: "#0F2C6F",
                        }}
                      >
                        Facial Recognition
                      </Typography>
                      <IOSSwitch defaultChecked={false} />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          letterSpacing: "-0.25px",
                          fontFamily: "inter",
                          color: "#6F80A9",
                        }}
                      >
                        Automatically activate the facial recognition feature
                        for this tenant upon entering the building.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          letterSpacing: "-0.25px",
                          fontFamily: "inter",
                          color: "#6F80A9",
                        }}
                      >
                        You can skip this step and let the tenant upload it by
                        themselves.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )} */}
              {!restrictEdit && isToggle && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "-0.25px",
                        fontFamily: "inter",
                        color: "#0F2C6F",
                      }}
                    >
                      Photo Requirements:
                    </Typography>
                    <ol style={{ color: "#0F2C6F" }}>
                      <li style={{ color: "inherit" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "-0.25px",
                            fontFamily: "inter",
                            color: "#0F2C6F",
                          }}
                        >
                          Please submit a single color photograph.
                        </Typography>
                      </li>
                      <li style={{ color: "inherit" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "-0.25px",
                            fontFamily: "inter",
                            color: "#0F2C6F",
                          }}
                        >
                          Ensure the photo was taken within the last six months.
                        </Typography>
                      </li>
                      <li style={{ color: "inherit" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "-0.25px",
                            fontFamily: "inter",
                            color: "#0F2C6F",
                          }}
                        >
                          Use a clear image showing the entire face; avoid
                          selfies.
                        </Typography>
                      </li>
                      <li style={{ color: "inherit" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "-0.25px",
                            fontFamily: "inter",
                            color: "#0F2C6F",
                          }}
                        >
                          Do not alter the photo using computer software, phone
                          apps, or filters.
                        </Typography>
                      </li>
                      <li style={{ color: "inherit" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "-0.25px",
                            fontFamily: "inter",
                            color: "#0F2C6F",
                          }}
                        >
                          The background should be white or off-white, free from
                          shadows, texture, or lines.
                        </Typography>
                      </li>
                    </ol>
                    <Box sx={{ padding: "16px 24px" }}>
                      <Typography
                        sx={{ color: "#0F2C6F", fontSize: "16px", mb: 2 }}
                      >
                        Supporting Materials(optional)
                      </Typography>
                      {supportingFiles?.size ? (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            border: "2px solid #80808021",
                            mb: 0.5,
                            borderRadius: "5px",
                          }}
                        >
                          <List
                            sx={{
                              width: "100%",
                              bgcolor: "background.paper",
                              p: 0,
                            }}
                          >
                            <ListItem
                              sx={{ pl: 0 }}
                              secondaryAction={
                                <IconButton edge="end" aria-label="delete">
                                  <Box
                                    component="img"
                                    src={AvatarRemove}
                                    onClick={removeSupportImage}
                                  />
                                </IconButton>
                              }
                            >
                              <ListItemText
                                primary={
                                  <Box
                                    sx={{
                                      ml: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      component="img"
                                      src={EmptyJpg}
                                      sx={{
                                        width: "40px",
                                        height: "48px",
                                      }}
                                    />
                                    <Typography variant="p" sx={{ m: 2 }}>
                                      {supportingFiles.fileName}
                                      <span
                                        style={{
                                          color: "gray",
                                          fontSize: "15px",
                                        }}
                                      >{`     (${Math.floor(
                                        supportingFiles?.size / 1024
                                      )} of ${Math.floor(
                                        supportingFiles?.size / 1024
                                      )}KB)`}</span>
                                    </Typography>
                                  </Box>
                                }
                                secondary={""}
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      ) : (
                        <>
                          <Grid
                            container
                            sx={{
                              backgroundColor: imageErr ? "#FEE4E2" : "#EAF0FF",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                minHeight: 250,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                onDragOver={handleDragImage}
                                onDrop={handleDropImage}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    color: " #0F2C6F",
                                    fontWeight: 600,
                                  }}
                                >
                                  Drag and drop files here
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    color: "#0F2C6F",
                                    fontWeight: 400,
                                  }}
                                >
                                  Files Supported: svg, .png, .jpg (Max 25 MB)
                                </Typography>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    backgroundColor: "white",
                                    color: "#0F2C6F",
                                    fontSize: "14px",
                                    mt: 2,
                                    border: "1px solid #AAC3FF",
                                    borderRadius: " 6px",
                                    "&:hover": { backgroundColor: "white" },
                                  }}
                                  onClick={handleImage}
                                >
                                  Choose file
                                  <input
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.svg,.gif"
                                    ref={inputImg}
                                    onChange={handleInputImage}
                                    hidden
                                  />
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                          <Typography sx={{ color: "#f44336", mt: 2 }}>
                            {imageErr}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                </Grid>
              )}
            </Box>
          </Box>

          <Box sx={{ backgroundColor: "#FFFFFF", p: 2 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Onboarded Date</StyledTableCell>
                    <StyledTableCell>Offboarded Date</StyledTableCell>
                    <StyledTableCell>Submitted by</StyledTableCell>
                    <StyledTableCell>Role</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.length > 0 &&
                    tableData.map((value) => (
                      <TableRow
                        sx={{ backgroundColor: " #F5F8FF", cursor: "pointer" }}
                      >
                        <StyledTableCell sx={{ fontWeight: 400 }}>
                          {moment(value?.access_start_date).format(
                            "DD-MM-YYYY"
                          )}
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 400 }}>
                          {value?.offboard_date
                            ? moment(value?.offboard_date).format("DD-MM-YYYY")
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 400 }}>
                          {value?.created_by_name}
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 400 }}>
                          {value?.created_by_role}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", py: 5 }}>
            <Box>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  height: "48px",
                  backgroundColor: "#123FA9",
                  "&:hover": { backgroundColor: "#123FA9" },
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={1} />
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    empDetails: state.CommonReducer.empDetails,
    bmsRole: state.BMSStaffReducer.rolesList,
    levelList: state.BMSStaffReducer.levelList,
    accessList: state.BMSStaffReducer.accessList,
    defaultAccessRights: state.BMSStaffReducer.defaultAccessRights,
    addBmsEmpStatus: state.BMSStaffReducer.addBmsEmpStatus,
    levelDatas: state.CommonReducer.levelDatas,
    unitDatas: state.CommonReducer.unitDatas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmpDetailsById: (data) => dispatch(CommonAction.getEmpDetailsById(data)),
    getStaffRole: () => dispatch(BmsStaffAction.getStaffRole()),
    getStaffLevel: () => dispatch(BmsStaffAction.getStaffLevel()),
    getAccessList: () => dispatch(BmsStaffAction.getAccessList()),
    getdefaultAccessRights: (data) =>
      dispatch(BmsStaffAction.getdefaultAccessRights(data)),
    addBmsEmp: (data) => dispatch(BmsStaffAction.addBmsEmp(data)),
    getAllLevels: () => dispatch(commonAction.getAllLevels()),
    getUnitsByLevelid: (data) => dispatch(commonAction.getUnitsByLevelid(data)),
    setBmsStaffReducernull: () =>
      dispatch(BmsStaffAction.setBmsStaffReducernull()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStaff);
