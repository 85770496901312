import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

console.log("window.location ",window.location)
const isIP = /^(\d{1,3}\.){3}\d{1,3}$/.test(window.location.hostname); 
const firebaseConfig = {
    apiKey: "AIzaSyCO2jE5Y3EBSgPwtWCPd9BIX70EmTkVmgM",
    authDomain: "singaporeland-8e25c.firebaseapp.com",
    projectId: "singaporeland-8e25c",
    storageBucket: "singaporeland-8e25c.appspot.com",
    messagingSenderId: "677053920442",
    appId: "1:677053920442:web:3ab5bd2a7e59ae9cb47e83",
    measurementId: "G-V0QGTDK90B"

    // apiKey: "AIzaSyACJVlpts5NwHT-rgZufjKnGtsXhzKujg0",
    // authDomain: "websgland.firebaseapp.com",
    // projectId: "websgland",
    // storageBucket: "websgland.appspot.com",
    // messagingSenderId: "310625631754",
    // appId: "1:310625631754:web:981131f6d95baf402ca9f3",
    // measurementId: "G-EPJMB3Y2P7"
};

// export const app = initializeApp(firebaseConfig);
// export const messaging = getMessaging(app);

let app, messaging;

if (!isIP) {
  app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
  console.log("Firebase initialized successfully.");
} else {
  console.log("Firebase initialization skipped because the hostname is an IP address.");
}

// Conditional exports
export { app, messaging };
