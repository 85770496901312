import {Checkbox} from '@mui/material'
import { ReactComponent as CheckboxIcon } from "../../Assets/icons/CheckboxIcon.svg";

export const DRAFT_COLUMNS = [
//   {
//     id: 1,
//     column_name: <Checkbox icon={<CheckboxIcon/>}
//     size="small"
// /> 
//   },
  {
    id: 2,
    column_name: 'Submitted on'
  },
  {
    id: 3,
    column_name: 'Title'
  }
    ,
  {
    id: 4,
    column_name: 'Publish On'
  },
]

export const SCHEDULE_COLUMNS = [
  // {
  //   id: 1,
  //   column_name: <Checkbox/>
  // },
  {
    id: 2,
    column_name: 'Published On'
  },
  {
    id: 3,
    column_name: 'Title'
  }
    ,
  {
    id: 4,
    column_name: 'End Date'
  },
  {
    id: 5,
    column_name: 'Submitted By'
  },
  {
    id: 6,
    column_name: ''
  }
]

export const ARCHIVED_COLUMNS = [
  // {
  //   id: 1,
  //   column_name: <Checkbox/>
  // },
  {
    id: 2,
    column_name: 'Published On'
  },
  {
    id: 3,
    column_name: 'Title'
  }
    ,
  {
    id: 4,
    column_name: 'End Date'
  },
  {
    id: 5,
    column_name: 'Submitted By'
  },
]

export const ACTIVE_COLUMNS = [
  // {
  //   id: 1,
  //   column_name: <Checkbox/>
  // },
  {
    id: 2,
    column_name: 'Published On'
  },
  {
    id: 3,
    column_name: 'Title'
  }
    ,
  {
    id: 4,
    column_name: 'End Date'
  },
  {
    id: 5,
    column_name: 'Read count'
  },
  {
    id: 6,
    column_name: 'Submitted By'
  },
  {
    id: 7,
    column_name: 'Popup'
  },
  {
    id: 8,
    column_name: ''
  }
]