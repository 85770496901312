import React, { useEffect, useState, useRef } from "react";
import { format, parse } from 'date-fns';
import * as XLSX from "xlsx";
import PropTypes, { element } from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import moment from "moment";
import ReactDOM from "react-dom/client";
import PhoneInput from 'react-phone-input-2';
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { ReactComponent as FileUploadIcon } from "../../Assets/icons/FileUploadIcon.svg";
import { ReactComponent as Excel } from "../../Assets/icons/Excel.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/Alertclose.svg";
import { ReactComponent as ExcelDeleteIcon } from "../../Assets/icons/DeleteExcel.svg";
import { ReactComponent as RefreshIcon } from '../../Assets/icons/Refresh.svg';
import { ReactComponent as DatePickerIcon } from '../../Assets/icons/DatePickerIcon.svg';
import styled from "@emotion/styled";
import ExcelTable from "../../Utils/ExcelTable";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { connect } from "react-redux";
import * as commonAction from "../../redux/actions/commonAction";
import ToastAlert from "../common/Alert";
import { getLastUpdatedTime, getUTCDateFormat, preventDatePickerKeyboard } from '../../Utils/utils.js';
import ExportExcel from "../../Utils/ExportExcel";
import Loader from "../common/Loader";
import { countryCode } from "./countryCode.js";
import parsePhoneNumber from 'libphonenumber-js'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant="p">{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign:"center",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: [theme.palette.common.white],
    fontSize: "13px",
    padding: '8px 30px',
    color: ' #0F2C6F',
    fontWeight:550
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    padding: "8px 12px",
    textAlign: "center",
    color: ' #0F2C6F'
  },
  [theme.breakpoints.up("md")]: {
    [`&.${tableCellClasses.body}`]: {
      fontSize: "14px",
      padding: "16px",
    },
    [`&.${tableCellClasses.head}`]: {
      fontSize: "14px",
    },
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  cursor: 'pointer',
  "&:nth-of-type(odd)": {
    backgroundColor: "#FAFBFF",
  },
}));
const tabs = [
  { id: 1, title: "  Template" },
  { id: 2, title: " Upload " },
  { id: 3, title: " Review " },
];
const StepperTab = ({setStatusNull, addBatchEmployees, batchAddStatus, existEmailList, getAllLevels, getUnitsByLevelid, levelDatas, unitDatas }) => {
  const navigate = useNavigate();
  const uploadClickRef = useRef();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [accStartDate, setAccStartDate] = useState({
    startDate: null,
    currentDate: new Date(),
    dateStr: ''
  });
  const [unit, setUnit] = useState('');
  const [level, setLevel] = useState('');
  const [noOfPerson, setNoOfPerson] = useState("");
  const [progress, setProgress] = useState(0);
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);
  const [excelFileName, setExcelFileName] = useState("");
  const [excelData, setExcelData] = useState(null);
  const [excelSubmitData, setExcelSubmitData] = useState([]);
  const [submitOpen, setSubmitOpen] = useState(false);
  const [errors, setErrors] = useState({
    startDate: "",
    unitNo: "",
    level: "",
    numberOfPerson: "",
  });
  const [reviewCheckBox, setReviewCheckBox] = useState(true)
  const [batchEmpCount, setBatchEmpCount] = useState(0)
  const [onBoardStatus, setOnboardStatus] = useState({ message: '', status: false, color: '', icon: '' })
  const [batchAddFaild, setBatchAddFaild] = useState({ status: false, repeatedEmails: [], copiedStatus: false })
  const [excelError, setExcelErrors] = useState({ messageStatus: false, errorcounts: 0, totalLength: 0 })
  const [time, setTime] = useState({ hour: '', minute: '', am_pm: '', timeStr: '' });
  const [row, setRow] = useState([]);
  const [levelUnits, setLevelUnits] = useState({ levels: [], units: [] })
  const [showPreview, setShowPreview] = useState(false)
  const [loaderStatus,setLoaderStattus] = useState(false)
  const [tabDisabled,setTabDisabled] = useState({tab2:true,tab3:true})
  const [phone, setPhone] = useState([]);
  const [arrayPhone,setArrayPhone] = useState([])
  const [formattedMobileNumbers, setFormettedMobileNumbers] = useState([])
 
  useEffect(() => {
    setTime({ ...time, timeStr: getLastUpdatedTime() })
    getAllLevels();
  }, [])

  useEffect(() => {
    if (levelDatas.length > 0) {
      setLevelUnits({ ...levelUnits, ['levels']: levelDatas })
    }
  }, [levelDatas])

  useEffect(() => {
    if (unitDatas.length > 0) {
      setLevelUnits({ ...levelUnits, ['units']: unitDatas })
    }

  }, [unitDatas])

  useEffect(() => {
    if (batchAddStatus === 'Employee batch submitted successfully and waiting for BMS Coordinator approval.') {
      setLoaderStattus(false)
      setSubmitOpen(false)
      setOnboardStatus({ message: 'Employee onboarded successfully', status: true, color: 'green', icon: 'tick' })
      setTimeout(() => {
        setStatusNull()
        setOnboardStatus({ message: '', status: false, color: '', icon: '' })
        navigate("/manage_employees/1")
      }, 5000);
    } else if (batchAddStatus === 'Below emails already exists. Please try another emails') {
      setLoaderStattus(false)
      setSubmitOpen(false)
      setBatchAddFaild({ status: true, repeatedEmails: existEmailList, copiedStatus: false })
      setTimeout(() => {
        setStatusNull()
      }, 5000);
    }

  }, [batchAddStatus])


  const submitModalOpen = () => {
    let count = 0;
    excelData.forEach(element => {
      if (element.is_selected === true) {
        count++;
      }

    });
    setSubmitOpen(true);
    setBatchEmpCount(count)
  }
  const submitModalClose = () => {
    setSubmitOpen(false);
    setBatchAddFaild({ status: false, repeatedEmails: [], copiedStatus: false })
    // setExcelFile(null)
    // setValue(1)
    // setExcelData(null)
  }

  const validate = () => {
    let noError = true;
    if (!accStartDate.startDate || accStartDate.startDate==null) {
      setErrors((prevValue) => ({
        ...prevValue,
        startDate: "Should not be empty!",
      }));
      noError = false;
    }
    else {
      setErrors((prevValue) => ({
        ...prevValue,
        startDate: "",
      }));
    }
    if (!unit.unit_id) {
      setErrors((prevValue) => ({
        ...prevValue,
        unitNo: "Should not be empty!",
      }));
      noError = false;
    }
    if (!noOfPerson) {
      setErrors((prevValue) => ({
        ...prevValue,
        numberOfPerson: "Should not be empty!",
      }));
      noError = false;
    }
    if (!level.level_id) {
      setErrors((prevValue) => ({
        ...prevValue,
        level: 'Should not be empty'
      }))
    }
    return noError;
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone();

  useEffect(() => { 
    if(acceptedFiles){
      setShowPreview(false)
      handleFileUpload(acceptedFiles);
    }
  
  }, [acceptedFiles]);

  useEffect(() => {
    if (excelSubmitData?.length > 1) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [excelSubmitData]);

 
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const generateExcel = () => {
    validate();
    let noError = validate();
    if (noError) {
      let newArray = [];
      let arr = [];
     
      arr.push(excelSubmitData[0]);

      if (noOfPerson > 0) {
        for (let i = 1; i <= noOfPerson; i++) {
          newArray.push({
            "OB-SN": i.toString(),
            "First Name": "",
            "Last Name": "",
            "Work Email": "",
            "Mobile Country Code (Only for foreign numbers)": '',
            "Mobile Number": '',
            "Job Title": '',
            "Access Start Date": moment(accStartDate.dateStr, 'DD-MM-YYYY').toDate(),
            "Office Location": `${level.level_name}-${unit.unit_name}` ,
            "Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)": '',
            "Remarks - Optional (For eg, sub tenant, contract staff)": '',
          })
        }
        setExcelSubmitData(newArray);
      }

      if (noOfPerson > 5) {
        for (let i = 1; i <= 5; i++) {
          arr.push([
            i.toString(),
            "",
            "",
            "",
            "",
            "",
            "",
            accStartDate.dateStr,
            `${level.level_name}-${unit.unit_name}`,
            "",
            "",
            "",
          ]);
        }
        setRow(arr);
      } else {
        for (let i = 1; i <= noOfPerson; i++) {
          arr.push([
            i.toString(),
            "",
            "",
            "",
            "",
            "",
            "",
            accStartDate.dateStr,
            `${level.level_name}-${unit.unit_name}`,
            "",
            "",
            "",
          ]);
        }
        setRow(arr);
      }

      setShowPreview(true)
      setTimeout(()=>{
        let id = document.getElementById("downloadExcelButton")
        id.scrollIntoView({ block: 'center', behavior: 'smooth' })
      },500 )
   
    }
  };

  // Function to calculate the width of a string in Excel units
// const getColumnWidth = (text) => {
//   return { wch: Math.max(text.length + 2, 10) };
// };

// const generateExcel = () => {
//   let noError = validate();
//   if (noError) {
//     let newArray = [];
//     let arr = [];
//     const headers = [
//       "OB-SN", "First Name", "Last Name", "Work Email", "Mobile Country Code (Only for foreign numbers)",
//       "Mobile Number", "Job Title", "Access Start Date", "Office Location",
//       "Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)",
//       "Remarks - Optional (For eg, sub tenant, contract staff)"
//     ];

//     arr.push(headers);

//     if (noOfPerson > 0) {
//       for (let i = 1; i <= noOfPerson; i++) {
//         newArray.push({
//           "OB-SN": i.toString(),
//           "First Name": "",
//           "Last Name": "",
//           "Work Email": "",
//           "Mobile Country Code (Only for foreign numbers)": '',
//           "Mobile Number": '',
//           "Job Title": '',
//           "Access Start Date": moment(accStartDate.dateStr, 'DD-MM-YYYY').toDate(),
//           "Office Location": `${level.level_name}-${unit.unit_name}`,
//           "Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)": '',
//           "Remarks - Optional (For eg, sub tenant, contract staff)": '',
//         });
//       }

//       setExcelSubmitData(newArray);

//       for (let i = 1; i <= noOfPerson; i++) {
//         arr.push([
//           i.toString(),
//           "",
//           "",
//           "",
//           "",
//           "",
//           "",
//           accStartDate.dateStr,
//           `${level.level_name}-${unit.unit_name}`,
//           "",
//           "",
//           "",
//         ]);
//       }
//       setRow(arr);
      
//       setShowPreview(true);
//       setTimeout(() => {
//         let id = document.getElementById("downloadExcelButton");
//         id.scrollIntoView({ block: 'center', behavior: 'smooth' });
//       }, 500);
//     }
//   }
// };

  const handleNext = () => {
    if (value < 2) {
      setValue((prevValue) => prevValue + 1);
      setTabDisabled({
        tab2:false,
        tab3:true
      })
    }
  };

  const handleAccessDate = (value) => {
    let dd = value.getDate() <= 9 ? `0${value.getDate()}` : value.getDate(), mm = value.getMonth() + 1 <= 9 ? `0${value.getMonth() + 1}` : value.getMonth() + 1, yyyy = value.getFullYear();

    setAccStartDate({ ...accStartDate, dateStr: `${dd}-${mm}-${yyyy}`, startDate: value });
    setErrors({ ...errors, startDate: '' });



  }

  const handleUnitChange = (e) => {
    let { value } = e.target;
    if (value) {
      setErrors((prevValue) => ({ ...prevValue, unitNo: "" }));
      setUnit(value);
    }
    setTime({ ...time, timeStr: getLastUpdatedTime() })
  };

  const handleNoOfPerson = (e) => {
    setTime({ ...time, timeStr: getLastUpdatedTime() })
    const { value } = e.target;
    if (parseInt(value) <= 50 && parseInt(value) > 1) {
      setNoOfPerson(value);
      setErrors((prevValue) => ({
        ...prevValue,
        numberOfPerson: "",
      }));
    }else if(parseInt(value)<=0){
      setErrors((prevValue) => ({
        ...prevValue,
        numberOfPerson: "Please enter a number between 2 to 50",
      }));
    }
    else {
      setErrors((prevValue) => ({
        ...prevValue,
        numberOfPerson: "Please enter a number between 2 to 50",
      }));
    }

    if (!value || value === null) {
      setNoOfPerson();
    }
    return;
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileSubmit = (e) => {
    e.preventDefault();
    let elementId = 0;
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const excelDatas = XLSX.utils.sheet_to_json(worksheet)
      const data = excelDatas.filter(row => row['OB-SN'] != undefined);
      data.forEach(element => {
        element.is_selected = true;
        element.id = parseInt(elementId) + 1;
        elementId++

          //Converting excel serial number into date

        if(element['OB-SN'] !=undefined) {
          for (let key in element) {
            if(key == 'Access Start Date') {
              console.log(element[key]);
              element[key] = ExcelDateToJSDate(element[key])
            }
          }
        }
      });

      console.log('data 1',data)
      setExcelData(data);
      console.log('uns ', (data[0]['Mobile Number'] != undefined && data[0]['Mobile Number'] !='') && (data[0]['Mobile Country Code (Only for foreign numbers'] ==undefined || data[0]['Mobile Country Code (Only for foreign numbers'] =='' )) //data[0]['Mobile Number'] != undefined && data[0]['Mobile Number'] !=''
      // const dat = data?.map((item,index) => (` ${item['Mobile Country Code (Only for foreign numbers)']} ${item['Mobile Number']}`))
      const dat = data?.map((item,index) => (item['Mobile Number'] != '' && item['Mobile Number'] !=undefined) && (item['Mobile Country Code (Only for foreign numbers)'] =='' || item['Mobile Country Code (Only for foreign numbers)'] == undefined) ? `+65 ${item['Mobile Number']}` : ` ${item['Mobile Country Code (Only for foreign numbers)']} ${item['Mobile Number']}` )
      setPhone(dat)
      console.log('dat ',dat)
      setArrayPhone(dat)
      // but(dat)
      setValue(2);
    } else {
      console.log('data 2')
      setExcelData(null);
    }
  };

useEffect(() => {
    if(arrayPhone?.length !=0) {
      let arr = []
      arrayPhone.forEach((item,index) => {
        const element = document?.getElementsByClassName('ins')[index]
        console.log('element ',element)
        arr.push(element.value)
       
      })
      setFormettedMobileNumbers(arr)
     
    }
},[arrayPhone])
console.log('fomratt ',formattedMobileNumbers)

  const handlePhoneChange = (index, value, event) => {
   
    console.log(`Phone number at index ${index} changed to: ${event}`);
};


  const handleCheckbox = (newData) => {
    let updatedData = excelData;
    for (let i = 0; i < updatedData.length; i++) {
      if (updatedData[i].id === newData.id) {
        updatedData[i].is_selected = !excelData[i].is_selected;
        if (!updatedData[i].is_selected) {
          setReviewCheckBox(false)
        }
      }
    }
    setExcelData([...updatedData]);

  };
  const handleAllCheck = (e) => {
    const { checked } = e.target;
    setReviewCheckBox(!reviewCheckBox)
    let updatedData = excelData;
    for (let i = 0; i < updatedData.length; i++) {
      updatedData[i].is_selected = checked === true ? true : false;
    }
    setExcelData([...updatedData]);
  };

  function ExcelDateToJSDate(serial) {     
    var utc_days  = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;                                        
    var date_info = new Date(utc_value * 1000);
 
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
 
    var total_seconds = Math.floor(86400 * fractional_day);
 
    var seconds = total_seconds % 60;
 
    total_seconds -= seconds;
 
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    const inputDate = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds) 
    const day = String(inputDate.getDate()).padStart(2, '0');
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const year = inputDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    
    return formattedDate;
 }

 const findCountryCode = (code,num) => {
  console.log('test ', code,num)
  for(let i=0;i<countryCode.length; i++) {
    if(countryCode[i]?.hasOwnProperty(code?.toString())) {
      console.log('len ',countryCode[i][code] >= num.toString().length)
      console.log('len1 ',countryCode[i][code],  num.toString().length)
      if(countryCode[i][code] >= num.toString().length) {
        return 'Satisfied'
        
      } else {
        return 'Not Satisfied'
      }
      
    }
  }
  return 'Not Available'
 }
  
  const handleExcelInputs = (newExcelFile) => {
    const workbook = XLSX.read(newExcelFile, { type: "buffer"});
    const worksheetName = workbook.SheetNames[0]; 
    const worksheet = workbook.Sheets[worksheetName];
    const excelDatas = XLSX.utils.sheet_to_json(worksheet)
    const data = excelDatas.filter(row => row['OB-SN'] != undefined);
    let countVar = 0;
    let arr = [];
    let errArray = []
        console.log('holys ',workbook.Sheets)
    if(workbook.Sheets.data.A1.v != "OB-SN") errArray.push('In Excel, A1 value should be "OB-SN"')
    if(workbook.Sheets.data.B1.v != "First Name") errArray.push('In Excel, B1 value should be "First Name"')
    if(workbook.Sheets.data.C1.v != "Last Name") errArray.push('In Excel, C1 value should be "Last Name"')
    if(workbook.Sheets.data.D1.v != "Work Email") errArray.push('In Excel, D1 value should be "Work Email"')
    if(workbook.Sheets.data.E1.v != "Mobile Country Code (Only for foreign numbers)") errArray.push('In Excel, E1 value should be "Mobile Country Code (Only for foreign numbers)"')
    if(workbook.Sheets.data.F1.v != "Mobile Number") errArray.push('In Excel, F1 value should be "Mobile Number"')
    if(workbook.Sheets.data.G1.v != "Job Title") errArray.push('In Excel, G1 value should be "Job Title"')
    if(workbook.Sheets.data.H1.v != "Access Start Date") errArray.push('In Excel, H1 value should be "Access Start Date"')
    if(workbook.Sheets.data.I1.v != "Office Location") errArray.push('In Excel, I1 value should be "Office Location"')
    if(workbook.Sheets.data.J1.v != "Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)") errArray.push('In Excel, J1 value should be "Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"')
    if(workbook.Sheets.data.K1.v != "Remarks - Optional (For eg, sub tenant, contract staff)") errArray.push('In Excel, K1 value should be "Remarks - Optional (For eg, sub tenant, contract staff)"')

    //Converting excel serial number into date
    data.forEach((element,index)=>{
      if(element['OB-SN'] !=undefined) {
        for (let key in element) {
          if(key == 'Access Start Date') {
            console.log(element[key]);
            element[key] = ExcelDateToJSDate(element[key])
          }
        }
      }
    })
    //validating datas
    console.log('adtda',data);
    data.forEach((element,index)=>{
      if(element['OB-SN'] !=undefined && errArray?.length == 0) {
        let obj = {}
        if(element['Mobile Number']) {
          console.log('mobNo ',element['Mobile Number'])
        }
        // if(element['Mobile Number'] != undefined) {
        //   console.log('yeas ',element['Mobile Number'] )
        // } else {
        //   console.log('coded ',(element['Mobile Country Code (Only for foreign numbers)'] =="" || element['Mobile Country Code (Only for foreign numbers)'] ==undefined))
        //   console.log('nose ',((element['Mobile Country Code (Only for foreign numbers)'] !="" || element['Mobile Country Code (Only for foreign numbers)'] !=undefined) &&  (element['Mobile Number'] !="" || element['Mobile Number'] !=undefined)))
        // }
      if((element['Mobile Country Code (Only for foreign numbers)'] !="" && element['Mobile Country Code (Only for foreign numbers)'] != undefined) &&  (typeof element['Mobile Country Code (Only for foreign numbers)'] != 'number' && parseInt(element['Mobile Country Code (Only for foreign numbers)']) == 'NaN') ) {
        console.log('nums type ', typeof element['Mobile Country Code (Only for foreign numbers)'] )
        obj.mobileCode = 'numeric_mobile_code'
      } else if((element['Mobile Number'] =="" && element['Mobile Number'] ==undefined) && typeof element['Mobile Number'] != 'number') {
        obj.mobile = 'numeric_mobile_number'
      }
      else if(((element['Mobile Country Code (Only for foreign numbers)'] =="" || element['Mobile Country Code (Only for foreign numbers)'] ==undefined) &&  (element['Mobile Number'] =="" || element['Mobile Number'] ==undefined))) {
        console.log('cl ')
          obj.mobile = true
      } else if((element['Mobile Country Code (Only for foreign numbers)'] == undefined || element['Mobile Country Code (Only for foreign numbers)'] == '') && (element['Mobile Number'] != undefined || element['Mobile Number'] != "" )) {
          if(element['Mobile Number'].toString().length <= 8) {
            obj.mobile = true
          } else {
            obj.mobile = 'sg-length'
          }
        }
        else if((element['Mobile Country Code (Only for foreign numbers)']!=undefined || element['Mobile Country Code (Only for foreign numbers)']!='') && (element['Mobile Number']==undefined || element['Mobile Number'] =="") ){
          console.log('mobbbbbs ',element['Mobile Number'])
            obj.mobile = 'false'
        
      }
        else if((element['Mobile Country Code (Only for foreign numbers)'] !="" || element['Mobile Country Code (Only for foreign numbers)'] !=undefined) &&  (element['Mobile Number'] !="" || element['Mobile Number'] != undefined)) {
          const result = findCountryCode(element['Mobile Country Code (Only for foreign numbers)'],element['Mobile Number'])
          console.log('ressssssss ',result)
         switch(result) {
          case "Satisfied":
            obj.mobile = true
            break;
          case "Not Satisfied":
            obj.mobile = 'Not Satisfied'
            break;
          case "Not Available":
            obj.mobileCode = 'Not Available'
            break;
         }
        
        } 

        // if(element['Mobile Country Code (Only for foreign numbers)']!=undefined &&  typeof element['Mobile Country Code (Only for foreign numbers)'] === 'number'){
        //   obj.mobileCode = true
        // }else{
        //   obj.mobileCode = false
        // }

        // if(element["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"]==="Y" || element["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"]==="" || element["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"]==undefined){
        //   obj.role = true
        // }else{
        //   obj.role= false
        // }

        if(element["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"] == "" || element["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"] == "Y" || element["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"] == "y" || /^(yes)$/i.test(element["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"])){
          console.log('true')
          obj.role = true
        }else{
          console.log('false')
          obj.role= false
        }
        const specialCharactersRegex = /[`~!@#$%^&*()\-_+={}[\]:;"'<>,.?\/|\\]/;
        console.log("element ",element)
        if(element['First Name']!=undefined &&  typeof element['First Name'] === 'string' && element['First Name'].length>=3){
          obj.firstName = true
        } else{
          obj.firstName = false
        }
        if(element['First Name'].length >=3 && specialCharactersRegex.test(element['First Name'])) {
          console.log("within if regx special ",specialCharactersRegex.test(element['First Name']))
          obj.firstName = 'special_character'
        } 

        if(element['Last Name']!=undefined &&  typeof element['Last Name'] === 'string' && element['Last Name'] !=""){
          obj.lastName = true
        } else{
          obj.lastName = false
        }
        if(element['Last Name'].length !="" && specialCharactersRegex.test(element['Last Name'])) {
          obj.lastName = 'special_character'
        }

        if(element['Job Title']!=undefined && element['Job Title'].length>0){
          obj.jobTitle = true
        }else{
          obj.jobTitle = false
        }

        if(element['Work Email']!=undefined && validateEmail(element['Work Email'])){
          obj.email = true
        }else{
          obj.email = false
        }

        if(element['Access Start Date']!=undefined){
          obj.access = true
        }else{
          obj.access = false
        }

        if(element['Access Start Date']==accStartDate.dateStr){
          obj.accessEqual = true
        }else{
          obj.accessEqual = false
        }

        if(typeof element['Access Start Date'] =='number'){
          obj.accessType = false
        }else{
          obj.accessType = true
        }

        if(element['Office Location']!=undefined){
          obj.location = true
        }else{
          obj.location = false
        }

        if(element['Office Location']==`${level?.level_name}-${unit?.unit_name}`){
          obj.locationEqual = true
        }else{
          obj.locationEqual = false
        }
        arr.push(obj)
      }
    })  
    data.forEach(element => {
      if (element['Access Start Date']!=undefined &&  element['Access Start Date']==accStartDate.dateStr && typeof element['Access Start Date']!='number'  && element['Office Location']!=undefined &&  element['Office Location']==`${level?.level_name}-${unit?.unit_name}` &&   
       (element["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"] == "" || element["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"] == "Y" || element["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"] == "y" || /^(yes)$/i.test(element["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"])) && 
          // typeof element['Mobile Country Code (Only for foreign numbers)'] !=undefined &&   
          // typeof element['Mobile Country Code (Only for foreign numbers)'] === 'number'
          //typeof element['Mobile Number'] !=undefined &&  
          //typeof element['Mobile Number'] === 'number'
        element['Job Title']!=undefined && data.length<=50 &&  element['Job Title'].length>0 && element['Work Email']!=undefined &&   validateEmail(element['Work Email']) && typeof element['First Name'] !=undefined &&  typeof element['First Name'] === 'string' && element['First Name'].length>=3  && element['Last Name']!=undefined && typeof element['Last Name'] === 'string') {
        countVar += 1
      }
    });

    //returning the error text 

    if(arr.length>0){
      if(data.length>50){
        errArray.push('Please enter only 50 rows')
      }else{
        console.log(arr);
        arr.map((value,index)=>{
          // if(value.mobile==false){
          //     errArray.push(`Row ${index+1} - Enter a valid Mobile Number`)
          // }
          // if(value.mobileCode==false){  
          //   errArray.push(`Row ${index+1} - Mobile Code must be a number`)
          // }
          console.log("value ",value)
          if(value.role==false){
            errArray.push(`Row ${index+1} - Enter a valid Role`)
          }
          if(value.firstName==false){
            errArray.push(`Row ${index+1} - First Name must have at least three characters`)
          } else if(value.firstName == 'special_character') {
            errArray.push(`Row ${index+1} - First Name should not contain special characters`)
          }
          if(value.lastName===false){
            errArray.push(`Row ${index+1} - Last name must have at least one character`)
          }  else if(value.lastName == 'special_character') {
            errArray.push(`Row ${index+1} - Last Name should not contain special characters`)
          }
          if(value.mobile==='false'){
            errArray.push(`Row ${index+1} - Enter Mobile Number`)
          }
          if(value.mobile ==='sg-length'){
            errArray.push(`Row ${index+1} - Enter Valid Mobile Number`)
          }
          if(value.mobile ==='Not Satisfied'){
            errArray.push(`Row ${index+1} - Enter Correct Length Mobile Number`)
          }
          if(value.mobile == 'numeric_mobile_number') {
            errArray.push(`Row ${index+1} - Enter Numeric Value`)
          }
          if(value.mobileCode =='Not Available'){
            errArray.push(`Row ${index+1} - Enter Valid Country Code`)
          }
          if(value.mobileCode == 'numeric_mobile_code') {
            errArray.push(`Row ${index+1} - Enter Numeric Mobile Code`)
          }
          if(value.jobTitle===false){
            errArray.push(`Row ${index+1} - Enter Job Title`) 
          }
          if(value.email==false){
            errArray.push(`Row ${index+1} - Enter the valid Email`)
          }
          if(value.access==false){
            errArray.push(`Row ${index+1} - Enter Access Start Date`)
          }
          if(value.accessEqual==false){
            errArray.push(`Row ${index+1} - Access Start Date in excel differs from selected Access Start Date`)
          }
          if(value.accessType==false){
            errArray.push(`Row ${index+1} - Access start date should be in valid format`)
          }
          if(value.location==false){
            errArray.push(`Row ${index+1} - Enter Office Location`)
          }
          if(value.locationEqual==false){
            if(data[index]['Office Location']?.includes('#')) {
              errArray.push(`Row ${index+1} - Location in excel is given with '#'. Please try like this ${level?.level_name}-${unit?.unit_name}`)
            } else {
              errArray.push(`Row ${index+1} - Location in excel differs from selected Location`)
            }
          }
        })
      }
     
    }
    let finalStatus = data.length === countVar ? false : true
    if(finalStatus || errArray.length != 0){ 
        setExcelErrors({ messageStatus: finalStatus ? finalStatus :  errArray.length != 0, errorcounts: data.length - countVar, totalLength: data.length,messageData:errArray  })
    }else{
      setExcelErrors({messageStatus:finalStatus,messageData:[],totalLength: data.length,errorcounts:0})
    }

    if (finalStatus || errArray.length != 0) {
      setExcelFile(null); 
    }
    
  }
  
  const validateEmail = (email) => {
    let isValidEmail = false
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(emailRegex)) {
      isValidEmail = true
    }
    return isValidEmail;
  }
  const deleteExcelDatas = () => {
    setExcelFile(null)
    setProgress(0);
  }
  const handleFileUpload = (acceptedFiles) => {
    console.log('handle')
    let selectedFile = acceptedFiles[0];
    if (selectedFile) {
      if (selectedFile && (selectedFile.type === "text/csv" || selectedFile.type === "application/vnd.ms-excel" || selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        setTimeout(() => {
          setProgress(45);
        }, 1000);
        setExcelFileName(selectedFile.name);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
          handleExcelInputs(e.target.result)
        };
        setTimeout(() => {
          setProgress(80);
        }, 2000);

        setTimeout(() => {
          setProgress(100);
          window.scrollTo(0, document.body.scrollHeight);
        }, 3000);
      } else {
        setExcelFileError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      return;
    }
  };

  const handleDeleteButton = () => {
    setAccStartDate({ ...accStartDate, dateStr: '', startDate: '' });
    setNoOfPerson('');
    setLevel('');
    setUnit('');

  }

  const splitedCode = (phoneNumber, from) => {
    const firstSpaceIndex = phoneNumber?.indexOf(' ');
    const countryCode = phoneNumber?.substring(0, firstSpaceIndex);
    const remainingNumber = phoneNumber?.substring(firstSpaceIndex + 1);
    console.log('countryCode ',countryCode)
    console.log('remainingNumber ',remainingNumber)
    if(from == 'code') {
      return countryCode
    } else  {
      return remainingNumber
    }
  }

  const handleConfirm = () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let finalData = []
    console.log('excelData ',excelData)
    excelData.forEach((element,index) => {
      if (element.is_selected === true) {
        // let date = new Date(element['Access Start Date'].split("-").reverse().join("-"))
        let obj = {
          email: element[`Work Email`],
          access_start_date: element['Access Start Date'].split("-").reverse().join("-"),
          // access_start_date:getUTCDateFormat(date),
          job_title: element['Job Title'],
          last_name: element['Last Name'],
          first_name: element['First Name'],
          level_no: level.level_id,
          mobile_code:((element[`Mobile Country Code (Only for foreign numbers)`]==undefined || element[`Mobile Country Code (Only for foreign numbers)`]=='') && (element['Mobile Number']==undefined || element['Mobile Number']=='')) ? '' : ((element['Mobile Number'] !=undefined && element['Mobile Number'] !='') && (element[`Mobile Country Code (Only for foreign numbers)`]==undefined || element[`Mobile Country Code (Only for foreign numbers)`]=='') ) ? '+65' : splitedCode(formattedMobileNumbers[index], 'code'),
          mobile_no:((element[`Mobile Country Code (Only for foreign numbers)`]==undefined || element[`Mobile Country Code (Only for foreign numbers)`]=='') && (element['Mobile Number']==undefined || element['Mobile Number']=='')) ? '' : ((element['Mobile Number'] !=undefined && element['Mobile Number'] !='') && (element[`Mobile Country Code (Only for foreign numbers)`]==undefined || element[`Mobile Country Code (Only for foreign numbers)`]=='') ) ? splitedCode(formattedMobileNumbers[index], 'number_code') : splitedCode(formattedMobileNumbers[index], 'number'),
          role: ((element["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"]=='Y' || element["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"] == "yes") ? 'Tenant Manager' : 'Employee' ),
          unit_no: unit.unit_id,
        }
        finalData.push(obj)
      }
    });
    const Data = {
      company_id: userData.company_id,
      created_by: userData.user_id,
      employeeDataArrayList: JSON.stringify(finalData)
    }
    addBatchEmployees(Data, localStorage.getItem('userToken'))
    setSubmitOpen(false)
    setLoaderStattus(true)
    setTimeout(()=>{
      setLoaderStattus(false)
    },20000)
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(batchAddFaild.repeatedEmails)
    setBatchAddFaild({ ...batchAddFaild, copiedStatus: true })
    setTimeout(() => {
      submitModalClose()
    }, 5000);
  }

  const handleBatchLevel = (e) => {
    setLevel(e.target.value)
    setErrors({ ...errors, level: '' })
    setUnit('')
    setLevelUnits({ ...levelUnits, ['units']: [] })
    getUnitsByLevelid({ level_id: e.target.value.level_id })

  }
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          background: "#fff",
        }}
      >
        {loaderStatus && <Loader status={loaderStatus}/>}
        <ToastAlert message={onBoardStatus.message} status={onBoardStatus.status} color={onBoardStatus.color} icon={onBoardStatus.icon} />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            aria-label="basic tabs example"
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
          > 
              <Tab 
                label={
                  <Box>
                      <span style={{ backgroundColor: '#123FA9', fontSize: '12px', color: '#FFFFFF', borderRadius: '50%', padding: '3px 7px' }}>1</span>
                      <span style={{ textTransform: 'capitalize', marginLeft: '3px', color: '#123FA9', fontSize: '14px' }}>Template</span>
                  </Box>
                }
              />
              <Tab disabled={tabDisabled.tab2} label={<Box>
                      <span style={{ backgroundColor: '#123FA9', fontSize: '12px', color: '#FFFFFF', borderRadius: '50%', padding: '3px 7px' }}>2</span>
                      <span style={{ textTransform: 'capitalize', marginLeft: '3px', color: '#123FA9', fontSize: '14px' }}>Upload</span>
                  </Box>} />
            <Tab disabled={tabDisabled.tab3} label={<Box>
                      <span style={{ backgroundColor: '#123FA9', fontSize: '12px', color: '#FFFFFF', borderRadius: '50%', padding: '3px 7px' }}>3</span>
                      <span style={{ textTransform: 'capitalize', marginLeft: '3px', color: '#123FA9', fontSize: '14px' }}>Review</span>
                  </Box>}
          />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Typography sx={{ color: '#0F2C6F', fontSize: '18px', fontWeight: 500 }}>Company Details</Typography>
          <Typography sx={{ color: '#6F80A9;', fontSize: '14px', fontWeight: 500, mt: 1, mb: 3 }}>Fill in below form to onboard a new tenant company</Typography>
          <Box
            sx={{ display: "flex", flexDirection: "row", rowGap: "30px" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#0F2C6F", 
                  marginBottom: "8px",

                }}
              >
                Access Start Date
              </label>
              <Box sx={{ position: 'relative' }}>
                <DatePicker disablePast components={{
                  OpenPickerIcon:DatePickerIcon
                }} value={accStartDate.startDate} inputFormat='dd/MM/yyyy'  onChange={(value) => handleAccessDate(value)} renderInput={(params)=> <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} />
                {errors.startDate && (
                  <Typography variaint="span" sx={{ color: "red" }}>
                    {errors.startDate}
                  </Typography>
                )}
              </Box>
            

            </Box>

           

            <Box sx={{ ml: 10 }}>
              <label style={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 600 }}>Office Unit Number *</label>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ mt: 1 }}>
                  <FormControl >
                    <InputLabel sx={{ color: ' #9AA6C3' }}>Level</InputLabel>
                    <Select label='Level' sx={{ height: '48px', width: '88px' }} value={level}
                      onChange={(e) => {
                        handleBatchLevel(e)
                      }}>
                      {levelUnits.levels.length > 0 ? levelUnits.levels.map((item) => (
                        <MenuItem value={item}>{item.level_name}</MenuItem>
                      )) : ''}

                    </Select>
                  </FormControl>
                  {errors.level && (
                    <Typography variaint="span" sx={{ color: "red" }}>
                      {errors.level}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mt: 1 }}>
                  <FormControl sx={{ ml: 5, width: '146px' }}>
                    <InputLabel sx={{ color: ' #9AA6C3' }}>Search Unit</InputLabel>
                    <Select label='Search Unit' sx={{ height: '48px', width: '146px' }} value={unit} onChange={(e) => handleUnitChange(e)}>
                      {levelUnits.units.length > 0 ? levelUnits.units.map((item) => (
                        <MenuItem value={item}>{item.unit_name}</MenuItem>
                      )) : ''
                      }
                    </Select>
                  </FormControl>
                  {errors.unitNo && (
                    <Typography variaint="span" sx={{ color: "red", ml: 5 }}>
                      {errors.unitNo}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>


          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", mt: 5 }}>
            <label
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#0F2C6F",
                marginBottom: "8px",
              }}
            >
              Number of person to onboard *
            </label>
            <TextField
              type="text"
              sx={{ width: { md: "240px", xs: "100%" }, border: "#DBDFE9" }}
              value={noOfPerson}
              onChange={(e) => handleNoOfPerson(e)}
              autoComplete="off"
            />
            <Typography sx={{ color: '#6F80A9', fontSize: '14px', mt: 1 }}>50 Maximum</Typography>
            {errors.numberOfPerson && (
              <Typography variaint="span" sx={{ color: "red" }}>
                {errors.numberOfPerson}
              </Typography>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {excelFile ? (
            <>
              <Box>
                <Typography sx={{ color: '#0F2C6F', fontSize: "18px" }}>Upload here</Typography>
                <Typography sx={{ color: '#6F80A9', fontSize: '14px' }}>Finance Manager will be the person who is authorised to make payments on behalf of the company. </Typography>
              </Box>
              <Box
                sx={{
                  height: "100px",
                  background: "#fff",
                  padding: { md: "15px", xs: "4px 0" },
                }}
              >
                <Box
                  sx={{
                    padding: { md: "12px 24px", xs: "14px" },
                    borderRadius: "8px",
                    border: "1px solid #DBDFE9",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Icon */}
                  <Excel width="40px" />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      marginLeft: "16px",
                      rowGap: { md: "8px", xs: "4px" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { md: "14px", sm: "12px", xs: "11px" },
                            fontWeight: 500,
                            color: "#0F2C6F",
                          }}
                        >
                          {excelFileName}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { md: "14px", sx: "12px", xs: "11px" },
                            fontWeight: 400,
                            color: "#6F80A9",
                          }}
                        >
                          {Math.round(acceptedFiles[0].size * (progress / 100))}{" "}
                          of {acceptedFiles[0].size}KB
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: { md: "center", xs: "flex-end" },
                          flexDirection: { md: "row", xs: "column-reverse" },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { md: "14px", sm: "12px", xs: "9px" },
                            fontWeight: 500,
                            color: "#0F2C6F",
                          }}
                        >
                          {progress !== 100
                            ? `Uploading... ${Math.floor(progress)}%`
                            : "Upload Complete"}
                        </Typography>
                        {/* <ExcelUploadCancel height="26px" width="26px" /> */}
                        <Box sx={{ border: '1px solid #AAC3FF', borderRadius: '6px', p: 1, cursor: "pointer" }}>
                          < ExcelDeleteIcon onClick={() => deleteExcelDatas()} />
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress
                        variant="determinate"
                        value={progress}
                        sx={{
                          background: "#F3F4F8",
                          "& .MuiLinearProgress-barColorPrimary": {
                            backgroundColor: "#33B7A5",
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                padding: "20px",
                background: "#EAF0FF",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              {...getRootProps({
                isDragActive,
              })}
            >
              <Box sx={{ textAlign: "center", padding: "44px 0" }}>
                <FileUploadIcon />
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    color: "#0F2C6F",
                    paddingTop: "20px",
                  }}
                >
                  Drag and Drop files here
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#0F2C6F",
                    paddingTop: "10px",
                  }}
                >
                  Please fill in employee details into excel template generated in previous step and upload below. 
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#0F2C6F",
                    mt:1
                  }}
                >
                If you would like to manually enter details, please select single employee instead. 
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#0F2C6F",mt:1 }}
                >
                  Files Supported: .csv, .xls (Max 5 MB)
                </Typography>
                {/* {excelError.messageStatus ? <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "red" }}
                >
                  {excelError.messageData ? excelError.messageData : `In uploaded File ${excelError.errorcounts} out of ${excelError.totalLength} have invalid emails/phone no's/last name/first name must have 3 characters.Upload valid data`}
                </Typography> : ''} */}
                <Grid container >
                {excelError.messageStatus && excelError?.messageData?.map((value)=>(
                  <Grid item xs={excelError?.messageData?.length>25 ? 6 : 12}>
                    <Typography  sx={{m:0.5, fontSize: "14px", fontWeight: 400, color: "red",textAlign:'left' }}>{value}</Typography>
                  </Grid>
                ))}
                </Grid>
                <Box sx={{ paddingTop: "30px" }}>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      background: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "#0F2C6F",
                      "&:hover": {
                        background: "#0F2C6F",
                        color: "white",
                      },
                    }}
                    // onClick={() => {
                    //   uploadClickRef.current.click();
                    // }}
                  >
                    Choose File
                  </Button>
                  <input
                    type="file"
                    name="file_upload"
                    accept="application/vnd.ms-excel"
                    style={{ display: "none" }}
                    {...getInputProps({ ref: uploadClickRef })}
                  />
                </Box>
                {excelFileError && (
                  <Alert severity="warning" sx={{ marginTop: "20px" }}>
                    {excelFileError}
                  </Alert>
                )}
              </Box>
            </Box>
          )}  
        </TabPanel>
        <TabPanel value={value} index={2} >
          <div>
            {excelData === null && (
              <Box
                sx={{
                  textAlign: "center",
                  color: "#0F2C6F",
                  padding: "100px",
                  fontWeight: 500,
                  fontSize: "23px",
                }}
              >
                No File Selected
              </Box>
            )}
            {excelData !== null && (
              <>
                <Box>
                  <Typography sx={{ fontSize: '14px', color: '#6F80A9', py: 1 }}>Please ensure that all data provided are accurate to prevent any delays in processing.
                    You will be notified within 1-3 working days once checks has been processed.
                    Once employee is onboarded, they will receive an email in their work email provided to download and
                    install the Singland App for access.</Typography>
                </Box>

                <Grid container sx={{ border: '1px solid #DBDFE9', borderRadius: '6px', width: '75%', p: 3, my: 2 }}>
                  <Grid item xs={12} sm={5}>
                    <Typography sx={{ color: ' #0F2C6F', fontSize: '14px' }}>Access start Date</Typography>
                    <Typography sx={{ color: ' #0F2C6F', fontSize: '12px' }}>{accStartDate.dateStr}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Typography sx={{ color: '#0F2C6F', fontSize: '12px' }}>{excelData.length} personnel</Typography>
                    <Typography sx={{ color: '#0F2C6F', fontSize: '12px' }}>Office Unit :#{level.level_name}-{unit.unit_name}</Typography>
                  </Grid>
                </Grid>

                <Box sx={{ overflowX: "auto" }}>
                  <Table>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>
                          <Checkbox
                            size="small"
                            
                            onClick={(e) => handleAllCheck(e)}
                            checked={reviewCheckBox}

                          />
                        </StyledTableCell>

                        <StyledTableCell>Employee Name</StyledTableCell>
                        <StyledTableCell>Work Name</StyledTableCell>
                        <StyledTableCell sx={{width:'200px'}}>Mobile Number</StyledTableCell>
                        <StyledTableCell>Job Title</StyledTableCell>
                        {/* <StyledTableCell>Access Start Date</StyledTableCell>
                      <StyledTableCell>Default Office Unit</StyledTableCell> */}
                        <StyledTableCell>Tenant Role</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {excelData?.map((data, index) => (

                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            <Checkbox
                              onChange={() => handleCheckbox(data)}
                              checked={data.is_selected}
                              size="small"
                              
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            {data["First Name"]  + " "  +  data["Last Name"]}
                          </StyledTableCell>
                          <StyledTableCell>{data["Work Email"]}</StyledTableCell>
                          <StyledTableCell sx={{width:'180px'}}>
                          {/* +{data["Mobile Country Code (Only for foreign numbers)"]}  {data["Mobile Number"]} */}
                          {/* {(data[`Mobile Country Code (Only for foreign numbers)`]==undefined || data[`Mobile Country Code (Only for foreign numbers)`]=='' ||  data['Mobile Number']==undefined || data['Mobile Number']=='') ? '' : `+${data[`Mobile Country Code (Only for foreign numbers)`]}`} {(data['Mobile Number']==undefined || data['Mobile Number']=='' ||  data[`Mobile Country Code (Only for foreign numbers)`]==undefined ||  data[`Mobile Country Code (Only for foreign numbers)`]=='') ? '' : data['Mobile Number'] } */}
                          {(data['Mobile Country Code (Only for foreign numbers)'] !="" || data['Mobile Country Code (Only for foreign numbers)'] !=undefined) &&  (data['Mobile Number'] !="" || data['Mobile Number'] != undefined) && 
                          <Box sx={{display:'flex'}}>
                            <PhoneInput 
                            inputProps={{
                              className:'ins'
                            }}
                            // value={`${data['Mobile Country Code (Only for foreign numbers)']} ${data['Mobile Number']}`}
                            value={phone[index]}
                            onChange={(value,country,event,format) => handlePhoneChange(index, value, event)}
                            country='sg'
                            // className='ins'
                            disabled
                            inputStyle={{
                              width: '150px',
                              background: '#F3F4F8',
                              fontSize: '13px',
                              height: '48px',
                              fontWeight: 500,
                              border: 'none',
                              backgroundColor:'transparent',

                            }}
                            containerStyle={{
                              borderRadius: '6px',
                              width:'226px'
                            }}
                            buttonStyle={{
                              border: 'transparent',
                              margin: 2,
                              backgroundColor:'transparent'
                            }}
                            />
                          </Box>
                          }
                          </StyledTableCell>
                          <StyledTableCell>{data["Job Title"]}</StyledTableCell>
                          {/* <StyledTableCell>
                          {data["Access Start Date"]}
                        </StyledTableCell>
                        <StyledTableCell>
                          {data["Office Location"]}
                        </StyledTableCell> */}
                          <StyledTableCell>
                            {data["Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"] === "Y" ? 'Tenant Manager' :  "Employee" }
                               
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </>
            )}
          </div>
        </TabPanel>
      </Box>



      <Box sx={{ display: "flex", justifyContent: "space-between", py: 5 }}>
        <Box sx={{ display: 'flex' }}>
          <RefreshIcon />
          <Typography sx={{ color: ' #9AA6C3', fontSize: '14px', ml: 1 }}>Last updated at {time.timeStr} </Typography>
        </Box>
        <Box>
          { value===0 &&  <Button variant='outlined'
            onClick={() => handleDeleteButton()}
            sx={{ height: '50px', border: '1px solid #AAC3FF', borderRadius: '8px', mr: 2, textTransform: 'capitalize' }}> Clear All</Button>
          }

          {!excelFile && value===0 &&  (
            <Button
              onClick={() => generateExcel()}
              variant="contained"
              sx={{ textTransform: "none", margin: "10px 0", height: '48px', borderRadius: '8px' }}
            >
              Generate Template
            </Button>
          )}

          {excelFile && value === 2 && (
            <Button
              onClick={submitModalOpen}
              variant="contained"
              sx={{ textTransform: "none", margin: "10px 0", height: '50px' }}
            >
              Submit
            </Button>
          )}
          {excelFile && progress === 100 && value === 1 && (
            <Button
              onClick={(e) => handleFileSubmit(e)}
              variant="contained"
              sx={{ textTransform: "none", margin: "10px 0", height: '50px' }}
            >
              Continue
            </Button>
          )}
        </Box>
      </Box>
      {excelSubmitData?.length > 1 && showPreview  && value===0 && (
        <Box

          sx={{ background: "white", padding: "30px", marginTop: "20px",marginBottom:"5px" }}
          id="downloadExcelButton"
        >
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#0F2C6F" }}
          >
            Your template has been generated below. Once downloaded, you may
            press next to upload the batch file.
          </Typography>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#0F2C6F" }}
          >
            You may amend the details above and press on generate template again
            to generate amended template.
          </Typography>

          <Box sx={{ marginTop: "40px" }}>
           
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                border: "1px solid #AAC3FF",
                fontSize: "14px",
                fontWeight: 600,
                color: "#0F2C6F",
              }}
            >
              <ExportExcel excelData={excelSubmitData} fileName={'Bulkemployeesonboardformat'} />
            </Button>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#0F2C6F",
                margin: "15px 0",
              }}
            >
              Example of excel file to be filled up. The template is customised
              to the details you have selected above.
            </Typography>
            <Box sx={{ overflowX: "auto" }}>
              <ExcelTable
                excelData={row}
                date={accStartDate.dateStr}
                unit={`${level?.level_name}-${unit?.unit_name}`}
              />
            </Box>
          </Box>
          <Box sx={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
            <Button
              variant="contained"
              sx={{ textTransform: "none",mt:2 }}
              onClick={() => handleNext()}
            >
              Next
            </Button>
          </Box>
          
        </Box>
      )}

      <Modal
        open={submitOpen}
        onClose={submitModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Grid container>
          <Grid item md={3.5} sm={2} xs={0.5} />

          <Grid
            sx={{
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
             
            }}
            md={5}
            sm={8}
            xs={11}
            item
          >
            <Box sx={{display:"flex",justifyContent:"space-between",borderBottom:"1px solid #C0C7D7",p:2}}>
                <Typography sx={{color:"#0F2C6F",fontSize:"18px"}}>Confirm Batch Onboarding</Typography>
                <CloseIcon
                width={24}
               
                style={{ float: "right" }}
                onClick={submitModalClose}
                cursor="pointer"
              />
            </Box>
            
            <Box
              sx={{
               p:2
              }}
            >
          
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#0F2C6F",
                  margin: "10px 0 50px 0",
                }}
              >
                 Please confirm that you wish to onboard ({batchEmpCount}) employees.
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button 
                  onClick={submitModalClose}
                  variant="outlined" 
                  sx={{border:"1px solid #AAC3FF",borderRadius:"6px",backgroundColor:"#FFFFFF","&:hover:":{backgroundColor:'#FFFFFF'},textTransform:"capitalize",color:"#0F2C6F",fontSize:"14px",fontWeight:550}}>Cancel</Button>
                <Button
                  variant="contained"
                  sx={{
                    background: "#123FA9",
                    fontSize: "14px",
                    fontWeight: 550,
                    textTransform:"capitalize",
                    "&:hover":{backgroundColor:"#123FA9"}
                  }}
                  onClick={handleConfirm}
                  disabled={batchEmpCount > 0 ? false : true}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item md={3.5} sm={2} xs={0.5} />
        </Grid>
      </Modal>
      <Dialog
      
        open={batchAddFaild.status}
        onClose={submitModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant='h6'>Batch Employee Onboard Failed!</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Below listed email's are already exist
          </DialogContentText>
          {batchAddFaild.repeatedEmails.map((item) => (
            <DialogContentText id='' sx={{ color: '#000000' }}>
              {item}
            </DialogContentText>
          ))}
          {batchAddFaild.copiedStatus ? <Typography variant='p' color='success'>Emails copied to clibboard!</Typography> : ''}
        </DialogContent>
        <DialogActions sx={{display:"flex",justifyContent:"space-between",m:1}}>
          <Button sx={{textTransform:"capitalize"}} variant="outlined" onClick={copyToClipboard}>{'Copy Emails & Close'}</Button>
          <Button sx={{textTransform:"capitalize"}} variant="outlined" onClick={submitModalClose}>Close</Button>

        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    batchAddStatus: state.CommonReducer.batchAddStatus,
    existEmailList: state.CommonReducer.existEmailList,
    levelDatas: state.CommonReducer.tenantOccupiedLeves,
    unitDatas: state.CommonReducer.tenantOccupiedUnits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBatchEmployees: (data, token) =>
      dispatch(commonAction.addBatchEmployees(data, token)),
    getAllLevels: () => dispatch(commonAction.getTenantAccessLevel()),
    getUnitsByLevelid: (data) => dispatch(commonAction.getTenantAccessUnit(data)),
    setStatusNull: () => dispatch(commonAction.setStatusNull()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StepperTab);