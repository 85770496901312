import { Box, Button, Input, InputLabel, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux'
import { ReactComponent as Logo } from "../../Assets/icons/Logo.svg";
import loginBgImage from '../../Assets/icons/loginbg.svg';
import * as loginaction from "../../redux/actions/loginaction";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ReactComponent as Left } from "../../Assets/icons/Left.svg";

const Signup = ({ hideSignUp,loginNullService, email, sendOTPService, verifyOTPService, passwordResetService, sendStatus, verifyStatus, resetStatus }) => {
    const [renderContainer, setRenderContainer] = useState('email');
    const [buttonText, setButtonText] = useState('Send OTP');
    const [passwordVisibility, setPasswordVisibility] = useState({ confirmPassword: false, password: false });
    const [details, setDetails] = useState({ email: email, otp: '', password: '', confirmPassword: '', user_id: null });
    const [validationLabelColor, setValidationLabelColor] = useState({
        eight_digits:"#900527",
        numeric:"#900527",
        capital:"#900527",
        small:"#900527",
        special:"#900527",
        eight_digits_bgcolor:"#F9E7EB",
        numeric_bgcolor:"#F9E7EB",
        capital_bgcolor:"#F9E7EB",
        small_bgcolor:"#F9E7EB",
        special_bgcolor:"#F9E7EB",
    })
    const [validationStatus, setValidationStatus] = useState({
        eight_digits: false,
        numeric: false,
        capital: false,
        small: false,
        special: false,
    });
    const [errorStatus, setErrorStatus] = useState({
        email: "",
        password: "",
        loginError: "",
        commonError: "",
        otpError: '',
        confirmPassword: ''
    });

useEffect(() => {
    if (renderContainer === 'password') {
        window.onbeforeunload = function () {
            return "Changes you made may not be saved.";
        };
    } else {
        window.onbeforeunload = null; // Clean up if the condition is false
    }

    return () => {
        window.onbeforeunload = null; // Clean up on component unmount
    };
}, [renderContainer]);

    const handleChange = (e) => {
        let { name, value } = e.target;
        setDetails({ ...details, [name]: value })
        setErrorStatus(prev => ({...prev, [name] : ""}))
    }

    const handleKeyPress = (e) => {
        if (e.key === " ") {
            e.preventDefault(); 
        }
    }

    useEffect(() => {
        if (sendStatus?.response_status==0) {
            setErrorStatus({ ...errorStatus, ['loginError']: sendStatus?.message })
            setTimeout(()=>{
                loginNullService()
                setErrorStatus({ loginError: '' });
                hideSignUp(false)
            },5000)
        } else if (sendStatus?.response_status==1) {
            setDetails({ ...details, ['user_id']: sendStatus?.data_array?.user_id })
            loginNullService();
            setErrorStatus({ loginError: 'Otp send successfully!' });
            setTimeout(() => {
                setRenderContainer('otp')
                setButtonText('Verify OTP')
                setErrorStatus({ loginError: '' });
            }, 1000);

        }
    }, [sendStatus])

    useEffect(() => {
        if (verifyStatus?.response_status==0) {
            setErrorStatus({ ...errorStatus, ['loginError']:verifyStatus?.message  })
        } else if (verifyStatus?.response_status==1) {
            loginNullService();
            setErrorStatus({ loginError: verifyStatus?.message });
            setTimeout(() => {
                setRenderContainer('password')
                setButtonText('Change Password')
                setErrorStatus({ loginError: '' });
            }, 1000);

        }
    }, [verifyStatus])
    useEffect(() => {
        if (resetStatus?.error_message === 'Password Updated Failed') {
            setErrorStatus({ ...errorStatus, ['loginError']: 'Password Updated Failed' })
        } else if (resetStatus?.message === 'Password updated successfully') {
            loginNullService();
            setErrorStatus({ loginError: 'Password Updated Successfully!' });
            setTimeout(() => {          
                setErrorStatus({ loginError: '' });
                hideSignUp(false)
            }, 1000);

        }
    }, [resetStatus])
    const buttonHandler = () => {
        if (renderContainer === 'email') {
            if (verifyEmail()) {
                let data = {
                    email: details.email
                }
                sendOTPService(data)
            }
        } else if (renderContainer === 'otp') {
            if (verifyOTP()) {
                let data = {
                    otp: details.otp,
                    user_id: details.user_id
                }
                verifyOTPService(data)
            }
        } else if (renderContainer === 'password') {
            if (verifyPassword()) {
                let data = {
                    password: details.confirmPassword,
                    user_id: details.user_id
                }
                passwordResetService(data)
            }
        }
    }
    const verifyEmail = () => {
        let status = false;
        const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (details.email === '') {
            status = false;
            setErrorStatus({ ...errorStatus, ['email']: 'Email Required!' })
        } else if (!details.email.match(emailRegex)) {
            status = false;
            setErrorStatus({ ...errorStatus, ['email']: 'Invalid email!' })
        } else if (details.email.match(emailRegex)) {
            status = true;
            setErrorStatus({ ...errorStatus, ['email']: '' })
        }
        return status;
    }

    const verifyOTP = () => {
        let status = false;
        if (details.otp.length === 6) {
            status = true;
            setErrorStatus({ ...errorStatus, ['otpError']: '' })
        } else if (details.otp.length > 0 && details.otp.length < 6) {
            status = false;
            setErrorStatus({ ...errorStatus, ['otpError']: '6 digit otp required!' })
        } else {
            status = false;
            setErrorStatus({ ...errorStatus, ['otpError']: 'OTP required!' })
        }

        return status;
    }

    const verifyPassword = () => {
        let confirmPasswordMessage=''
        if((/\s/g).test(details.password)) {
            setErrorStatus(prev => ({ ...prev, ['confirmPassword']: 'password contains disallowed characters'}))
            return
        }
        console.log("details.confirmPassword === details.password ",Object.values(validationStatus).every(Boolean))
        if(Object.values(validationStatus).every(Boolean)) {
            if(details.confirmPassword === details.password) {
                        confirmPasswordMessage='';
                    } else {
                        confirmPasswordMessage='Password not matched !';
                    }
              setErrorStatus(prev => ({ ...prev, ['confirmPassword']:confirmPasswordMessage}))
        }
        return Object.values(validationStatus).every(Boolean) && details.confirmPassword === details.password
    }

    const hideShowPassword = (property, propertyStatus) => {
        setPasswordVisibility({ ...passwordVisibility, [property]: propertyStatus })
    }
    useEffect(() => {
        console.log("details?.password?.length ",details?.password?.length >= 8)
        if(details?.password?.length >= 8) {
            setValidationLabelColor(prev => ({...prev, eight_digits:"#005934", eight_digits_bgcolor:"#D3F2E5"}))
        } else {
            setValidationLabelColor(prev => ({...prev, eight_digits:"#900527", eight_digits_bgcolor:"#F9E7EB"}))
        }

        if(details?.password?.length > 0 && (/\d/).test(details?.password)) {
            setValidationLabelColor(prev => ({...prev, numeric:"#005934", numeric_bgcolor:"#D3F2E5"}))
        } else {
            setValidationLabelColor(prev => ({...prev, numeric:"#900527", numeric_bgcolor:"#F9E7EB"}))
        }

        if(details?.password?.length > 0 && (/[A-Z]/).test(details?.password)) {
            setValidationLabelColor(prev => ({...prev, capital:"#005934", capital_bgcolor:"#D3F2E5"}))
        } else {
            setValidationLabelColor(prev => ({...prev, capital:"#900527", capital_bgcolor:"#F9E7EB"}))
        }

        if(details?.password?.length > 0 && (/[a-z]/).test(details?.password)) {
            setValidationLabelColor(prev => ({...prev, small:"#005934", small_bgcolor:"#D3F2E5"}))
        } else {
            setValidationLabelColor(prev => ({...prev, small:"#900527", small_bgcolor:"#F9E7EB"}))
        }

        if(details?.password?.length > 0 && (/[@#$*`.,/?;:\-_()\|'"%^&+!=]/).test(details?.password)) {
            setValidationLabelColor(prev => ({...prev, special:"#005934", special_bgcolor:"#D3F2E5"}))
        } else {
            setValidationLabelColor(prev => ({...prev, special:"#900527", special_bgcolor:"#F9E7EB"}))
        }

        const status = {
            eight_digits: details?.password?.length >= 8,
            numeric: /\d/.test(details?.password || ""),
            capital: /[A-Z]/.test(details?.password || ""),
            small: /[a-z]/.test(details?.password || ""),
            special: /[@#$*`.,/?;:\-_()\|'"%^&+!=]/.test(details?.password || ""),
        };

        setValidationStatus(status);


    },[details.password])

    const handleBack = () => {
        if(renderContainer == "email") {
            hideSignUp(false)
        } 
        else if(renderContainer == "otp") {
            setRenderContainer('email')
            setButtonText('Send OTP')
        }  
        setErrorStatus({
            email: "",
            password: "",
            loginError: "",
            commonError: "",
            otpError: '',
            confirmPassword: ''
        })
    }

    
    const handleOTPChange = (otp) => {
        setDetails({ ...details, ['otp']: otp })
        setErrorStatus(prev => ({...prev, otpError:"", loginError:""}))
    }

    const sendResendOTP = () => {
        if(details.email) {
            sendOTPService({email: details.email})
        }
    }

    const handleEnter = (e) => {
        console.log("enter pressed")
        if(e.key === "Enter") buttonHandler();
    }

    const emailContainer = () => {
        return (
            <>
                <InputLabel
                    sx={{
                        fontSize: { sm: "14px", xs: "12px" },
                        fontWeight: 600,
                        color: "#0F2C6F",
                        mb:1,
                        marginTop: "25px",
                    }}
                >
                    Corporate Email Address
                </InputLabel>
                <Input
                    type="email"
                    name="email"
                    placeholder="Johndoe@example.com"
                    style={{
                        padding: "12px 6px",
                        border: "1px solid #DBDFE9",
                        borderRadius: "6px",
                        outlineColor: "#123FA9",
                        color: "#0F2C6F",
                    }}
                    value={details.email}
                    onKeyDown={(e) => handleEnter(e)}
                    onChange={(e) => handleChange(e)}
                    inputProps={{
                        sx: {
                            height: { md: "25px", sm: "20px", xs: "4px" },
                            fontSize: { md: "16px", sm: "14px", xs: "12px" },
                        },
                    }}
                    autoComplete="off"
                    disableUnderline
                />
                <Typography variant="p" color="error" sx={{ fontSize: "15px" }}>
                    {errorStatus.email}
                </Typography>
            </>
        )
    }

    const otpContainer = () => {
        return (
            <>
                <InputLabel
                    sx={{
                        fontSize: { sm: "14px", xs: "12px" },
                        fontWeight: 600,
                        color: "#0F2C6F",
                        mb:1
                    }}
                >
                    Verify OTP
                </InputLabel>
                <OtpInput
                    isInputNum={true}
                    value={details.otp}
                    onChange={handleOTPChange}
                    onKeyDown={(e) => handleEnter(e)}
                    numInputs={6}
                    containerStyle={{ display: 'flex', justifyContent: 'center', gap:"8px" }}
                    inputStyle={{ width: '40px', height: '40px' }}
                    separator={<span> &nbsp;</span>}
                />
                {/* <Typography variant="p" color="error" sx={{ fontSize: "15px" }}>
                    {errorStatus.otpError}
                </Typography> */}
            </>
        )
    }

    const passwordContainer = () => {
        return (
            <>
            <Box sx={{display:"flex", flexDirection:"column", border:"1px dashed #9747FF", borderRadius:"5px", gap:"8px", padding:1}}>
            <Typography variant="p" color="error" sx={{ fontSize: "12px", color: validationLabelColor?.eight_digits, backgroundColor: validationLabelColor?.eight_digits_bgcolor, p:'3px', borderRadius:"6px", width:"fit-content" }}>Password must be 8 characters</Typography>
            <Typography variant="p" color="error" sx={{ fontSize: "12px", color: validationLabelColor?.numeric, backgroundColor:validationLabelColor?.numeric_bgcolor,  p:'3px', borderRadius:"6px",width:"fit-content" }}>Password must be atleast one numeric character</Typography>
            <Typography variant="p" color="error" sx={{ fontSize: "12px", color: validationLabelColor?.capital, backgroundColor:validationLabelColor?.capital_bgcolor,  p:'3px', borderRadius:"6px",width:"fit-content" }}>Password must be atleast one capital character</Typography>
            <Typography variant="p" color="error" sx={{ fontSize: "12px", color: validationLabelColor?.small, backgroundColor:validationLabelColor?.small_bgcolor,  p:'3px', borderRadius:"6px",width:"fit-content" }}>Password must be atleast one small character</Typography>
            <Typography variant="p" color="error" sx={{ fontSize: "12px", color: validationLabelColor?.special, backgroundColor:validationLabelColor?.special_bgcolor,  p:'3px', borderRadius:"6px",width:"fit-content" }}>Password must be atleast one special character</Typography>
            </Box>
                <InputLabel
                    sx={{
                        fontSize: { sm: "14px", xs: "12px" },
                        fontWeight: 600,
                        color: "#0F2C6F",
                        marginTop: "10px",
                    }}
                >
                    Password
                </InputLabel>
                <Input
                    type={passwordVisibility.password ? 'text' : 'password'}
                    placeholder="xxxxx"
                    name="password"
                    endAdornment={passwordVisibility.password ?
                        <VisibilityIcon style={{cursor:'pointer'}} onClick={() => hideShowPassword('password', !passwordVisibility.password)} /> :
                        <VisibilityOffIcon style={{cursor:'pointer'}} onClick={() => hideShowPassword('password', !passwordVisibility.password)} />}
                    sx={{
                        padding: "12px 6px",
                        border: "1px solid #DBDFE9",
                        borderRadius: "6px",
                        outlineColor: "#123FA9",
                    }}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={handleKeyPress}
                    inputProps={{
                        sx: {
                            height: { md: "25px", sm: "20px", xs: "4px" },
                            fontSize: { md: "16px", sm: "14px", xs: "12px" },
                            color: "#0F2C6F",
                        },
                    }}
                    disableUnderline
                />
                {errorStatus.password && <Typography variant="p" color="error" sx={{ fontSize: "15px" }}>
                    {errorStatus.password}
                </Typography>
                }

                <InputLabel
                    sx={{
                        fontSize: { sm: "14px", xs: "12px" },
                        fontWeight: 600,
                        color: "#0F2C6F",
                        marginTop: "10px",
                    }}
                >
                    Confirm Password
                </InputLabel>
                <Input
                    type={passwordVisibility.confirmPassword ? 'text' : 'password'}
                    endAdornment={passwordVisibility.confirmPassword ?
                        <VisibilityIcon style={{cursor:'pointer'}} onClick={() => hideShowPassword('confirmPassword', !passwordVisibility.confirmPassword)} />
                        : <VisibilityOffIcon style={{cursor:'pointer'}} onClick={() => hideShowPassword('confirmPassword', !passwordVisibility.confirmPassword)} />}

                    placeholder="xxxxx"
                    name="confirmPassword"
                    sx={{
                        padding: "12px 6px",
                        border: "1px solid #DBDFE9",
                        borderRadius: "6px",
                        outlineColor: "#123FA9",
                        color: "#0F2C6F",
                    }}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={handleKeyPress}
                    inputProps={{
                        sx: {
                            height: { md: "25px", sm: "20px", xs: "4px" },
                            fontSize: { md: "16px", sm: "14px", xs: "12px" },
                        },
                    }}
                    disableUnderline
                />
                {errorStatus.confirmPassword && <Typography variant="p" color="error" sx={{ fontSize: "15px" }}>
                    {errorStatus.confirmPassword}
                </Typography>
                }

                {errorStatus.commonError &&
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography variant="p" color="error" sx={{ fontSize: "15px", mt: 2 }}>
                            {errorStatus.commonError}
                        </Typography>
                    </Box>
                }
            </>
        )
    }


    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100%",
                backgroundImage:`url(${loginBgImage})`
                // background: "#0F2C6F",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    background: "white",
                    padding: { md: "42px 34px", sm: "30px", xs: "20px" },
                    flexDirection: "column",
                    borderRadius: "8px",
                    width: { sm: "360px", xs: "270px" },
                    mx: "auto",

                    boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.05)",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        alignItems: "center",
                        position:"relative",
                    }}
                >
                    <Logo
                        style={{
                            width: "224px",
                            height: "48px",
                        }}
                    />
                </Box>
                {renderContainer == "otp" && <Left onClick={handleBack} style={{left:"0px", cursor:"pointer"}} />}
                <Box
                    sx={{ padding: "20px 0 ", display: "flex", flexDirection: "column" }}
                    rowGap={1}
                >

                    {
                        renderContainer === 'email' ?
                            emailContainer() :
                            renderContainer === 'otp' ?
                                otpContainer() :
                                renderContainer === 'password' ?
                                    passwordContainer() : null
                    }


                </Box>
                <Box
                    sx={{
                        paddingBotton: "50px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{ textTransform: "none", background: "#123FA9" }}
                        onClick={() => buttonHandler()}
                    >
                        {buttonText}
                    </Button>
                    <Typography variant="p" color="error" sx={{ fontSize: "15px", mt: 1 }}>
                        {errorStatus.loginError || errorStatus.otpError}
                    </Typography>
                     {renderContainer == "otp" && <Typography sx={{cursor:"pointer",textTransform: "none",textAlign:"center",mt:'12px',color:"#0F2C6F",fontSize:"14px",textDecoration:"underline",textUnderlineOffset:"5px"}}
                        onClick={sendResendOTP}>Resend OTP
                        </Typography>}
                      {renderContainer == "email" && <Typography sx={{cursor:"pointer",textTransform: "none",textAlign:"center",mt:1,color:"#0F2C6F",fontSize:"14px",textDecoration:"underline",textUnderlineOffset:"5px"}}
                           onClick={handleBack}>Back to Login
                    </Typography>}
                    {/* <a
              href="/"
              style={{
                textAlign: "center",
                paddingTop: "15px",
                fontSize: "14px",
                fontWeight: 400,
                color: "#0F2C6F",
              }}
            >
              Create new account
            </a> */}
                </Box>
            </Box>
        </Box>
    )
}
const mapStateToProps = (state) => {
    return {
        sendStatus: state.LoginReducer.sendStatus,
        verifyStatus: state.LoginReducer.verifyStatus,
        resetStatus: state.LoginReducer.resetStatus,
    }

}
const mapDispatchToProps = (dispatch) => {
    return {
        sendOTPService: (data) => dispatch(loginaction.sendOTPService(data)),
        verifyOTPService: (data) => dispatch(loginaction.verifyOTPService(data)),
        passwordResetService: (data) => dispatch(loginaction.passwordResetService(data)),
        loginNullService: () => dispatch(loginaction.loginNullService()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Signup)