import {
  Box,
  FormControl,
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
  Checkbox,
  TextField,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../Assets/icons/back.svg";
import { ReactComponent as CheckboxIcon } from "../../Assets/icons/CheckboxIcon.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import EmptyAvatar from "../../Assets/icons/Default.png";
import AvatarRemove from "../../Assets/icons/avatarRemove.png";
import EmptyJpg from "../../Assets/icons/EmptyJpg.png";
import PhoneInput from "react-phone-input-2";
import {
  initialSubmissionRights,
  initialAccessRights,
  initialSystemAccess,
  initialApprovalRights,
} from "../../components/Data/Data.js";
import Modal from "../common/faultalert";
import * as BmsStaffAction from "../../redux/actions/bmsStaffAction";
import * as commonAction from "../../redux/actions/commonAction";
import { connect } from "react-redux";
import ToastAlert from "../common/Alert";
import Loader from "../common/Loader";
import {
  getUTCDateFormat,
  MenuProps,
  preventDatePickerKeyboard,
} from "../../Utils/utils";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { containsNumbers } from "../../Utils/validation.js";
import { makeStyles, useTheme } from "@mui/styles";
import Close from "@mui/icons-material/Close";
import ApiService from "../../AxiosConfig/ApiService.js";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    maxWidth: 312,
  },
}));

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

let alertData = {
  label: "Confirm Individual Staff Onboarding",
  description:
    "Please confirm that you wish to onboard (1) personnel. If the above details is correct, please press confirm below. You may click the cross button on top right to return to form.",
  buttonvalue: "Confirm",
};

const StyledTab = styled(Tab)({
  textTransform: "capitalize",
  color: " #123FA9",
});

const spanStyle = {
  backgroundColor: "#123FA9",
  color: "#FFFFFF",
  borderRadius: "50%",
  padding: "4px 6px",
  fontSize: "12px",
  marginRight: "5px",
};

const StyledTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#0F2C6F",
  marginBottom: "5px",
});

const StyledOutlinedInput = styled(OutlinedInput)({
  height: "48px",
  borderColor: " #9AA6C3",
  borderRadius: "6px",
  width: "90%",
  color: "#0F2C6F",
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StaffOnboard = ({
  bmsRole,
  getStaffRole,
  accessList,
  getAccessList,
  defaultAccessRights,
  getdefaultAccessRights,
  addBmsEmpStatus,
  addBmsEmp,
  getAllLevels,
  getUnitsByLevelid,
  levelDatas,
  unitDatas,
  setBmsStaffReducernull,
  levelList,
  getStaffLevel,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [personName, setPersonName] = React.useState([]);
  const [supportingFiles, setSupportingFiles] = useState(null);
  const inputImg = useRef();
  const [personalDetails, setPersonalDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    bmsRole: "",
    accessStartDate: null,
    dateStr: "",
    officeLevel: "",
    officeUnit: "",
    mobile: "",
    office: "",
  });
  const [personalDetailsError, setPersonalDetailsError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    bmsRole: "",
    accessStartDate: "",
    officeLevel: "",
    officeUnit: "",
    mobile: "",
    office: "",
  });
  const [systemAccess, setSystemAccess] = useState(initialSystemAccess);
  const [accessRights, setAccessRights] = useState([]);
  const [submissionRights, setSubmissionRights] = useState([]);
  const [approvalRights, setApprovalRights] = useState([]);

  const [systemSelected, setSystemSelected] = useState([]);
  const [accessSelected, setAccessSelected] = useState([]);
  const [submissionSelected, setSubmissionSelected] = useState([]);
  const [approvalSelected, setApprovalSelected] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [toast, setToast] = useState({ message: "", status: false, color: "" });
  const [allRights, setAllRights] = useState(true);
  const [submissionCheck, setSubmissionCheck] = useState(false);
  const [approvalCheck, setApprovalCheck] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [tabDisabled, setTabDisabled] = useState({ tab2: true, tab3: true });
  const [rightsError, setRightsError] = useState("");
  const [levelError, setLevelError] = useState("");
  const [isToggle, setIsToggle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const [selectedArryFromLevel, setSelectedArrayFromLevel] = useState([]);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    getStaffRole();
    getAllLevels();
    getStaffLevel();
  }, []);

  const handleChange = (event) => {
    setSelectedArrayFromLevel([]);
    setPersonalDetails({
      ...personalDetails,
      officeLevel: "",
      officeUnit: "",
    });

    setLevelError("");
    const value = event.target.value;
    const allIds = levelList.map((item) => item.level_name);

    if (value.includes("All")) {
      if (personName.length === allIds.length) {
        setPersonName([]);
      } else {
        setPersonName(allIds);
      }
    } else {
      if (personName.length === allIds.length) {
        setPersonName([value[value.length - 1]]);
      } else {
        setPersonName(value);
      }
    }
  };

  // useEffect(()=>{
  //         let array =[] ;
  //         for(let i=0;i<accessList?.length;i++){
  //             let newObj = accessList[i];
  //             newObj['isSelected'] = false;
  //             newObj['isDisabled'] = false;
  //             array.push(newObj)
  //         }
  //         setAccessRights([...array])
  //         setSubmissionRights([...array])
  //         setApprovalRights([...array])

  // },[accessList])

  useEffect(() => {
    if (personalDetails.bmsRole.role_id) {
      getdefaultAccessRights({ role_id: personalDetails.bmsRole.role_id });
      // getdefaultAccessRights({role_id:15})
    }
  }, [personalDetails.bmsRole]);

  useEffect(() => {
    // const  access = (id,rights)=>{
    //     let arr = accessRights;
    //     for(let i=0;i<arr.length;i++){
    //         if(arr[i].access_rights_id==id){
    //             arr[i].isSelected = rights==1 ? true : rights==0 ? false : null
    //         }
    //     }
    //     setAccessRights([...arr])
    // }
    // const submission = (id,srights)=>{
    //     let arr = submissionRights
    //     for(let j=0;j<arr.length;j++){
    //         if(arr[j].access_rights_id==id){
    //             arr[j].isSelected = srights==1 ? true : srights==0 ? false : null
    //         }
    //     }
    //     setSubmissionRights(arr)
    // }

    // const approval = (id,rights) =>{
    //     let arr = approvalRights
    //     for(let k=0;k<arr.length;k++){
    //         if(arr[k].access_rights_id==id){
    //             arr[k].isSelected = rights==1 ? true : rights==0 ? false : null
    //         }
    //     }
    //     setApprovalRights(arr)
    // }

    // for(let i=0;i<defaultAccessRights.length;i++){
    //     access(defaultAccessRights[i].access_rights_id,defaultAccessRights[i].access_rights)
    //     submission(defaultAccessRights[i].access_rights_id,defaultAccessRights[i].submission_rights)
    //     approval(defaultAccessRights[i].access_rights_id,defaultAccessRights[i].approval_rights)
    // }

    let array = [];
    for (let j = 0; j < defaultAccessRights.length; j++) {
      let obj = defaultAccessRights[j];
      obj["isSelected"] =
        defaultAccessRights[j].access_rights == 1 ? true : false;
      obj["isDisabled"] = false;
      array.push(obj);
    }
    setAccessRights(array);
  }, [defaultAccessRights]);

  useEffect(() => {
    if (personName.length > 0) {
      const filteredData = levelDatas.filter((item) =>
        personName.includes(item.level_name)
      );
      setSelectedArrayFromLevel(filteredData);
    }
  }, [personName, levelDatas]);

  useEffect(() => {
    if (addBmsEmpStatus === "Employee Added Successfully") {
      setLoaderStatus(false);
      setToast({ status: true, message: addBmsEmpStatus, color: "green" });
      setTimeout(() => {
        setToast({});
        setBmsStaffReducernull();
        navigate("/manage_staff/1");
      }, 5000);
    } else if (addBmsEmpStatus != null) {
      setLoaderStatus(false);
      setToast({ status: true, message: addBmsEmpStatus, color: "red" });
      setTimeout(() => {
        setToast({});
        setBmsStaffReducernull();
      }, 5000);
    }
  }, [addBmsEmpStatus]);

  useEffect(() => {
    ApiService()
      .get(`${process.env.REACT_APP_API_URL}/getFRconfig`)
      .then((res) => setModalData(res.data?.response_json?.fr_contents))
      .catch((err) => console.log(err));
  }, []);

  const handlePersonalDetails = (e) => {
    const { name, value } = e.target;

    setPersonalDetails({
      ...personalDetails,
      [name]: value,
    });

    setPersonalDetailsError({
      ...personalDetailsError,
      [name]: "",
    });
  };
  const handleMobileInput = (data, country, event) => {
    const { name, value } = event.target;
    setPersonalDetails({
      ...personalDetails,
      [name]: value,
      [`${name}Code`]: `+${country.dialCode}`,
      [`${name}Number`]: data.slice(country.dialCode.length),
    });
    setPersonalDetailsError({
      ...personalDetailsError,
      [name]: "",
    });
  };

  const handleDateFormat = (value) => {
    let date = `${value.getFullYear()}-${
      value.getMonth() < 9 ? `0${value.getMonth() + 1}` : value.getMonth() + 1
    }-${value.getDate() < 10 ? `0${value.getDate()}` : value.getDate()}`;
    setPersonalDetails({
      ...personalDetails,
      accessStartDate: value,
      dateStr: date,
    });
    setPersonalDetailsError((prevValue) => ({
      ...prevValue,
      accessStartDate: "",
    }));
  };
  const validate = () => {
    let error = true;
    const firstnameRegex = /^[a-zA-Z_. ]{3,}([a-zA-Z]+ ?)$/;
    if (!personalDetails.firstName) {
      setPersonalDetailsError((prevValue) => ({
        ...prevValue,
        firstName: "Should not be empty",
      }));
      error = false;
    }

    if (
      personalDetails.firstName &&
      !personalDetails.firstName.match(firstnameRegex)
    ) {
      const isNumericIncluded = containsNumbers(personalDetails.firstName);
      const nameLength = personalDetails.firstName.length;
      if (nameLength < 2) {
        setPersonalDetailsError((prevValue) => ({
          ...prevValue,
          firstName: "First name must be 2 characters !",
        }));
        error = false;
      } else if (isNumericIncluded) {
        setPersonalDetailsError((prevValue) => ({
          ...prevValue,
          firstName: "First name should not contain numeric!",
        }));
        error = false;
      }
    }

    if (!personalDetails.lastName) {
      setPersonalDetailsError((prevValue) => ({
        ...prevValue,
        lastName: "Should not be empty",
      }));
      error = false;
    }
    if (!personalDetails.email) {
      setPersonalDetailsError((prevValue) => ({
        ...prevValue,
        email: "Should not be empty",
      }));
      error = false;
    }
    if (!personalDetails.jobTitle) {
      setPersonalDetailsError((prevValue) => ({
        ...prevValue,
        jobTitle: "Should not be empty",
      }));
      error = false;
    }
    if (!personalDetails.bmsRole) {
      setPersonalDetailsError((prevValue) => ({
        ...prevValue,
        bmsRole: "Should not be empty",
      }));
      error = false;
    }
    if (!personalDetails.mobile) {
      setPersonalDetailsError((prevValue) => ({
        ...prevValue,
        mobile: "Should not be empty",
      }));
      error = false;
    }
    // if(!personalDetails.office){
    //     setPersonalDetailsError((prevValue)=>({
    //         ...prevValue,
    //         office:'Should not be empty'
    //     }))
    //     error=false;
    // }
    if (!personalDetails.officeLevel) {
      setPersonalDetailsError((prevValue) => ({
        ...prevValue,
        officeLevel: "Should not be empty",
      }));
      error = false;
    }
    if (!personalDetails.officeUnit) {
      setPersonalDetailsError((prevValue) => ({
        ...prevValue,
        officeUnit: "Should not be empty",
      }));
      error = false;
    }
    if (personName?.length <= 0) {
      setLevelError("Should not be empty");
      error = false;
    }
    if (!personalDetails.accessStartDate) {
      setPersonalDetailsError((prevValue) => ({
        ...prevValue,
        accessStartDate: "Should not be empty",
      }));
      error = false;
    }
    if (isToggle) {
      if (!supportingFiles?.src) {
        setImageErr("Please upload the image");
        error = false;
      }
    }
    return error;
  };
  const isAllSelected = personName.length === levelList.length;
  const handleContinueButton = () => {
    let err = validate();
    if (tabValue == 0) {
      if (err) {
        // getAccessList()
        setTabValue(1);
        setTabDisabled({
          tab2: false,
          tab3: true,
        });
      }
    }

    if (tabValue == 1) {
      let errArray = [];
      for (let j = 0; j < accessRights.length; j++) {
        if (accessRights[j].isSelected) {
          errArray.push(true);
        }
      }
      if (errArray.length >= 1) {
        let arr = [];
        filterSelected();
        setTabValue(2);
        setTabDisabled({
          tab2: false,
          tab3: false,
        });
        for (let i = 0; i < accessRights.length; i++) {
          if (accessRights[i].isSelected) {
            arr.push(accessRights[i]);
          }
        }
      } else {
        setRightsError("Select atleast one access rights");
      }
    }

    if (tabValue == 2) {
      setModalOpen(true);
    }
  };

  const handleSystemCheckBox = (value, all) => {
    let arr = systemAccess;
    if (all) {
      for (let i = 0; i < arr.length; i++) {
        if (allRights) {
          arr[i].isSelected = false;
        } else {
          arr[i].isSelected = true;
        }
      }
      setSystemAccess([...arr]);
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === value) {
          arr[i].isSelected = !arr[i].isSelected;
        }
      }

      setSystemAccess([...arr]);
    }
  };

  const handleAccessCheckBox = (value, all) => {
    setRightsError("");
    let arr = accessRights;

    if (all) {
      for (let i = 0; i < arr.length; i++) {
        if (allRights) {
          arr[i].isSelected = false;
        } else {
          arr[i].isSelected = true;
        }
      }
      setAccessRights([...arr]);
    } else {
      let array = arr.map((item, index) => {
        if (value == index) {
          return {
            ...item,
            ["isSelected"]: !item.isSelected,
          };
        } else {
          return item;
        }
      });
      setAccessRights([...array]);
    }
  };

  const handleSubmissionCheckBox = (value, all) => {
    setSubmissionCheck(!submissionCheck);
    let array = submissionRights;
    let acc = accessRights;
    let app = approvalRights;
    if (all) {
      for (let i = 0; i < array.length; i++) {
        if (allRights) {
          array[i].isSelected = false;
        } else {
          array[i].isSelected = true;
        }
      }
      setSubmissionRights([...array]);
    } else {
      let arr = array.map((item, index) => {
        if (value == index) {
          return {
            ...item,
            ["isSelected"]: !item.isSelected,
          };
        } else {
          return item;
        }
      });
      let access = acc.map((item, index) => {
        if (index == value) {
          if (submissionCheck) {
            return {
              ...item,
              ["isSelected"]: !item.isSelected,
              ["isDisabled"]: !item.isDisabled,
            };
          } else {
            return {
              ...item,
              ["isSelected"]: true,
              ["isDisabled"]: true,
            };
          }
        } else {
          return item;
        }
      });
      let approval = app.map((item, index) => {
        if (index == value) {
          if (submissionCheck) {
            return {
              ...item,
              ["isSelected"]: !item.isSelected,
              ["isDisabled"]: !item.isDisabled,
            };
          } else {
            return {
              ...item,
              ["isSelected"]: true,
              ["isDisabled"]: true,
            };
          }
        } else {
          return item;
        }
      });

      setSubmissionRights([...arr]);
      setAccessRights([...access]);
      setApprovalRights([...approval]);
    }
  };

  const handleApprovalCheckBox = (value, all) => {
    setApprovalCheck(!approvalCheck);
    let arr = approvalRights;
    let acc = accessRights;
    if (all) {
      for (let i = 0; i < arr.length; i++) {
        if (allRights) {
          arr[i].isSelected = false;
        } else {
          arr[i].isSelected = true;
        }
      }
      setApprovalRights([...arr]);
    } else {
      let array = arr.map((item, index) => {
        if (value == index) {
          return {
            ...item,
            ["isSelected"]: !item.isSelected,
          };
        } else {
          return item;
        }
      });

      let access = acc.map((item, index) => {
        if (index == value) {
          if (approvalCheck) {
            return {
              ...item,
              ["isSelected"]: !item.isSelected,
              ["isDisabled"]: !item.isDisabled,
            };
          } else {
            return {
              ...item,
              ["isSelected"]: true,
              ["isDisabled"]: true,
            };
          }
        } else {
          return item;
        }
      });
      setAccessRights([...access]);
      setApprovalRights([...array]);
    }
  };

  const selectAllCheckBox = () => {
    setAllRights(!allRights);
    handleAccessCheckBox("", "all");
    handleSubmissionCheckBox("", "all");
    handleSystemCheckBox("", "all");
    handleApprovalCheckBox("", "all");
  };

  const filterSelected = () => {
    let system = [];
    for (let i = 0; i < systemAccess.length; i++) {
      if (systemAccess[i].isSelected === true) {
        system.push(systemAccess[i]);
      }
    }
    setSystemSelected([...system]);

    let access = [];
    for (let j = 0; j < accessRights.length; j++) {
      if (accessRights[j].isSelected === true) {
        access.push(accessRights[j]);
      }
    }
    setAccessSelected([...access]);

    let submission = [];
    for (let k = 0; k < submissionRights.length; k++) {
      if (submissionRights[k].isSelected === true) {
        submission.push(submissionRights[k]);
      }
    }
    setSubmissionSelected([...submission]);

    let approval = [];
    for (let l = 0; l < approvalRights.length; l++) {
      if (approvalRights[l].isSelected === true) {
        approval.push(approvalRights[l]);
      }
    }
    setApprovalSelected([...approval]);
  };

  const modalClose = () => {
    setModalOpen(false);
  };

  const modalConfirm = () => {
    const accessData = accessRights.map((value) => {
      return {
        access_rights_id: value.access_rights_id,
        access_rights: value.isSelected ? 1 : 0,
        submission_rights: 0,
        approval_rights: 0,
      };
    });

    const levels = [...levelList];
    let level_array = [];
    personName?.forEach((item, index) => {
      levels?.forEach((item2, index2) => {
        if (item2.level_name === item) {
          level_array.push(parseInt(item2.assigned_units_id));
        }
      });
    });

    let data = {
      first_name: personalDetails.firstName,
      last_name: personalDetails.lastName,
      mobile_country_code: personalDetails.mobileCode,
      mobile_no: personalDetails.mobile?.slice(
        personalDetails.mobileCode.length + 1
      ),
      office_country_code: personalDetails?.office
        ? personalDetails.officeCode
        : "",
      office_phone_no: personalDetails?.office?.slice(
        personalDetails?.officeCode?.length + 1
      ),
      access_start_date: personalDetails.dateStr,
      level_no: personalDetails.officeLevel.level_id,
      unit_no: personalDetails.officeUnit.unit_id,
      role: personalDetails.bmsRole.role,
      job_title: personalDetails.jobTitle,
      email: personalDetails.email,
      assigned_unit_id_str: JSON.stringify(level_array),
      app_accesss: 1,
      dashboard_accesss: 1,
      accessRightsDataArrayList: JSON.stringify(accessData),
      // enable_fr: Number(0),
    };
    if (isToggle && supportingFiles) {
      delete data?.enable_fr;
      const formData = new FormData();
      // formData.append("face_photo", supportingFiles?.file);
      // formData.append("enable_fr", Number(1));
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      addBmsEmp(formData);
    } else {
      addBmsEmp(data);
    }

    setModalOpen(false);
    setLoaderStatus(true);
    setTimeout(() => {
      setLoaderStatus(false);
    }, 20000);
  };

  const clearData = () => {
    if (tabValue == 0) {
      setPersonalDetails({
        firstName: "",
        lastName: "",
        email: "",
        jobTitle: "",
        bmsRole: "",
        accessStartDate: null,
        dateStr: "",
        officeLevel: "",
        officeUnit: "",
        mobile: "+65",
        office: "+65",
      });

      setSupportingFiles(null);
      setIsToggle(false);
      setImageErr("");
      setPersonName([]);
    } else if (tabValue == 1 && allRights) {
      setAllRights(false);
      handleAccessCheckBox("", "all");
      handleSubmissionCheckBox("", "all");
      handleSystemCheckBox("", "all");
      handleApprovalCheckBox("", "all");
    }
  };

  const handleToggleChange = () => {
    if (!isToggle) {
      setOpenModal(true);
    } else {
      setImageErr(false);
      setSupportingFiles(null);
      setIsToggle((prev) => !prev);
    }
  };

  const handleConfrim = () => {
    setImageErr(false);
    setSupportingFiles(null);
    setIsToggle((prev) => !prev);
    setOpenModal(false);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const ModalAlert = () => {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        sx={{ "& .MuiDialog-paper": { width: "60%" } }} // Fixed width for the dialog
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "25px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottom: "1px solid #e0e0e0", // Bottom border
            gap: "10px", // Gap between content
          }}
        >
          <Typography
            sx={{ fontWeight: "500", fontSize: "18px", color: "#0F2C6F" }}
          >
            {modalData?.fr_title ||
              "Do you want to turn on the facial recognition?"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              color: "#0F2C6F",
              marginTop: "15px",
            }}
            gutterBottom
          >
            {modalData?.fr_desc ||
              "  An update is available with new features, a faster experiences,fixes and more. Please update to continue using it."}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              borderRadius: "6px",
              p: "8px 10px",
              color: "#0F2C6F",
              borderColor: "#AAC3FF",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Inter",
              }}
            >
              {modalData?.fr_cancel_btn || "Cancel"}
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={handleConfrim}
            sx={{
              borderRadius: "6px",
              p: "8px 10px",
              textTransform: "capitalize",
              backgroundColor: "#123FA9",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Inter",
              }}
            >
              {modalData?.fr_confirm_btn || "Confirm and Turn On"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const IOSSwitch = styled((SwitchProps) => (
    <Switch
      checked={isToggle}
      onChange={handleToggleChange}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...SwitchProps}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#123FA9",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleDropImage = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setSupportingFiles({
        file: file,
        fileName: file.name,
        size: file.size,
        src: URL.createObjectURL(file), // Create a blob URL for image preview
      });
      setImageErr(false);
      e.target.value = null;
    }
  };
  const handleDragImage = (e) => {
    e.preventDefault();
  };

  const handleImage = () => {
    inputImg.current.click();
  };

  const handleInputImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSupportingFiles({
        file: file,
        fileName: file.name,
        size: file.size,
        src: URL.createObjectURL(file), // Create a blob URL for image preview
      });
      e.target.value = null;
      setImageErr(false);
    }
  };

  const removeSupportImage = () => {
    setSupportingFiles(null);
  };

  return (
    <>
      {modalOpen && (
        <Modal
          alertData={alertData}
          closeFunc={modalClose}
          confirmFunc={modalConfirm}
        />
      )}
      {openModal && ModalAlert()}
      {toast.status && (
        <ToastAlert
          status={toast.status}
          message={toast.message}
          color={toast.color}
        />
      )}
      {loaderStatus && <Loader status={loaderStatus} />}
      <Grid container sx={{ backgroundColor: "rgba(143, 146, 161,0.05)" }}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Box sx={{ mb: 5, mt: 7 }}>
            <BackIcon
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            />
            <Typography
              sx={{
                fontSize: "26px",
                fontWeight: 500,
                color: "#0F2C6F",
                my: 1,
              }}
            >
              Onboard a New Staff
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#6F80A9" }}
            >
              You may onboard a new staff here.
            </Typography>
          </Box>
          <Box sx={{ backgroundColor: "#FFFFFF" }}>
            <Box sx={{ borderBottom: "1px solid #DBDFE9" }}>
              <Tabs
                value={tabValue}
                onChange={(e, value) => setTabValue(value)}
              >
                <StyledTab
                  label={
                    <Box>
                      <span style={spanStyle}>1</span>
                      <span>Details</span>
                    </Box>
                  }
                ></StyledTab>
                <StyledTab
                  disabled={tabDisabled.tab2}
                  label={
                    <Box>
                      <span style={spanStyle}>2</span>
                      <span>Access & Rights</span>
                    </Box>
                  }
                ></StyledTab>
                <StyledTab
                  disabled={tabDisabled.tab3}
                  label={
                    <Box>
                      <span style={spanStyle}>3</span>
                      <span>Review</span>
                    </Box>
                  }
                ></StyledTab>
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <Box sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    my: 2,
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#0F2C6F",
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Personal Details
                    </Typography>
                    <Typography
                      sx={{
                        color: "#6F80A9",
                        fontSize: "14px",
                        fontWeight: 500,
                        mt: 1,
                        mb: 3,
                      }}
                    >
                      Please fill up the details of your employee below.
                    </Typography>
                  </Box>

                  {/* <img
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "231px",
                      border: "1px solid #DBDFE9",
                    }}
                    src={
                      supportingFiles?.src ? supportingFiles?.src : EmptyAvatar
                    }
                  /> */}
                </Box>

                <Grid container rowSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <StyledTypography>First Name *</StyledTypography>
                    <StyledOutlinedInput
                      onChange={(e) => handlePersonalDetails(e)}
                      name="firstName"
                      value={personalDetails.firstName}
                    />
                    {personalDetailsError.firstName && (
                      <Typography sx={{ color: "#f44336" }}>
                        {personalDetailsError.firstName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTypography>Last Name *</StyledTypography>
                    <StyledOutlinedInput
                      onChange={(e) => handlePersonalDetails(e)}
                      name="lastName"
                      value={personalDetails.lastName}
                    />
                    {personalDetailsError.lastName && (
                      <Typography sx={{ color: "#f44336" }}>
                        {personalDetailsError.lastName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTypography>Work Email Address*</StyledTypography>
                    <StyledOutlinedInput
                      onChange={(e) => handlePersonalDetails(e)}
                      name="email"
                      value={personalDetails.email}
                    />
                    {personalDetailsError.email && (
                      <Typography sx={{ color: "#f44336" }}>
                        {personalDetailsError.email}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTypography>Job Title *</StyledTypography>
                    <StyledOutlinedInput
                      onChange={(e) => handlePersonalDetails(e)}
                      name="jobTitle"
                      value={personalDetails.jobTitle}
                    />
                    {personalDetailsError.jobTitle && (
                      <Typography sx={{ color: "#f44336" }}>
                        {personalDetailsError.jobTitle}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTypography>Mobile Number*</StyledTypography>
                    <PhoneInput
                      inputStyle={{
                        width: "90%",
                        height: "48px",
                        borderRadius: "6px",
                        color: "#0F2C6F",
                      }}
                      onChange={(data, country, event) =>
                        handleMobileInput(data, country, event)
                      }
                      value={personalDetails.mobile}
                      country="sg"
                      inputProps={{ name: "mobile" }}
                    />
                    {personalDetailsError.mobile && (
                      <Typography sx={{ color: "#f44336" }}>
                        {personalDetailsError.mobile}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTypography>Office Number</StyledTypography>
                    <PhoneInput
                      inputStyle={{
                        width: "90%",
                        height: "48px",
                        borderRadius: "6px",
                        color: "#0F2C6F",
                      }}
                      onChange={(data, country, event) =>
                        handleMobileInput(data, country, event)
                      }
                      value={personalDetails.office}
                      country="sg"
                      inputProps={{ name: "office" }}
                    />
                    {personalDetailsError.office && (
                      <Typography sx={{ color: "#f44336" }}>
                        {personalDetailsError.office}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTypography>BMS Role*</StyledTypography>
                    <Select
                      sx={{ width: "90%", color: "#0F2C6F" }}
                      value={personalDetails.bmsRole}
                      onChange={(e) => handlePersonalDetails(e)}
                      name="bmsRole"
                    >
                      {bmsRole.length > 0 &&
                        bmsRole.map((value) => {
                          return (
                            <MenuItem value={value}>{value.role}</MenuItem>
                          );
                        })}
                    </Select>
                    {personalDetailsError.bmsRole && (
                      <Typography sx={{ color: "#f44336" }}>
                        {personalDetailsError.bmsRole}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTypography>Level</StyledTypography>
                    <FormControl style={{ width: "90%" }}>
                      <Select
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="All">
                          <Checkbox
                            checked={isAllSelected}
                            // indeterminate={
                            //   personName.length > 0 && !isAllSelected
                            // }
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                        {levelList.map((item, index) => (
                          <MenuItem key={index} value={item.level_name}>
                            <Checkbox
                              checked={personName.indexOf(item.level_name) > -1}
                            />
                            <ListItemText primary={item.level_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {levelError != "" && (
                      <Typography sx={{ color: "#f44336" }}>
                        {levelError}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} />
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        color: "#0F2C6F",
                        fontSize: "18px",
                        fontWeight: 500,
                        mt: 3,
                      }}
                    >
                      Access Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ position: "relative", width: "40%" }}>
                      <StyledTypography>Access Start date*</StyledTypography>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        disablePast
                        value={personalDetails.accessStartDate}
                        onChange={(value) => handleDateFormat(value)}
                        components={{
                          OpenPickerIcon: DatePickerIcon,
                        }}
                        renderInput={(params) => (
                          <TextField
                            onKeyDown={(e) => preventDatePickerKeyboard(e)}
                            {...params}
                          />
                        )}
                      />
                    </Box>
                    {personalDetailsError.accessStartDate && (
                      <Typography sx={{ color: "#f44336" }}>
                        {personalDetailsError.accessStartDate}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTypography>Office Unit Number*</StyledTypography>
                    <FormControl>
                      <Select
                        MenuProps={MenuProps}
                        onChange={(e) => handlePersonalDetails(e)}
                        name="officeLevel"
                        sx={{
                          height: "40px",
                          width: "70px",
                          mr: 3,
                          color: "#0F2C6F",
                        }}
                        value={personalDetails.officeLevel}
                      >
                        {selectedArryFromLevel?.length > 0 &&
                          selectedArryFromLevel.map((value, index) => {
                            return (
                              <MenuItem
                                sx={{ color: "#0F2C6F" }}
                                key={index}
                                value={value}
                                onClick={() => {
                                  getUnitsByLevelid({
                                    level_id: value.level_id,
                                  });
                                  //setSpecifyUnits({...specifyUnits,unit:unitDatas})
                                }}
                              >
                                {value.level_name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    <Select
                      onChange={(e) => handlePersonalDetails(e)}
                      name="officeUnit"
                      sx={{ height: "40px", width: "120px", color: "#0F2C6F" }}
                      value={personalDetails.officeUnit}
                    >
                      {unitDatas.length > 0 &&
                        unitDatas.map((value) => {
                          return (
                            <MenuItem value={value}>{value.unit_name}</MenuItem>
                          );
                        })}
                    </Select>
                    {personalDetailsError.officeUnit && (
                      <Typography sx={{ color: "#f44336" }}>
                        {personalDetailsError.officeUnit}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} />
                  {/* <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "fit-content",
                          borderRadius: "6px",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-0.25px",
                          fontFamily: "inter",
                          color: "#0F2C6F",
                          padding: "0 8px",
                          backgroundColor: "#CFDDFE",
                        }}
                      >
                        NEW
                      </Typography>
                      <Box sx={{ display: "flex", gap: "20px" }}>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            lineHeight: "24px",
                            letterSpacing: "-0.25px",
                            fontFamily: "inter",
                            color: "#0F2C6F",
                          }}
                        >
                          Facial Recognition
                        </Typography>
                        <IOSSwitch defaultChecked={false} />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            lineHeight: "24px",
                            letterSpacing: "-0.25px",
                            fontFamily: "inter",
                            color: "#6F80A9",
                          }}
                        >
                          Automatically activate the facial recognition feature
                          for this tenant upon entering the building.
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            lineHeight: "24px",
                            letterSpacing: "-0.25px",
                            fontFamily: "inter",
                            color: "#6F80A9",
                          }}
                        >
                          You can skip this step and let the tenant upload it by
                          themselves.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid> */}

                  {isToggle && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                          mt: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "-0.25px",
                            fontFamily: "inter",
                            color: "#0F2C6F",
                          }}
                        >
                          Photo Requirements:
                        </Typography>
                        <ol style={{ color: "#0F2C6F" }}>
                          <li style={{ color: "inherit" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "-0.25px",
                                fontFamily: "inter",
                                color: "#0F2C6F",
                              }}
                            >
                              Please submit a single color photograph.
                            </Typography>
                          </li>
                          <li style={{ color: "inherit" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "-0.25px",
                                fontFamily: "inter",
                                color: "#0F2C6F",
                              }}
                            >
                              Ensure the photo was taken within the last six
                              months.
                            </Typography>
                          </li>
                          <li style={{ color: "inherit" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "-0.25px",
                                fontFamily: "inter",
                                color: "#0F2C6F",
                              }}
                            >
                              Use a clear image showing the entire face; avoid
                              selfies.
                            </Typography>
                          </li>
                          <li style={{ color: "inherit" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "-0.25px",
                                fontFamily: "inter",
                                color: "#0F2C6F",
                              }}
                            >
                              Do not alter the photo using computer software,
                              phone apps, or filters.
                            </Typography>
                          </li>
                          <li style={{ color: "inherit" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "-0.25px",
                                fontFamily: "inter",
                                color: "#0F2C6F",
                              }}
                            >
                              The background should be white or off-white, free
                              from shadows, texture, or lines.
                            </Typography>
                          </li>
                        </ol>
                        <Box sx={{ padding: "16px 24px" }}>
                          <Typography
                            sx={{ color: "#0F2C6F", fontSize: "16px", mb: 2 }}
                          >
                            Supporting Materials(optional)
                          </Typography>
                          {supportingFiles?.size ? (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                border: "2px solid #80808021",
                                mb: 0.5,
                                borderRadius: "5px",
                              }}
                            >
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                  p: 0,
                                }}
                              >
                                <ListItem
                                  sx={{ pl: 0 }}
                                  secondaryAction={
                                    <IconButton edge="end" aria-label="delete">
                                      <Box
                                        component="img"
                                        src={AvatarRemove}
                                        onClick={removeSupportImage}
                                      />
                                    </IconButton>
                                  }
                                >
                                  <ListItemText
                                    primary={
                                      <Box
                                        sx={{
                                          ml: 1,
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box
                                          component="img"
                                          src={EmptyJpg}
                                          sx={{
                                            width: "40px",
                                            height: "48px",
                                          }}
                                        />
                                        <Typography variant="p" sx={{ m: 2 }}>
                                          {supportingFiles.fileName}
                                          <span
                                            style={{
                                              color: "gray",
                                              fontSize: "15px",
                                            }}
                                          >{`     (${Math.floor(
                                            supportingFiles?.size / 1024
                                          )} of ${Math.floor(
                                            supportingFiles?.size / 1024
                                          )}KB)`}</span>
                                        </Typography>
                                      </Box>
                                    }
                                    secondary={""}
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                          ) : (
                            <>
                              <Grid
                                container
                                sx={{
                                  backgroundColor: imageErr
                                    ? "#FEE4E2"
                                    : "#EAF0FF",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    minHeight: 250,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    onDragOver={handleDragImage}
                                    onDrop={handleDropImage}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        color: " #0F2C6F",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Drag and drop files here
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#0F2C6F",
                                        fontWeight: 400,
                                      }}
                                    >
                                      Files Supported: svg, .png, .jpg (Max 25
                                      MB)
                                    </Typography>
                                    <Button
                                      variant="outlined"
                                      sx={{
                                        backgroundColor: "white",
                                        color: "#0F2C6F",
                                        fontSize: "14px",
                                        mt: 2,
                                        border: "1px solid #AAC3FF",
                                        borderRadius: " 6px",
                                        "&:hover": { backgroundColor: "white" },
                                      }}
                                      onClick={handleImage}
                                    >
                                      Choose file
                                      <input
                                        type="file"
                                        accept=".jpg,.jpeg,.png,.svg,.gif"
                                        ref={inputImg}
                                        onChange={handleInputImage}
                                        hidden
                                      />
                                    </Button>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Typography sx={{ color: "#f44336", mt: 2 }}>
                                {imageErr}
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Box sx={{ p: 3 }}>
                <Typography sx={{ fontSize: "18px", color: "#0F2C6F" }}>
                  Access & Rights
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", color: "#6F80A9", mb: 2, mt: 1 }}
                >
                  Finance Manager will be the person who is authorised to make
                  payments on behalf of the company.{" "}
                </Typography>

                <Checkbox
                  checked={allRights}
                  icon={<CheckboxIcon checked={allRights} />}
                  onClick={() => selectAllCheckBox()}
                />
                <Typography
                  sx={{
                    display: "inline",
                    color: "#0F2C6F ",
                    fontSize: "12px",
                  }}
                >
                  Select all rights and access
                </Typography>
                <Grid container sx={{ mt: 2 }}>
                  {/* <Grid item xs={12} sm={3}>
                        <Typography sx={{color:'#0F2C6F',fontSize:'12px',fontWeight:500}}>System Access</Typography>
                        <Box sx={{display:'flex',flexDirection:'column'}}>
                        {
                            systemAccess.map((value,index)=>(
                                <Box key={index}>
                                    <Checkbox icon={<CheckboxIcon />}  checked={value.isSelected} onClick={()=>handleSystemCheckBox(value.id)}/>
                                    <Typography sx={{display:'inline',fontSize:'12px',color:'#0F2C6F',fontWeight:400}}>{value.name}</Typography>
                                </Box>
                            ))
                        }
                        </Box>
                        
                    </Grid> */}
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        color: "#0F2C6F",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      Access Rights
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      {accessRights.map((value, index) => (
                        <Box key={index}>
                          <Checkbox
                            disabled={value.isDisabled}
                            icon={<CheckboxIcon />}
                            checked={value.isSelected}
                            onClick={() => handleAccessCheckBox(index)}
                          />
                          <Typography
                            sx={{
                              display: "inline",
                              fontSize: "12px",
                              color: "#0F2C6F",
                              fontWeight: 400,
                            }}
                          >
                            {value.access_rights_name}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Typography sx={{ color: "red", fontSize: "14px" }}>
                      {rightsError}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={12} sm={3}>
                        <Typography sx={{color:'#0F2C6F',fontSize:'12px',fontWeight:500}}>Submission Rights</Typography>
                        <Box>
                            {
                                submissionRights.map((value,index)=>{
                                     return <Box key={index}>
                                        <Checkbox disabled={value.isDisabled} icon={<CheckboxIcon />}  checked={value.isSelected} onClick={()=>handleSubmissionCheckBox(index)} />
                                    <Typography sx={{display:'inline',fontSize:'12px',color:'#0F2C6F',fontWeight:400}}>{value.access_rights_name}</Typography>
                                    </Box>
                                    
                                })
                            }
                        </Box>
                    </Grid> */}
                  {/* <Grid item xs={12} sm={3}>
                        <Typography sx={{color:'#0F2C6F',fontSize:'12px',fontWeight:500}}>Approval Rights</Typography>
                        <Box>
                            {
                                approvalRights.map((value,index)=>{
                                 
                                        return <Box key={index}>
                                        <Checkbox disabled={value.isDisabled} icon={<CheckboxIcon />}  checked={value.isSelected} onClick={()=>handleApprovalCheckBox(index)} />
                                    <Typography sx={{display:'inline',fontSize:'12px',color:'#0F2C6F',fontWeight:400}}>{value.access_rights_name}</Typography>
                                    </Box>
                                    
                                    
                                })
                            }
                        </Box>
                    </Grid> */}
                </Grid>
              </Box>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <Box sx={{ p: 3 }}>
                <Typography sx={{ color: "#6F80A9", fontSize: "14px" }}>
                  Please ensure that all data provided are accurate to prevent
                  any delays in processing. You will be notified within 1-3
                  working days once checks has been processed. Once employee is
                  onboarded, they will receive an email in their work email
                  provided to download and install the Singland App for access.
                </Typography>
                <Box
                  sx={{
                    border: "1px solid #DBDFE9",
                    borderRadius: "6px",
                    p: 3,
                    my: 3,
                  }}
                >
                  <Grid container>
                    {supportingFiles?.src && (
                      <Grid item xs={12}>
                        <img
                          style={{
                            width: "120px",
                            height: "120px",
                            borderRadius: "231px",
                            border: "1px solid #DBDFE9",
                          }}
                          src={
                            supportingFiles?.src
                              ? supportingFiles?.src
                              : EmptyAvatar
                          }
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: "#0F2C6F",
                          fontSize: "14px",
                          mb: 3,
                          mt: 2,
                          ml: 1,
                        }}
                      >
                        {personalDetails.firstName} {personalDetails.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ color: "#0F2C6F", fontSize: "12px" }}>
                        {personalDetails.jobTitle}
                      </Typography>
                      <Typography sx={{ color: "#0F2C6F", fontSize: "12px" }}>
                        BMS:{personalDetails?.bmsRole?.role}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ color: "#0F2C6F", fontSize: "12px" }}>
                        Office:{personalDetails.office}
                      </Typography>
                      <Typography sx={{ color: "#0F2C6F", fontSize: "12px" }}>
                        Mobile:{personalDetails.mobile}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        sx={{
                          color: "#0F2C6F",
                          fontSize: "12px",
                          fontWeight: 550,
                        }}
                      >
                        Access Start Date
                      </Typography>
                      <Typography sx={{ color: "#0F2C6F", fontSize: "12px" }}>
                        {moment(personalDetails.dateStr).format("DD MMM YYYY")}
                      </Typography>
                      <Typography sx={{ color: "#0F2C6F", fontSize: "12px" }}>
                        Office Unit : #{personalDetails.officeLevel.level_name}-
                        {personalDetails.officeUnit.unit_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container>
                  {/* <Grid item xs={12} sm={3}>
                        <Typography sx={{color:'#0F2C6F',fontSize:'12px',fontWeight:500}}>System Access</Typography>
                        {systemSelected?.map((value,index)=>
                            <Box key={index}>
                                <Checkbox checked={value.isSelected} disabled /><Typography sx={{display:'inline',fontSize:'12px',color:'#0F2C6F',fontWeight:400}}>{value.name}</Typography>
                            </Box>
                        )}
                    </Grid> */}
                  <Grid item xs={12} sm={3}>
                    <Typography
                      sx={{
                        color: "#0F2C6F",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      Access Rights
                    </Typography>
                    {accessSelected?.map((value, index) => (
                      <Box key={index}>
                        <Checkbox checked={value.isSelected} disabled />
                        <Typography
                          sx={{
                            display: "inline",
                            fontSize: "12px",
                            color: "#0F2C6F",
                            fontWeight: 400,
                          }}
                        >
                          {value.access_rights_name}
                        </Typography>
                      </Box>
                    ))}
                  </Grid>
                  {/* <Grid item xs={12} sm={3}>
                        <Typography sx={{color:'#0F2C6F',fontSize:'12px',fontWeight:500}}>Submission Rights</Typography>
                        {submissionSelected?.map((value,index)=>
                            <Box key={index}>
                                <Checkbox checked={value.isSelected} disabled /><Typography sx={{display:'inline',fontSize:'12px',color:'#0F2C6F',fontWeight:400}}>{value.access_rights_name}</Typography>
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography sx={{color:'#0F2C6F',fontSize:'12px',fontWeight:500}}>Approval Rights</Typography>
                        {approvalSelected?.map((value,index)=>
                            <Box key={index}>
                                <Checkbox checked={value.isSelected} disabled /><Typography sx={{display:'inline',fontSize:'12px',color:'#0F2C6F',fontWeight:400}}>{value.access_rights_name}</Typography>
                            </Box>
                        )}
                    </Grid> */}
                </Grid>
              </Box>
            </TabPanel>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", py: 5 }}>
            <Box>
              {tabValue !== 2 && (
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "capitalize",
                    color: "#0F2C6F",
                    borderRadius: "8px",
                    height: "48px",
                    mr: 2,
                  }}
                  onClick={clearData}
                >
                  Clear
                </Button>
              )}

              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  height: "48px",
                  backgroundColor: "#123FA9",
                  "&:hover": { backgroundColor: "#123FA9" },
                }}
                onClick={() => handleContinueButton()}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={1} />
      </Grid>
    </>
  );
};

const TabPanel = (props) => {
  const { children, value, index } = props;
  return <>{value === index && <>{children}</>}</>;
};

const mapStateToProps = (state) => {
  return {
    bmsRole: state.BMSStaffReducer.rolesList,
    levelList: state.BMSStaffReducer.levelList,
    accessList: state.BMSStaffReducer.accessList,
    defaultAccessRights: state.BMSStaffReducer.defaultAccessRights,
    addBmsEmpStatus: state.BMSStaffReducer.addBmsEmpStatus,
    levelDatas: state.CommonReducer.levelDatas,
    unitDatas: state.CommonReducer.unitDatas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaffRole: () => dispatch(BmsStaffAction.getStaffRole()),
    getStaffLevel: () => dispatch(BmsStaffAction.getStaffLevel()),
    getAccessList: () => dispatch(BmsStaffAction.getAccessList()),
    getdefaultAccessRights: (data) =>
      dispatch(BmsStaffAction.getdefaultAccessRights(data)),
    addBmsEmp: (data) => dispatch(BmsStaffAction.addBmsEmp(data)),
    getAllLevels: () => dispatch(commonAction.getAllLevels()),
    getUnitsByLevelid: (data) => dispatch(commonAction.getUnitsByLevelid(data)),
    setBmsStaffReducernull: () =>
      dispatch(BmsStaffAction.setBmsStaffReducernull()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffOnboard);
