import axios from "axios";
import { createAction } from "redux-actions";
import ApiService from "../../../AxiosConfig/ApiService";
export const updateState = createAction("UPDATE_STATE");
export const sessionExpired = createAction("SESSION_EXPIRED");
export const setStatusNull=()=>{
  return (dispatch) => {
    dispatch(updateState());
  }
}
export const getEmpListInitiate = createAction("GET_EMPLIST_INITIATE");
export const getEmpListSuccess = createAction("GET_EMPLIST_SUCCESS");
export const getEmpListFailure = createAction("GET_EMPLIST_FAILURE");

export const getEmpList = (data, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(getEmpListInitiate());

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/getAllEmployeesByCompanyId`,
        data,
        config
      )
      .then((response) => {
        if(response.data?.response_json){
          dispatch(getEmpListSuccess(response?.data));
        }else{
          dispatch(getEmpListFailure());
         
        }
        
      })
      .catch((err) => {
        if(err?.response?.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
          dispatch(getEmpListFailure());
        }
      });
  };
};

export const createEmpInitiate = createAction("CREATE_EMP_INITIATE");
export const createEmpSuccess = createAction("CREATE_EMP_SUCCESS");
export const createEmpFailure = createAction("CREATE_EMP_FAILURE");

export const manageEmployee = (data, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",

    },
  };
  return (dispatch) => {
    dispatch(createEmpInitiate());

    axios
      .post(`${process.env.REACT_APP_API_URL}/addEmployee`, data, config)
      .then((response) => {
     
        if (response.data.response_status === "success") {
          dispatch(
            // createEmpSuccess(response.data?.success_message);
            createEmpSuccess(response.data)
          );
        } else {
          dispatch(createEmpFailure(response.data));
        }
      })
     
      .catch((err) => {
        if(err.response.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
          dispatch(createEmpFailure());
        }
      });
  };
};

export const getCompanyListInitiate = createAction("GET_COMPANYLIST_INITIATE");
export const getCompanyListSuccess = createAction("GET_COMPANYLIST_SUCCESS");
export const getCompanyListFailure = createAction("GET_COMPANYLIST_FAILURE");

export const getCompanyList = (data,token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept:'application/json'

    },
  };


  // let datas ={
  // limit:data.limit,
  // offset:data.offset,
  // approved_status:data.approvedStatus
  // } 
 

  return (dispatch) => {
    dispatch(getCompanyListInitiate());

    axios
      .post(`${process.env.REACT_APP_API_URL}/getAllCompanies`,data, config)
      .then((response) => {
        if(response.data.response_json){
          dispatch(getCompanyListSuccess(response.data));
        }else{
          let data={
            response_json:[],
            total_company_count:0
          }
          dispatch(getCompanyListSuccess(data));
        }
       
      })
      .catch((err) => {
        if(err.response.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
          dispatch(getCompanyListFailure(err.response));
        }
      });
  };
};

export const createTenantInitiate = createAction("CREATE_TENANT_INITIATE");
export const createTenantSuccess = createAction("CREATE_TENANT_SUCCESS");
export const createTenantFailure = createAction("CREATE_TENANT_FAILURE");

export const manageTenant = (data, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(createTenantInitiate());
    axios
      .post(`${process.env.REACT_APP_API_URL}/addCompany`, data, config)
      .then((response) => {
      
        if (
          response.data.response_json?.success_message ===
          "Company Added Successfully"
        ) {
          dispatch(
            createTenantSuccess(response.data.response_json?.success_message)
          );
        } else {
          dispatch(
            createTenantFailure(response.data.error_json?.error_message)
          );
        }
      })
     
      .catch((err) => {

        if(err.response.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
          dispatch(createTenantFailure(err.response.data.message));
        }
      });
  };
};

export const updateTenantInitiate = createAction("UPDATE_TENANT_INITIATE");
export const updateTenantSuccess = createAction("UPDATE_TENANT_SUCCESS");
export const updateTenantFailure = createAction("UPDATE_TENANT_FAILURE");

export const updateTenant = (data, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(updateTenantInitiate());
    axios
      .post(`${process.env.REACT_APP_API_URL}/updateCompany`, data, config)
      .then((response) => {
      
        if (
          response.data.response_status === 1        
        ) {
          dispatch(
            updateTenantSuccess(response.data)
          );
        } else {
          dispatch(
            updateTenantFailure(response.data)
          );
        }
      })
     
      .catch((err) => {

        if(err.response.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
          dispatch(updateTenantFailure(err.response.data.message));
        }
      });
  };
};


export const manageEmpStatusInitiate = createAction("MANAGE_EMP_INITIATE");
export const manageEmpStatusSuccess = createAction("MANAGE_EMP_SUCCESS");
export const manageEmpStatusFailure = createAction("MANAGE_EMP_FAILURE");

export const manageEMPStatus = (data, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(manageEmpStatusInitiate());
    axios
      .post(`${process.env.REACT_APP_API_URL}/updateEmployeeApprovedStatus`, data, config)
      .then((response) => {
        if (
          response.data.response_json?.success_message ===
          "Company Added Successfully"
        ) {
          dispatch(
            manageEmpStatusSuccess(response.data.response_json?.success_message)
          );
        } else {
          dispatch(
            manageEmpStatusFailure(response.data.error_json?.error_message)
          );
        }
      })
      
      .catch((err) => {
        if(err.response.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
          dispatch(manageEmpStatusFailure());
        }
      });
  };
};

export const manageTenantStatusInitiate = createAction("MANAGE_TENANT_INITIATE");
export const manageTenantStatusSuccess = createAction("MANAGE_TENANT_SUCCESS")
export const manageTenantStatusFailure = createAction("MANAGE_TENANT_FAILURE")

export const manageTenantStatus = (data, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(manageTenantStatusInitiate());
    axios
      .post(`${process.env.REACT_APP_API_URL}/updateCompanyApprovedStatus`, data, config)
      .then((response) => {
       
        if (response.data.reponse_json.response_status=="success"){
          dispatch(manageTenantStatusSuccess(response.data.reponse_json.message));
        } else {
          dispatch(manageTenantStatusFailure(response.data.error_json?.error_message));
        }
      })
      
      .catch((err) => {
        if(err.response.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
          dispatch(manageTenantStatusFailure());
        }
      });
  };
};

export const getunitsbylevelidinitiate =createAction("GETUNITS_BY_LEVELID_INITIATE")
export const getunitsbylevelidsuccess =createAction("GETUNITS_BY_LEVELID_SUCCESS")
export const getunitsbylevelidfailure =createAction("GETUNITS_BY_LEVELID_FAILURE")

export const getUnitsByLevelid =(data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(getunitsbylevelidinitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getAllUnitsByLevelId`,data,config)
    .then((response)=>{
      if(response.data.response_json.unitArrayList){
        dispatch(getunitsbylevelidsuccess(response.data.response_json.unitArrayList))
      }else{
       
        dispatch(getunitsbylevelidfailure([response.data.response_json.message]))
      }

     
    })
   
    .catch((err) => {
      if(err.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(getunitsbylevelidfailure('no data'))
      }
    });
}
}

export const getAvailableUnitsByLevelid =(data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(getunitsbylevelidinitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getAllAvailableUnitsByLevelId`,data,config)
    .then((response)=>{
      if(response.data.response_json.unitArrayList){
        dispatch(getunitsbylevelidsuccess(response.data.response_json.unitArrayList))
      }else{
       
        dispatch(getunitsbylevelidfailure([response.data.response_json.message]))
      }
    })
   
    .catch((err) => {
      if(err.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(getunitsbylevelidfailure('no data'))
      }
    });
}
}

export const getAllLevelsInitiate =createAction("GETALL_LEVELS_INITIATE")
export const getAllLevelsSuccess = createAction("GETALL_LEVELS_SUCCESS")
export const getAllLevelsFailure = createAction("GETALL_LEVELS_FAILURE")

export const getAllLevels = ()=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(getAllLevelsInitiate())
    axios.get(`${process.env.REACT_APP_API_URL}/getAllLevels`,config)
    .then((response)=>{
      
      dispatch(getAllLevelsSuccess(response.data?.response_json?.levelArrayList))
      
    })

    .catch((err) => {
     if(err.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(getAllLevelsFailure(err.data))
      }
    });
  }
}

export const getTenantByIDInitiate =createAction("GET_TENANT_BYID_INITIATE")
export const getTenantByIDScuccess = createAction("GET_TENANT_BYID_SUCCESS")
export const getTenantByIDFailure = createAction("GET_TENANT_BYID_FAILURE")

export const getTenantDetailsById = (data,token)=>{
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(getTenantByIDInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getCompanyDetailsByCompanyId`,data,config)
    .then((response)=>{
      if(response.data?.response_json){
        dispatch(getTenantByIDScuccess(response.data.response_json))
      }else{
        dispatch(getTenantByIDFailure(response.data?.error_json))
      }
   
      
    })
   
    .catch((err) => {
      if(err.response.status===401){
         dispatch(sessionExpired('Session Expired'));
       }else{
        dispatch(getTenantByIDFailure(err.data))
       }
     });
  }
}

export const getallrolesinitiate = createAction('GET_ALL_ROLES_INITIATE')
export const getallrolessuccess = createAction('GET_ALL_ROLES_SUCCESS')
export const getallrolesfailure = createAction('GET_ALL_ROLES_FAILURE')

export const getAllRoles =()=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(getallrolesinitiate())
    axios.get(`${process.env.REACT_APP_API_URL}/getAllRolesForManager`,config)
    .then((response)=>{
      dispatch(getallrolessuccess(response.data))
    })
   
    .catch((err) => {
      if(err.response.status===401){
         dispatch(sessionExpired('Session Expired'));
       }else{
        dispatch(getallrolesfailure(err.data))
       }
     });
  }
}


export const createBatchEmpInitiate = createAction("CREATE_BATCH_EMP_INITIATE");
export const createBatchEmpSuccess = createAction("CREATE_BATCH_EMP_SUCCESS");
export const createBatchEmpEmailExist = createAction("CREATE_BATCH_EMP_EmailExist");
export const createBatchEmpFailure = createAction("CREATE_BATCH_EMP_FAILURE");

export const addBatchEmployees = (data, token) => {

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(createBatchEmpInitiate());

    axios
      .post(`${process.env.REACT_APP_API_URL}/addBatchEmployees`, data, config)
      .then((response) => {
        if (response.data.response_json?.message) {
          dispatch(
            createBatchEmpSuccess(response.data.response_json?.message)
          );
        } else if(response.data.error_json?.message==='Below emails already exists. Please try another emails') {
          dispatch(createBatchEmpEmailExist(response.data.error_json));
          
        }else {
          dispatch(createBatchEmpFailure(response.data.error_json?.message));
        }
      })
     
      .catch((err) => {
        if(err.response.status===401){
           dispatch(sessionExpired('Session Expired'));
         }else{
          dispatch(createBatchEmpFailure());
         }
       });
  };
};

export const updateEmployeeApprovedStatusInitiate = createAction("UPDATE_EMPLOYEE_APPROVEDSTATUS_INITIATE")
export const updateEmployeeApprovedStatusSuccess = createAction("UPDATE_EMPLOYEE_APPROVEDSTATUS_SUCCESS")
export const updateEmployeeApprovedStatusFailure = createAction("UPDATE_EMPLOYEE_APPROVEDSTATUS_FAILURE")

export const updateEmployeeApprovedStatus=(data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(updateEmployeeApprovedStatusInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/updateEmployeeApprovedStatus`,data,config)
    .then((response)=>{
      dispatch(updateEmployeeApprovedStatusSuccess(response.data))
    })
    
    .catch((err) => {
      if(err.response.status===401){
         dispatch(sessionExpired('Session Expired'));
       }else{
        dispatch(updateEmployeeApprovedStatusFailure(err.data))
       }
     });
  }
}

export const editOffDateInitiate = createAction("EDIT_OFFDATE_INITIATE")
export const editOffDateSuccess = createAction("EDIT_OFFDATE_SUCCESS")
export const editOffDateFailure = createAction("EDIT_OFFDATE_FAILURE")

export const editOffDate = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch)=>{
    dispatch(editOffDateInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/updateOffboardEmployees`,data,config)
    .then((response)=>{
      if(response.data.response_status==1){
        dispatch(editOffDateSuccess(response.data.message))
      }else{
        dispatch(editOffDateFailure(response.data.message))
      }
    })
    .catch((err) => {
      if(err.response.status===401){
         dispatch(sessionExpired('Session Expired'));
       }else{
        dispatch(editOffDateFailure(err.data))
       }
     });
  }
}

export const getEmpDetailsbyidinitiate = createAction("GETEMPDETAILS_BY_ID_INITIATE")
export const getEmpDetailsbyidsuccess = createAction("GETEMPDETAILS_BY_ID_SUCCESS")
export const getEmpDetailsbyidfailure = createAction("GETEMPDETAILS_BY_ID_FAILURE")

export const getEmpDetailsById =(data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(getEmpDetailsbyidinitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getEmployeeDetailsById`,data,config)
    .then((response)=>{
      dispatch(getEmpDetailsbyidsuccess(response.data.response_json))
    })
   
    .catch((err) => {
      if(err.response.status===401){
         dispatch(sessionExpired('Session Expired'));
       }else{
        dispatch(getEmpDetailsbyidfailure(err.data))
       }
     });
  }
}

export const updateEmployeeInitiate = createAction("UPDATE_EMPLOYEE_INITIATE")
export const updateEmployeeSuccess = createAction("UPDATE_EMPLOYEE_SUCCESS")
export const updateEmployeeFailure = createAction("UPDATE_EMPLOYEE_FAILURE")

export const updateEmployee = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return(dispatch)=>{
    dispatch(updateEmployeeInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/updateEmployee`,data,config)
    .then((response)=>{
      if(response.data){
        dispatch(updateEmployeeSuccess(response.data))
      }
    })
   
    .catch((err) => {
      console.log(err)
      if(err.response.status===401){
         dispatch(sessionExpired('Session Expired'));
       }else{
        dispatch(updateEmployeeFailure(err.response.data))
       }
     });
  }
}

export const cancelOffEmpInitiate = createAction("CANCEL_OFFEMP_INITIATE")
export const cancelOffEmpSuccess = createAction("CANCEL_OFFEMP_SUCCESS")
export const cancelOffEmpFailure = createAction("CANCEL_OFFEMP_FAILURE")

export const cancelOffEmp = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(cancelOffEmpInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/cancelOffboardEmployees`,data,config)
    .then((response)=>{
      if(response.data.response_status==1){
        dispatch(cancelOffEmpSuccess(response.data.message))
      }else{
        dispatch(cancelOffEmpFailure(response.data.message))
      }
    })
    .catch((err) => {
      if(err?.response?.status===401){
         dispatch(sessionExpired('Session Expired'));
       }else{
        dispatch(cancelOffEmpFailure(err.data))
       }
     });
  }
}

export const getAllTenantEmpInitiate = createAction(" GETALL_TENANT_EMPLOYEE_INITIATE")
export const getAllTenantEmpSuccess = createAction("GETALL_TENANT_EMPLOYEE_SUCCESS")
export const getAllTenantEmpFailure = createAction(" GETALL_TENANT_EMPLOYEE_FAILURE")


export const getTenantEmp = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(getAllTenantEmpInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getAllTenantEmployee`,data,config)
    .then((response)=>{
      if(response.data.response_json?.length>0){
        dispatch(getAllTenantEmpSuccess(response.data))
      }else if(response.data.error_json?.error_message==='No data found'){
        dispatch(getAllTenantEmpSuccess({response_json:[]}))
      }
    })
   
    .catch((err) => {
      if(err?.response?.status===401){
         dispatch(sessionExpired('Session Expired'));
       }else{
        dispatch(getAllTenantEmpFailure(err.data))
       }
     });
  }
}

export const getBatchEmpInitiate = createAction("GETBATCHEMP_INITIATE")
export const getBatchEmpSuccess = createAction("GETBATCHEMP_SUCCESS")
export const getBatchEmpFailure = createAction("GETBATCHEMP_FAILURE")

export const getBatchEmp = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
   dispatch(getBatchEmpInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getAllBatchEmployeesById`,data,config)
    .then((response)=>{
      dispatch(getBatchEmpSuccess(response.data.response_json))
    })
    .catch((error)=>{
      if(error.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(getBatchEmpFailure())
      }
    })
  }
}

export const offBoardEmpInitiate = createAction("OFFBOARD_EMP_INITIATE")
export const offBoardEmpSuccess = createAction("OFFBOARD_EMP_SUCCESS")
export const offBoardEmpFailure = createAction("OFFBOARD_EMP_FAILURE")

export const offBoardEmp = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
   dispatch(offBoardEmpInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addOffboardEmployees`,data,config)
    .then((response)=>{
      if(response.data.response_status==1){
        dispatch(offBoardEmpSuccess(response.data.message))
      }else{
        dispatch(offBoardEmpSuccess(response.data.message))
      }
      
    })
    .catch((error)=>{
      if(error.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(offBoardEmpFailure(error.response.message))
      }
    })
  }
}

export const offBoardTenantInitiate = createAction("OFFBOARD_TENANT_INITIATE")
export const offBoardTenantSuccess = createAction("OFFBOARD_TENANT_SUCCESS")
export const offBoardTenantFailure = createAction("OFFBOARD_TENANT_FAILURE")

export const offBoardTenant = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
   dispatch(offBoardTenantInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addOffboardCompanies`,data,config)
    .then((response)=>{
      if(response.data.response_json){
        dispatch(offBoardTenantSuccess(response.data.response_json.success_message))
      }else if(response.data.error_json){
        dispatch(offBoardTenantFailure(response.data.error_json))
      }
    })
    .catch((error)=>{
      if(error.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(offBoardTenantFailure())
      }
    })
  }
}

export const tenantAccessLevelInitiate = createAction("TENANT_ACCESS_LEVEL_INITIATE")
export const tenantAccessLevelSuccess = createAction("TENANT_ACCESS_LEVEL_SUCCESS")
export const tenantAccessLevelFailure = createAction("TENANT_ACCESS_LEVEL_FAILURE")

export const getTenantAccessLevel = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const bodyParams={
    key:'value'
  }
  return(dispatch)=>{
   dispatch(tenantAccessLevelInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getAssignedLevelsForCompany`,bodyParams,config)
    .then((response)=>{
      dispatch(tenantAccessLevelSuccess(response.data.response_json))
    })
    .catch((error)=>{
      if(error.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(tenantAccessLevelFailure())
      }
    })
  }
}

export const tenantAccessUnitInitiate = createAction("TENANT_ACCESS_UNIT_INITIATE")
export const tenantAccessUnitSuccess = createAction("TENANT_ACCESS_UNIT_SUCCESS")
export const tenantAccessUnitFailure = createAction("TENANT_ACCESS_UNIT_FAILURE")

export const getTenantAccessUnit = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const bodyParams={
    key:'value'
  }
  return(dispatch)=>{
   dispatch(tenantAccessUnitInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getAssignedUnitsByLevelId`,data,config)
    .then((response)=>{
      dispatch(tenantAccessUnitSuccess(response.data.response_json))
    })
    .catch((error)=>{
      if(error.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(tenantAccessUnitFailure())
      }
    })
  }
}

export const storeFCMTokenInitiate = createAction("STORE_FCM_TOKEN_INITIATE")
export const storeFCMTokenSuccess = createAction("STORE_FCM_TOKEN_SUCCESS")
export const storeFCMTokenFailure = createAction("STORE_FCM_TOKEN_FAILURE")
export const saveFCMToken = (requestData) => {
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
   dispatch(storeFCMTokenInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/updateFcmToken`,requestData,config)
    .then((response)=>{
      dispatch(storeFCMTokenSuccess(response.data.response_json))
    })
    .catch((error)=>{
      if(error.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(storeFCMTokenFailure())
      }
    })
  }
}

export const sendReminderInitiate = createAction("SEND_REMINDER_INITIATE")
export const sendReminderSuccess = createAction("SEND_REMINDER_SUCCESS")
export const sendReminderFailure = createAction("SEND_REMINDER_FAILURE")

export const sendReminder = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(sendReminderInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/sendReminder`,data,config)
    .then((response)=>{
      dispatch(sendReminderSuccess(response.data))
    })
    .catch((error)=>{
      if(error.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(sendReminderFailure())
      }
    })
  }
}

const getBmsCoordinatorListInitiate = createAction("COORDINATOR_LIST_INITIATE")
const getBmsCoordinatorListSuccess = createAction("COORDINATOR_LIST_SUCCESS")
const getBmsCoordinatorListFailure = createAction("COORDINATOR_LIST_FAILURE")

export const getBmsCoordinatorList = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
      dispatch(getBmsCoordinatorListInitiate())
      axios.post(`${process.env.REACT_APP_API_URL}/getBmsCoordinatorList`,data,config)
      .then((response)=>{
        if(response.data.response_status==1){
          dispatch(getBmsCoordinatorListSuccess(response.data.data_array.userArrayList))
        }
      })
      .catch((error)=>{
        if(error.response.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
          dispatch(getBmsCoordinatorListFailure())
        }
      })
  }
}

const getAllEmployeeListForUpdateDefaultRoleInitiate = createAction("GET_ALL_EMPLOYEE_LIST_FOR_UPDATE_DEFAULT_ROLE_INITIATE")
const getAllEmployeeListForUpdateDefaultRoleSuccess = createAction("GET_ALL_EMPLOYEE_LIST_FOR_UPDATE_DEFAULT_ROLE_SUCCESS")
const getAllEmployeeListForUpdateDefaultRoleFailure = createAction("GET_ALL_EMPLOYEE_LIST_FOR_UPDATE_DEFAULT_ROLE_FAILURE")
const getAllEmployeeListForUpdateDefaultRoleNull = createAction("GET_ALL_EMPLOYEE_LIST_FOR_UPDATE_DEFAULT_ROLE_NULL")

export const getAllEmployeeListForUpdateDefaultRoleNullAction = () => dispatch => dispatch(getAllEmployeeListForUpdateDefaultRoleNull());

export const getAllEmployeeListForUpdateDefaultRoleAction = (params) => async (dispatch) => {
  dispatch(getAllEmployeeListForUpdateDefaultRoleInitiate());
  try {
      const response = await ApiService().post('/getAllEmployeesListForUpdateDefaultRole', params);
       if(response.data?.response_status === 1){
          console.log(response)
          dispatch(getAllEmployeeListForUpdateDefaultRoleSuccess(response.data));
      }else{
          dispatch(getAllEmployeeListForUpdateDefaultRoleFailure(response.data));
      }
  } catch (error) {
      if(error.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
            dispatch(getAllEmployeeListForUpdateDefaultRoleFailure(error.response.data));
          }
  }
}

const getAssignedLevelsToEditDefaultAccessInitiate = createAction("GET_ASSIGNED_LEVELS_TO_EDIT_DEFAULT_ACCESS_INITIATE")
const getAssignedLevelsToEditDefaultAccessSuccess = createAction("GET_ASSIGNED_LEVELS_TO_EDIT_DEFAULT_ACCESS_SUCCESS")
const getAssignedLevelsToEditDefaultAccessFailure = createAction("GET_ASSIGNED_LEVELS_TO_EDIT_DEFAULT_ACCESS_FAILURE")
const getAssignedLevelsToEditDefaultAccessNull = createAction("GET_ASSIGNED_LEVELS_TO_EDIT_DEFAULT_ACCESS_NULL")

export const getAssignedLevelsToEditDefaultAccessNullAction = () => dispatch => dispatch(getAssignedLevelsToEditDefaultAccessNull());

export const getAssignedLevelsToEditDefaultAccessAction = () => async (dispatch) => {
  dispatch(getAssignedLevelsToEditDefaultAccessInitiate());
  try {
      const response = await ApiService().get('/getAssignedLevelsToEditDefaultAccess');
       if(response.data?.response_status === 1){
          console.log(response)
          dispatch(getAssignedLevelsToEditDefaultAccessSuccess(response.data));
      }else{
          dispatch(getAssignedLevelsToEditDefaultAccessFailure(response.data));
      }
  } catch (error) {
      if(error.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
            dispatch(getAssignedLevelsToEditDefaultAccessFailure(error.response.data));
          }
  }
}

const updateDefaulAccessLevelInitiate = createAction("UPDATE_DEFAULT_ACCESS_LEVEL_INITIATE")
const updateDefaulAccessLevelSuccess = createAction("UPDATE_DEFAULT_ACCESS_LEVEL_SUCCESS")
const updateDefaulAccessLevelFailure = createAction("UPDATE_DEFAULT_ACCESS_LEVEL_FAILURE")
const updateDefaulAccessLevelNull = createAction("UPDATE_DEFAULT_ACCESS_LEVEL_NULL")

export const updateDefaultAccessLevelNullAction = () => dispatch => dispatch(updateDefaulAccessLevelNull());

export const updateDefaultAccessLevelAction = (params) => async (dispatch) => {
  dispatch(updateDefaulAccessLevelInitiate());
  try {
      const response = await ApiService().post('/updateDefaulAccessLevel',params);
       if(response.data?.response_status === 1){
          console.log(response)
          dispatch(updateDefaulAccessLevelSuccess(response.data));
      }else{
          dispatch(updateDefaulAccessLevelFailure(response.data));
      }
  } catch (error) {
      if(error.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
            dispatch(updateDefaulAccessLevelFailure(error.response.data));
          }
  }
}

export const resendWelcomeMailInitiate = createAction("RESEND_WELCOME_MAIL_INITIATE")
export const resendWelcomeMailSuccess = createAction("RESEND_WELCOME_MAIL_SUCCESS")
export const resendWelcomeMailFailure = createAction("RESEND_WELCOME_MAIL_FAILURE")
const resendWelcomeMailNull = createAction("RESEND_WELCOME_MAIL_NULL")

export const resendWelcomeMailNullAction = () => dispatch => dispatch(resendWelcomeMailNull());

export const resendWelcomeMail = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(resendWelcomeMailInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/ResendWelcomeMailForEmployees`,data,config)
    .then((response)=>{
      console.log('resendmail response ',response)
      if(response.status == 200){
        dispatch(resendWelcomeMailSuccess(response.data))
      }else{
        dispatch(resendWelcomeMailFailure(response.data))
      }
    })
    .catch((err) => {
      if(err?.response?.status===401){
         dispatch(sessionExpired('Session Expired'));
       }else{
        dispatch(resendWelcomeMailFailure(err.data))
       }
     });
  }
}

export const resendInviteMailInitiate = createAction("RESEND_INVITATION_MAIL_INITIATE")
export const resendInviteMailSuccess = createAction("RESEND_INVITATION_MAIL_SUCCESS")
export const resendInviteMailFailure = createAction("RESEND_INVITATION_MAIL_FAILURE")
const resendInviteMailNull = createAction("RESEND_INVITATION_MAIL_NULL")

export const resendInviteMailNullAction = () => dispatch => dispatch(resendInviteMailNull());

export const resendInviteMail = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(resendInviteMailInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/ResendingInvitationsEmails`,data,config)
    .then((response)=>{
      console.log('resendmail response ',response)
      if(response.status == 200){
        dispatch(resendInviteMailSuccess(response.data))
      }else{
        dispatch(resendInviteMailFailure(response.data))
      }
    })
    .catch((err) => {
      if(err?.response?.status===401){
         dispatch(sessionExpired('Session Expired'));
       }else{
        dispatch(resendInviteMailFailure(err.data))
       }
     });
  }
}

export const testMailInitiate = createAction("TEST_MAIL_INITIATE")
export const testMailSuccess = createAction("TEST_MAIL_SUCCESS")
export const testMailFailure = createAction("TEST_MAIL_FAILURE")
const testMailNull = createAction("TEST_MAIL_NULL")

export const testMailNullAction = () => dispatch => dispatch(testMailNull());

export const testMail = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(testMailInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/sendMailToUser`,data,config)
    .then((response)=>{
      console.log('resendmail response ',response)
      if(response.status == 200){
        dispatch(testMailSuccess(response.data))
      }else{
        dispatch(testMailFailure(response.data))
      }
    })
    .catch((err) => {
      if(err?.response?.status===401){
         dispatch(sessionExpired('Session Expired'));
       }else{
        dispatch(testMailFailure(err.data))
       }
     });
  }
}

export const getContactConfigInitiate =createAction("GET_CONTACTCONFIG_INITIATE")
export const getContactConfigScuccess = createAction("GET_CONTACTCONFIG_SUCCESS")
export const getContactConfigFailure = createAction("GET_CONTACTCONFIG_FAILURE")

export const getContactConfig = ()=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(getContactConfigInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getAllContactConfigNew`,'',config)
    .then((response)=>{
      console.log("contact response ",response)
      if(response.data?.response_status == 1){
        dispatch(getContactConfigScuccess(response.data.dataArray))
      }else{
        dispatch(getContactConfigFailure(response.data?.error_json))
      }
   
      
    })
   
    .catch((err) => {
      if(err.response.status===401){
         dispatch(sessionExpired('Session Expired'));
       }else{
        dispatch(getContactConfigFailure(err.data))
       }
     });
  }
}

export const updateContactConfigInitiate =createAction("UPDATE_CONTACTCONFIG_INITIATE")
export const updateContactConfigScuccess = createAction("UPDATE_CONTACTCONFIG_SUCCESS")
export const updateContactConfigFailure = createAction("UPDATE_CONTACTCONFIG_FAILURE")
const updateConfigContactNull = createAction("UPDATE_CONTACTCONFIG_NULL")

export const updateConfigContactNullAction = () => dispatch => dispatch(updateConfigContactNull());

export const updateContactConfig = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(updateContactConfigInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addContactConfig`,data,config)
    .then((response)=>{
      console.log("contact response ",response)
      if(response.data?.response_status == "success"){
        dispatch(updateContactConfigScuccess(response.data.response_message))
      }else{
        console.log("within ")
        dispatch(updateContactConfigFailure(response.data?.response_message))
        return
      }
   
      
    })
   
    .catch((err) => {
      console.log("err ",err)
      if(err?.response?.status===401){
         dispatch(sessionExpired('Session Expired'));
       }else{
        console.log("err2 ",err)
        dispatch(updateContactConfigFailure(err.data))
       }
     });
  }
}

export const generalUploadInitiate = createAction("GENERAL_UPLOAD_INITIATE");
export const generalUploadSuccess = createAction("GENERAL_UPLOAD_SUCCESS");
export const generalUploadFailure = createAction("GENERAL_UPLOAD_FAILURE");

export const generalUpload = (data, token) => {

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(generalUploadInitiate());

    axios
      .post(`${process.env.REACT_APP_API_URL}/Generalupload`, data, config)
      .then((response) => {
        console.log("response ",response)
        if (response.data.response_json?.message) {
          dispatch(
            generalUploadSuccess(response.data.response_json?.message)
          );
        } else {
          dispatch(generalUploadFailure(response.data.error_json?.message));
        }
      })
     
      .catch((err) => {
        if(err.response.status===401){
           dispatch(sessionExpired('Session Expired'));
         }else{
          dispatch(generalUploadFailure());
         }
       });
  };
};
