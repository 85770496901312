import { handleActions } from "redux-actions";

const responseDef = {
  isLoading: false,
  response: null,
  isError: false,
}

const CommonReducer = {
  empList: [],
  empAddStatus: "",
  tenantList: [],
  tenantAddStatus: "",
  tenantUpdateStatus: "",
  tenantCount: "",
  unitDatas: [],
  levelDatas: [],
  tenantUpdateStatus:null,
  tenantDetails:{},
  roleData:[],
  updateEmployeeApprovedStatus:"",
  batchAddStatus:'',
  empDetails:{},
  existEmailList:[],
  updateEmployeeStatus:null,
  tenantEmp:[],
  sessionExpired:false,
  batchEmp:[],
  empOffBoardStatus:null,
  tenantOffBoardStatus:null,
  empCount:0,
  tenantOccupiedLeves:[],
  tenantOccupiedUnits:[],
  getUnitsLoadingStatus: false,
  empCountForTenant:0,
  editOffDateStatus:null,
  cancelOffboardEmpStatus:null,
  sendReminderStatus:null,
  bmsCoordinatorList:[],
  getAllEmployeeListForUpdateDefaultRole: {...responseDef},
  getAssignedLevelsToEditDefaultAccess: {...responseDef},
  updateDefaultAccessLevel: {...responseDef},
  resendMailForEmployees: {...responseDef},
  resendInviteMail:{...responseDef},
  testMail:{...responseDef},
  getContactConfigData :{...responseDef},
  updatedContactConfigData: {...responseDef},
  generalUploadData: {...responseDef}

};
const reducer = handleActions(
  {
    UPDATE_STATE: (state, action) => {
      return {
        ...state,
        empAddStatus: "",
        tenantAddStatus: "",
        tenantUpdateStatus: null,
        empOffBoardStatus:null,
        tenantOffBoardStatus:null,
        updateEmployeeStatus:null,
        batchAddStatus:null,
        empList:null,
        empCount:0,
        empOffBoardStatus:null,
        editOffDateStatus:null,
        cancelOffboardEmpStatus:null,
        sendReminderStatus:null,
        getAllEmployeeListForUpdateDefaultRole: {...responseDef},
        resendMailForEmployees: {...responseDef},
        resendInviteMail:{...responseDef},
        testMail:{...responseDef}
      }
    },
    GET_EMPLIST_INITIATE: (state) => {
      return {
        ...state,
        isLoading:true,
      };
    },
    GET_EMPLIST_SUCCESS: (state, action) => {
      return {
        ...state,
        isLoading:false,
        empList:action.payload.response_json,
        empCount:action.payload.totalRecords

      };
    },
    GET_EMPLIST_FAILURE: (state,action) => {
    
      return {
        ...state,
        isLoading:false,
        empList:[],
        empCount:0
      };
    },
    CREATE_EMP_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    CREATE_EMP_SUCCESS: (state, action) => {
      return {
        ...state,
        empAddStatus: action.payload,
      };
    },
    CREATE_EMP_FAILURE: (state, action) => {
      return {
        ...state,
        empAddStatus: action.payload,
      };
    },
    GET_COMPANYLIST_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_COMPANYLIST_SUCCESS: (state, action) => {

      return {
        ...state,
        tenantList: action.payload?.response_json,
        tenantCount: action.payload?.total_company_count
      };
    },
    GET_COMPANYLIST_FAILURE: (state) => {
      return {
        ...state,
      };
    },
    CREATE_TENANT_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    CREATE_TENANT_SUCCESS: (state, action) => {
      return {
        ...state,
        tenantAddStatus: action.payload,
      };
    },
    CREATE_TENANT_FAILURE: (state, action) => {
      return {
        ...state,
        tenantAddStatus: action.payload,
      };
    },
    UPDATE_TENANT_INITIATE: (state) => {
      return {
        ...state,  
        tenantUpdateStatus: null      
      }
    },
    UPDATE_TENANT_SUCCESS: (state, action) => {
      return {
        ...state,  
        tenantUpdateStatus: action.payload,      
      }
    },
    UPDATE_TENANT_FAILURE: (state, action) => {
      return {
        ...state,        
        tenantUpdateStatus: action.payload,      
      }
    },

    MANAGE_EMP_INITIATE: (state) => {
      return {
        ...state,
      }
    },
    MANAGE_EMP_SUCCESS: (state) => {
      return {
        ...state
      }
    },
    MANAGE_EMP_FAILURE: (state) => {
      return {
        ...state,
      }
    },
    MANAGE_TENANT_INITIATE: (state) => {
      return {
        ...state,
      }
    },
    MANAGE_TENANT_SUCCESS: (state,action) => {

      return {
        ...state,
        tenantUpdateStatus:action.payload
      }
    },
    MANAGE_TENANT_FAILURE: (state,action) => {

      return {
        ...state,
        tenantUpdateStatus:action.payload
      }
    },
    GETUNITS_BY_LEVELID_INITIATE: (state) => {
      return {
        ...state,

      }
    },
    GETUNITS_BY_LEVELID_SUCCESS: (state, action) => {

      return {
        ...state,
        unitDatas: action.payload
      }
    },
    GETUNITS_BY_LEVELID_FAILURE: (state, action) => {

      return {
        ...state,

        unitDatas: []
      }
    },
    GETALL_LEVELS_INITIATE: (state) => {
      return {
        ...state
      }
    },
    GETALL_LEVELS_SUCCESS: (state, action) => {
      return {
        ...state,
        levelDatas: action.payload
      }
    },
    GETALL_LEVELS_FAILURE: (state, action) => {
      return {
        ...state,
        levelDatas: []
      }
    },
    GET_TENANT_BYID_INITIATE: (state) => {
      return {
        ...state
      }
    },
    GET_TENANT_BYID_SUCCESS: (state, action) => {
      return {
        ...state,
        tenantDetails: action.payload
      }
    },
    GET_TENANT_BYID_FAILURE: (state, action) => {
      return {
        ...state,
        tenantDetails: action.payload
      }
    },
    GET_ALL_ROLES_INITIATE:(state,action)=>{
      return{
        ...state
        
      }
    },
    GET_ALL_ROLES_SUCCESS:(state,action)=>{
      return{
          ...state,
          roleData:action.payload
      }
    },
    GET_ALL_ROLES_FAILURE:(state,action)=>{
      return{
          ...state,
          roleData:action.payload
      }
    },
    UPDATE_EMPLOYEE_APPROVEDSTATUS_INITIATE:(state)=>{
     return{
      ...state
     }
    },
    UPDATE_EMPLOYEE_APPROVEDSTATUS_SUCCESS:(state,action)=>{
      return{
        ...state,
        updateEmployeeApprovedStatus:action.payload
      }
    },
    UPDATE_EMPLOYEE_APPROVEDSTATUS_FAILURE:(state,action)=>{
      return{
        ...state,
        updateEmployeeApprovedStatus:action.payload
      }
    },
    CREATE_BATCH_EMP_INITIATE:(state,action)=>{
      return{
        ...state
        
      }
    },
    CREATE_BATCH_EMP_SUCCESS:(state,action)=>{
      return{
          ...state,
          batchAddStatus:action.payload
      }
    },
    CREATE_BATCH_EMP_EmailExist:(state,action)=>{
      return{
          ...state,
          existEmailList:action.payload.existEmailIdArray,
          batchAddStatus:action.payload.message
      }
    },
    CREATE_BATCH_EMP_FAILURE:(state,action)=>{
      return{
          ...state,
          batchAddStatus:action.payload
      }
    },
    GETEMPDETAILS_BY_ID_INITIATE:(state)=>{
      return{
        ...state
      }
    },
    GETEMPDETAILS_BY_ID_SUCCESS:(state,action)=>{
      return{
        ...state,
        empDetails:action.payload

      }
    },
    GETEMPDETAILS_BY_ID_FAILURE:(state,action)=>{
      return{
        ...state,
        empDetails:action.payload
      }
    },
    UPDATE_EMPLOYEE_INITIATE:(state)=>{
      return{
        ...state
      }
    },
    UPDATE_EMPLOYEE_SUCCESS:(state,action)=>{
      return{
        ...state,
        updateEmployeeStatus:action.payload
      }
    },
    UPDATE_EMPLOYEE_FAILURE:(state,action)=>{
      return{
        ...state,
        updateEmployeeStatus:action.payload
      }
    },
    GETALL_TENANT_EMPLOYEE_INITIATE:(state)=>{
      return{
        ...state
      }
    },
    GETALL_TENANT_EMPLOYEE_SUCCESS:(state,action)=>{
      return{
        ...state,
        tenantEmp:action.payload.response_json,
        empCountForTenant:action.payload.total_company_count
      }
    },
    GETALL_TENANT_EMPLOYEE_FAILURE:(state,action)=>{
      return{
        ...state,
        tenantEmp:action.payload,
        empCountForTenant:0
      }
    },
    SESSION_EXPIRED:(state,action)=>{
      return{
        ...state,
        sessionExpired:true
      }
    },
    GETBATCHEMP_INITIATE:(state)=>{
      return{
        ...state,
      
      }
    },
    GETBATCHEMP_SUCCESS:(state,action)=>{
      return{
        ...state,
      batchEmp:action.payload
      }
    },
    GETBATCHEMP_FAILURE:(state,action)=>{
      return{
        ...state,
        batchEmp:action.payload
      }
    }
    ,
    OFFBOARD_EMP_INITIATE:(state,action)=>{
      return{
        ...state,
        empOffBoardStatus:action.payload
      }
    } ,
    OFFBOARD_EMP_SUCCESS:(state,action)=>{
      return{
        ...state,
        empOffBoardStatus:action.payload
      }
    } ,
    OFFBOARD_EMP_FAILURE:(state,action)=>{
      return{
        ...state,
        empOffBoardStatus:action.payload
      }
    },
    OFFBOARD_TENANT_INITIATE:(state,action)=>{
      return{
        ...state,
      }
    },
    OFFBOARD_TENANT_SUCCESS:(state,action)=>{
      return{
        ...state,
        tenantOffBoardStatus:action.payload
      }
    },
    OFFBOARD_TENANT_FAILURE:(state,action)=>{
      return{
        ...state,
        tenantOffBoardStatus:action.payload
      }
    },
    TENANT_ACCESS_LEVEL_INITIATE:(state,action)=>{
      return{
        ...state,
        tenantOccupiedLeves:[]
      }
    },
    TENANT_ACCESS_LEVEL_SUCCESS:(state,action)=>{
      return{
        ...state,
        tenantOccupiedLeves:action.payload
      }
    },
    TENANT_ACCESS_LEVEL_FAILURE:(state,action)=>{
      return{
        ...state,
        tenantOccupiedLeves:[]
      }
    },
    TENANT_ACCESS_UNIT_INITIATE:(state,action)=>{
      return{
        ...state,
        tenantOccupiedUnits:[],
        getUnitsLoadingStatus: true
      }
    },
    TENANT_ACCESS_UNIT_SUCCESS:(state,action)=>{
      return{
        ...state,
        tenantOccupiedUnits:action.payload,
        getUnitsLoadingStatus: false
      }
    },
    TENANT_ACCESS_UNIT_FAILURE:(state,action)=>{
      return{
        ...state,
        tenantOccupiedUnits:[],
        getUnitsLoadingStatus: false
      }
    },
    EDIT_OFFDATE_INITIATE:(state)=>{
      return{
        ...state
      }
    },
    EDIT_OFFDATE_SUCCESS:(state,action)=>{
      return{
        ...state,
        editOffDateStatus:action.payload
      }
    },
    EDIT_OFFDATE_FAILURE:(state,action)=>{
      return{
        ...state,
        editOffDateStatus:action.payload
      }
    },
    CANCEL_OFFEMP_INITIATE:(state)=>{
      return{
        ...state,
      }
    },
    CANCEL_OFFEMP_SUCCESS:(state,action)=>{
      return{
        ...state,
        cancelOffboardEmpStatus:action.payload
      }
    },
    CANCEL_OFFEMP_FAILURE:(state,action)=>{
      return{
        ...state,
        cancelOffboardEmpStatus:action.payload
      }
    },
    SEND_REMINDER_INITIATE:(state)=>{
      return{
        ...state,
      }
    },
    SEND_REMINDER_SUCCESS:(state,action)=>{
      return{
        ...state,
        sendReminderStatus:action.payload
      }
    },
    SEND_REMINDER_FAILURE:(state,action)=>{
      return{
        ...state,
        sendReminderStatus:action.payload
      }
    },
    COORDINATOR_LIST_INITIATE:(state)=>{
      return{
        ...state
      }
    },
    COORDINATOR_LIST_SUCCESS:(state,action)=>{
      return{
        ...state,
        bmsCoordinatorList:action.payload
      }
    },
    COORDINATOR_LIST_FAILURE:(state)=>{
      return{
        ...state,
        bmsCoordinatorList:[]
      }
    },
    GET_ALL_EMPLOYEE_LIST_FOR_UPDATE_DEFAULT_ROLE_INITIATE:(state)=>{
        return{
          ...state,
          getAllEmployeeListForUpdateDefaultRole:{
            ...state.getAllEmployeeListForUpdateDefaultRole,
            isError: false,
            isLoading: true,
          }
        }
      },
      GET_ALL_EMPLOYEE_LIST_FOR_UPDATE_DEFAULT_ROLE_SUCCESS:(state,action)=>{
        return{
          ...state,
          getAllEmployeeListForUpdateDefaultRole: {
            isError: false,
            isLoading: false,
            response: action.payload
        }
        }
      },
      GET_ALL_EMPLOYEE_LIST_FOR_UPDATE_DEFAULT_ROLE_FAILURE:(state,action)=>{
        return{
          ...state,
          getAllEmployeeListForUpdateDefaultRole:  {response: action.payload, isLoading: false, isError: true}
        }
      },
      GET_ALL_EMPLOYEE_LIST_FOR_UPDATE_DEFAULT_ROLE_NULL: (state,) => {
        return {
            ...state,
            getAllEmployeeListForUpdateDefaultRole: {...responseDef}
        }
    },
    GET_ASSIGNED_LEVELS_TO_EDIT_DEFAULT_ACCESS_INITIATE:(state)=>{
      return{
        ...state,
        getAssignedLevelsToEditDefaultAccess:{
          ...state.getAssignedLevelsToEditDefaultAccess,
          isError: false,
          isLoading: true,
        }
      }
    },
    GET_ASSIGNED_LEVELS_TO_EDIT_DEFAULT_ACCESS_SUCCESS:(state,action)=>{
      return{
        ...state,
        getAssignedLevelsToEditDefaultAccess: {
          isError: false,
          isLoading: false,
          response: action.payload
      }
      }
    },
    GET_ASSIGNED_LEVELS_TO_EDIT_DEFAULT_ACCESS_FAILURE:(state,action)=>{
      return{
        ...state,
        getAssignedLevelsToEditDefaultAccess:  {response: action.payload, isLoading: false, isError: true}
      }
    },
    GET_ASSIGNED_LEVELS_TO_EDIT_DEFAULT_ACCESS_NULL: (state,) => {
      return {
          ...state,
          getAssignedLevelsToEditDefaultAccess: {...responseDef}
      }
  },
    RESEND_WELCOME_MAIL_INITIATE:(state)=>{
      return{
        ...state,
        resendMailForEmployees:{
          ...state.resendMailForEmployees,
          isError: false,
          isLoading: true,
        }
      }
    },
    RESEND_WELCOME_MAIL_SUCCESS:(state,action)=>{
      return{
        ...state,
        resendMailForEmployees: {
          isError: false,
          isLoading: false,
          response: action.payload
      }
      }
    },
    RESEND_WELCOME_MAIL_FAILURE:(state,action)=>{
      console.log('failure action ',action)
      return{
        ...state,
        resendMailForEmployees:  {response: action.payload, isLoading: false, isError: true}
      }
    },
    RESEND_WELCOME_MAIL_NULL: (state,) => {
      return {
          ...state,
          resendMailForEmployees: {...responseDef}
      }
  },
  RESEND_INVITATION_MAIL_INITIATE:(state)=>{
    return{
      ...state,
      resendInviteMail:{
        ...state.resendInviteMail,
        isError: false,
        isLoading: true,
      }
    }
  },
  RESEND_INVITATION_MAIL_SUCCESS:(state,action)=>{
    console.log('success action ',action)
    return{
      ...state,
      resendInviteMail: {
        isError: false,
        isLoading: false,
        response: action.payload
    }
    }
  },
  RESEND_INVITATION_MAIL_FAILURE:(state,action)=>{
    console.log('failure action ',action)
    return{
      ...state,
      resendInviteMail:  {response: action.payload, isLoading: false, isError: true}
    }
  },
  RESEND_INVITATION_MAIL_NULL: (state,) => {
    return {
        ...state,
        resendInviteMail: {...responseDef}
    }
},
TEST_MAIL_INITIATE:(state)=>{
  return{
    ...state,
    testMail:{
      ...state.testMail,
      isError: false,
      isLoading: true,
    }
  }
},
TEST_MAIL_SUCCESS:(state,action)=>{
  console.log('success action ',action)
  return{
    ...state,
    testMail: {
      isError: false,
      isLoading: false,
      response: action.payload
  }
  }
},
TEST_MAIL_FAILURE:(state,action)=>{
  console.log('failure action ',action)
  return{
    ...state,
    testMail:  {response: action.payload, isLoading: false, isError: true}
  }
},
TEST_MAIL_NULL: (state,) => {
  return {
      ...state,
      testMail: {...responseDef}
  }
},
  UPDATE_DEFAULT_ACCESS_LEVEL_INITIATE:(state)=>{
    return{
      ...state,
      updateDefaultAccessLevel:{
        ...state.updateDefaultAccessLevel,
        isError: false,
        isLoading: true,
      }
    }
  },
  UPDATE_DEFAULT_ACCESS_LEVEL_SUCCESS:(state,action)=>{
    return{
      ...state,
      updateDefaultAccessLevel: {
        isError: false,
        isLoading: false,
        response: action.payload
    }
    }
  },
  UPDATE_DEFAULT_ACCESS_LEVEL_FAILURE:(state,action)=>{
    return{
      ...state,
      updateDefaultAccessLevel:  {response: action.payload, isLoading: false, isError: true}
    }
  },
  UPDATE_DEFAULT_ACCESS_LEVEL_NULL: (state,) => {
    return {
        ...state,
        updateDefaultAccessLevel: {...responseDef}
    }
},
GET_CONTACTCONFIG_INITIATE:(state)=>{
    return{
      ...state,
      getContactConfigData:{
        ...state.getContactConfigData,
        isError: false,
        isLoading: true,
      }
    }
  },
  GET_CONTACTCONFIG_SUCCESS:(state,action)=>{
    return{
      ...state,
      getContactConfigData: {
        isError: false,
        isLoading: false,
        response: action.payload
    }
    }
  },
  GET_CONTACTCONFIG_FAILURE:(state,action)=>{
    return{
      ...state,
      getContactConfigData:  {response: action.payload, isLoading: false, isError: true}
    }
  },
  UPDATE_CONTACTCONFIG_INITIATE:(state)=>{
    return{
      ...state,
      updatedContactConfigData:{
        ...state.updatedContactConfigData,
        isError: false,
        isLoading: true,
      }
    }
  },
  UPDATE_CONTACTCONFIG_SUCCESS:(state,action)=>{
    return{
      ...state,
      updatedContactConfigData: {
        isError: false,
        isLoading: false,
        response: action.payload
    }
    }
  },
  UPDATE_CONTACTCONFIG_FAILURE:(state,action)=>{
    return{
      ...state,
      updatedContactConfigData:  {response: action.payload, isLoading: false, isError: true}
    }
  },
  UPDATE_CONTACTCONFIG_NULL: (state,) => {
    return {
        ...state,
        updatedContactConfigData: {...responseDef}
    }
  },
  
  GENERAL_UPLOAD_INITIATE:(state,action)=>{
    return{
      ...state,
      generalUploadData:{
        ...state.generalUploadData,
        isError: false,
        isLoading: true,
      }
      
    }
  },
  GENERAL_UPLOAD_SUCCESS:(state,action)=>{
    return{
        ...state,
        generalUploadData: {
          isError: false,
          isLoading: false,
          response: action.payload
      }
    }
  },
  GENERAL_UPLOAD_FAILURE:(state,action)=>{
    return{
      ...state,
      generalUploadData:  {response: action.payload, isLoading: false, isError: true}
    }
  },
  
  },
  CommonReducer
);

export default reducer;
