import React, { useEffect, useState, useRef } from "react";
import PropTypes, { number } from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as BmsStaffAction from "../../redux/actions/bmsStaffAction";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Chip,
  List,
  ListItem,
  ListItemText,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CalendarPicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ReactComponent as RefreshIcon } from "../../Assets/icons/Refresh.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as commonAction from "../../redux/actions/commonAction";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getLastUpdatedTime,
  preventDatePickerKeyboard,
} from "../../Utils/utils.js";
import ToastAlert from "../common/Alert";
import Loader from "../common/Loader";
import { containsNumbers } from "../../Utils/validation.js";
import { ReactComponent as RoundedPlusIcon } from "../../Assets/icons/RoundedPlusIcon.svg";
import EmptyAvatar from "../../Assets/icons/Default.png";
import AvatarRemove from "../../Assets/icons/avatarRemove.png";
import EmptyJpg from "../../Assets/icons/EmptyJpg.png";
import DropZone from "../../Utils/dropzone";
import Close from "@mui/icons-material/Close";
import axios from "axios";
import ApiService from "../../AxiosConfig/ApiService.js";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography variant="p">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTableRow = styled(TableRow)({
  ":nth-of-type(odd)": {
    backgroundColor: "#F5F8FF",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F2C6F",
  fontSize: "12xp",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: [theme.palette.common.white],
    fontSize: "12px",
    textAlign: "left",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    padding: "8px 12px",
    textAlign: "left",
  },

  [theme.breakpoints.up("md")]: {
    [`&.${tableCellClasses.body}`]: {
      fontSize: "14px",
      padding: "16px",
    },
    [`&.${tableCellClasses.head}`]: {
      fontSize: "14px",
    },
  },
}));
const tabs = [
  { id: 1, title: " Details" },
  { id: 2, title: " Review " },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BasicTabs = ({
  addEmployee,
  empAddStatus,
  levelDatas,
  unitDatas,
  roleData,
  getAllRoles,
  getAllLevels,
  getUnitsByLevelid,
  setStatusNull,
  getAssignedUnit,
  getAssignedLevel,
  assignedLevelList,
  assignedUnitList,
  getStaffLevel,
  levelList,
  assignedUnitsList,
}) => {
  const { tabvalue } = useParams();
  const [snackbar, setSnackbar] = React.useState({
    status: false,
    type: "error",
    message: "",
  });
  const inputImg = useRef();
  const [keyPress, setKeyPress] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [supportingFiles, setSupportingFiles] = useState(null);
  const [isToggle, setIsToggle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [imageErr, setImageErr] = useState(false);

  const [onBoardDetails, setOnBoardDetails] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    jobTitle: "",
    tenantRole: "",
    mobileNumber: "",
    mobileCode: "",
    accessLevelNumber: "",
    accessUnitNumber: "",
    assignedLevelNumber: [],
    assignedUnitNumber: [],
  });

  console.log(onBoardDetails);

  const [onBoardErrors, setOnBoardErrors] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    jobTitle: "",
    mobileNumber: "",
    tenantRole: "",
    accessStartDate: "",
    accessLevelNumber: "",
    accessUnitNumber: "",
    assignedLevelNumber: [],
    assignedUnitNumber: [],
    selectedUnits: [],
  });

  const [mobileBlur, setMobileBlur] = React.useState(false);
  const [emailBlur, setEmailBlur] = React.useState(false);
  const [noError, setNoError] = React.useState();
  const handleClose = () => setOpenModal(false);
  const [unit, setUnit] = React.useState("");
  const [unitNumber, setUnitNumber] = React.useState("");
  console.log(unitNumber);
  const [assignUnitNumber, setAssignUnitNumber] = useState("");
  const [administratorCheck, setAdministratorCheck] = React.useState(false);
  const [fireWardenCheck, setFireWardenCheck] = React.useState(false);
  const [accStartDate, setAccStartDate] = React.useState({
    startDate: null,
    currentDate: new Date(),
    dateStr: "",
  });
  const [level, setLevel] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [time, setTime] = useState({
    hour: "",
    minute: "",
    am_pm: "",
    timeStr: "",
  });
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [allUnits, setAllUnits] = useState([]);
  const [levels, setLevels] = React.useState("");
  const [units, setUnits] = React.useState("");
  const [items, setItems] = React.useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [assignedCompanyUnits, setAssignedCompanyUnits] = useState([]);
  const [filteredAssignedCompanyUnits, setFilteredAssignedCompanyUnits] =
    useState([]);
  const [itemsError, setItemsError] = React.useState();
  const [validationErr, setValidationErr] = useState("");
  const [levelValidation, setLevelValidation] = useState([]);
  const [modalData, setModalData] = useState({});

  const handleDelete = (id) => () => {
    const itemFilter = items.filter((val) => val.assigned_units_id !== id);
    setItems(itemFilter);
    const selectedFilters = selectedItems.filter((val) => val !== id);
    setSelectedItems(selectedFilters);
  };

  useEffect(() => {
    setTime({ ...time, timeStr: getLastUpdatedTime() });
    getLastUpdatedTime();
    getAllRoles();
    getAllLevels();
    getAssignedLevel();
    getStaffLevel();
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("userToken");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/getAllAssignedLevelsUnitsList`,
        config
      )
      .then((res) => {
        setItems(res.data?.response_data);
        const selectedItems = res.data?.response_data.map(
          (item) => item.assigned_units_id
        );
        setSelectedItems(selectedItems);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    ApiService()
      .get(`${process.env.REACT_APP_API_URL}/getFRconfig`)
      .then((res) => setModalData(res.data?.response_json?.fr_contents))
      .catch((err) => console.log(err));
  }, []);

  const handleAddClick = () => {
    setValidationErr("");
    if (
      items.find((val) => val?.assigned_units_id === units.assigned_units_id)
    ) {
      setLevels("");
      setUnits("");
    } else {
      setSelectedItems((prev) => [...prev, units.assigned_units_id]);
      const newItem = {
        assigned_units_id: units.assigned_units_id,
        level_name: `#${levels.level_name} ${"-" + units.unit_name}`,
      };
      setItems([...items, newItem]);
      setLevels("");
      setUnits("");
    }
  };
  useEffect(() => {
    if (empAddStatus?.response_status == "fail" && keyPress) {
      setLoaderStatus(false);
      setSnackbar({
        message: empAddStatus?.message,
        color: "red",
        status: true,
      });
      setTimeout(() => {
        setSnackbar({
          message: "",
          color: "",
          status: false,
        });
        setKeyPress(false);
      }, 5000);
    }

    if (empAddStatus?.response_status === "success") {
      setLoaderStatus(false);
      setSnackbar({
        message: empAddStatus?.response_json?.success_message,
        color: "green",
        status: true,
      });
      setTimeout(() => {
        navigate(`/manage_employees/1`);
      }, 5000);
    }
    setStatusNull();
  }, [empAddStatus, keyPress]);

  useEffect(() => {
    setLevel(levelDatas);
  }, [levelDatas]);

  useEffect(() => {
    if (unitDatas?.length > 0) {
      setUnitData(unitDatas);
    }
  }, [unitDatas]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let getTabs = (newTabs) => {
    return (
      <Typography sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography
          variant="span"
          sx={{
            background: value + 1 === newTabs.id ? "#123FA9" : "#6F80A9",
            color: "#FFFFFF",
            fontWeight: 600,
            padding: { md: "2px 9px", xs: "4px 8px" },
            borderRadius: "50%",
            fontSize: { md: "14px", xs: "10px" },
          }}
        >
          {newTabs.id}
        </Typography>
        <Typography
          sx={{ textTransform: "none", fontSize: { md: "14px", xs: "12px" } }}
          variant="span"
        >
          {newTabs.title}
        </Typography>
      </Typography>
    );
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    const userNameRegex = /^[a-zA-Z_ ]*$/;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (name === "first_name" && value.match(userNameRegex)) {
      setOnBoardErrors((prevValue) => ({ ...prevValue, [name]: "" }));
    } else if (name === "first_name") {
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        [name]: "Invalid Input",
      }));
    }

    if (name === "last_name" && value.match(userNameRegex)) {
      setOnBoardErrors((prevValue) => ({ ...prevValue, [name]: "" }));
    } else if (name === "last_name") {
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        [name]: "Invalid Input",
      }));
    }

    if (name === "email" && value.match(emailRegex)) {
      setOnBoardErrors((prevValue) => ({ ...prevValue, [name]: "" }));
    } else if (name === "email") {
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        [name]: "Invalid Input",
      }));
    }

    if (name === "jobTitle" && value.match(userNameRegex)) {
      setOnBoardErrors((prevValue) => ({ ...prevValue, [name]: "" }));
    } else if (name === "jobTitle") {
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        [name]: "Invalid Input",
      }));
    }

    if (name === "tenantRole") {
      setOnBoardDetails((prevValue) => ({
        ...prevValue,
        mobileCode: "",
        mobileNumber: "",
        mobile: "",
      }));
    }

    setOnBoardErrors({ ...onBoardErrors, [name]: "" });
    setOnBoardDetails((prevValue) => ({ ...prevValue, [name]: value }));

    setTime({ ...time, timeStr: getLastUpdatedTime() });
  };

  const handleMobileInput = (value, name, country, event) => {
    setOnBoardDetails((prevValue) => ({
      ...prevValue,
      [name]: value,
      mobileCode: `+${country.dialCode}`,
      mobile: event?.target?.value?.slice(country.dialCode.length + 1),
    }));
  };

  const handleUnitNumber = (e) => {
    let { name, value } = e.target;

    if (name === "unit") {
      setUnit(value);
      setOnBoardErrors((prevValue) => ({ ...prevValue, accessUnitNumber: "" }));
      setOnBoardDetails((prevValue) => {
        let newValue = "#" + value;
        if (prevValue.accessUnitNumber.substring(0, 1) === "#") {
          return { ...prevValue, accessUnitNumber: newValue + " - " };
        } else {
          let update = newValue.concat(" - ", prevValue.accessUnitNumber);
          return { ...prevValue, accessUnitNumber: update };
        }
      });
    }

    if (name === "unitNumber") {
      setUnitNumber(value);
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        accessUnitNumber: "",
      }));
      setOnBoardDetails((prevValue) => {
        let update = prevValue.accessUnitNumber + value;
        return { ...prevValue, accessUnitNumber: update };
      });
    }

    if (name === "assignUnitNumber") {
      setAssignUnitNumber(value);
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        assignedUnitNumber: "",
      }));
      setOnBoardDetails((prevValue) => {
        let update = prevValue.assignedUnitNumber + value;
        return { ...prevValue, assignedUnitNumber: update };
      });
    }
  };

  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleDateFormat = (newDate) => {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let dateString = `${newDate.getDate()} ${
      months[newDate.getMonth()]
    } ${newDate.getFullYear()}`;

    setAccStartDate({ startDate: newDate, dateStr: dateString });
    setOpen(false);
    setOnBoardErrors((prevValue) => ({ ...prevValue, accessStartDate: "" }));
  };

  const handleCheck = (e) => {
    let { name, checked } = e.target;

    if (name === "administrator" && checked) {
      setAdministratorCheck(true);
      setOnBoardErrors((prevValue) => ({ ...prevValue, tenantRole: "" }));
    } else if (name === "administrator") {
      setAdministratorCheck(false);
    }

    if (name === "fireWarden" && checked) {
      setFireWardenCheck(true);
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        tenantRole: "",
      }));
    } else if (name === "fireWarden") {
      setFireWardenCheck(false);
    }
  };

  const handleDropImage = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setSupportingFiles({
        file: file,
        fileName: file.name,
        size: file.size,
        src: URL.createObjectURL(file), // Create a blob URL for image preview
      });
      setImageErr(false);
      e.target.value = null;
    }
  };
  const handleDragImage = (e) => {
    e.preventDefault();
  };

  const handleImage = () => {
    inputImg.current.click();
  };

  const handleInputImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSupportingFiles({
        file: file,
        fileName: file.name,
        size: file.size,
        src: URL.createObjectURL(file), // Create a blob URL for image preview
      });
      e.target.value = null;
      setImageErr(false);
    }
  };

  const removeSupportImage = () => {
    setSupportingFiles(null);
  };

  const validate = () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const firstnameRegex = /^[a-zA-Z_. ]{3,}$/;

    let noErrors = true;

    if (!onBoardDetails.first_name) {
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        first_name: "Should not be empty!",
      }));
      noErrors = false;
    }

    if (!onBoardDetails.first_name.match(firstnameRegex)) {
      const isNumericIncluded = containsNumbers(onBoardDetails.first_name);
      const nameLength = onBoardDetails.first_name.length;
      if (nameLength == 0) {
        setOnBoardErrors((prevValue) => ({
          ...prevValue,
          first_name: "Should not be empty!",
        }));
        noErrors = false;
      } else if (nameLength < 2) {
        setOnBoardErrors((prevValue) => ({
          ...prevValue,
          first_name: "First name must be 2 characters !",
        }));
        noErrors = false;
      } else if (isNumericIncluded) {
        setOnBoardErrors((prevValue) => ({
          ...prevValue,
          first_name: "First name should not contain numeric!",
        }));
        noErrors = false;
      }
    }

    if (!onBoardDetails.last_name) {
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        last_name: "Should not be empty!",
      }));
      noErrors = false;
    }
    if (!onBoardDetails.email) {
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        email: "Should not be empty!",
      }));
      setEmailBlur(true);
      noErrors = false;
    } else if (!onBoardDetails.email.match(emailRegex)) {
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        email: "Invalid Input",
      }));
      noErrors = false;
    }

    // if (
    //   !onBoardDetails.mobileNumber &&
    //   onBoardDetails.tenantRole == "Tenant Manager"
    // ) {
    //   setOnBoardErrors((prevValue) => ({
    //     ...prevValue,
    //     mobileNumber: "Should not be empty!",
    //   }));
    //   noErrors = false;
    //   setMobileBlur(true);
    // }

    // if (!onBoardDetails.jobTitle) {
    //   setOnBoardErrors((prevValue) => ({
    //     ...prevValue,
    //     jobTitle: "Should not be empty!",
    //   }));
    //   noErrors = false;
    // }

    if (!onBoardDetails.tenantRole) {
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        tenantRole: "Should not be empty",
      }));
      noErrors = false;
    } else {
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        tenantRole: "",
      }));
    }

    if (!accStartDate.startDate || accStartDate.startDate == null) {
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        accessStartDate: "Should not be empty!",
      }));
      noErrors = false;
    }

    if (!onBoardDetails.accessLevelNumber) {
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        accessLevelNumber: "Should not be empty!",
      }));
      noErrors = false;
    }

    if (!onBoardDetails.accessUnitNumber) {
      setOnBoardErrors((prevValue) => ({
        ...prevValue,
        accessUnitNumber: "Should not be empty!",
      }));
      noErrors = false;
    }
    if (items?.length <= 0) {
      setItemsError("Should not be empty");
      noErrors = false;
    }
    if (isToggle) {
      if (!supportingFiles?.src) {
        setImageErr("Please upload the image");
        noErrors = false;
      }
    }
    return noErrors;
  };
  const handleBackButton = () => {
    if (value !== 0) {
      setValue(0);
    }
    setOnBoardDetails({
      first_name: "",
      last_name: "",
      email: "",
      jobTitle: "",
      tenantRole: "",
      mobileNumber: "",
      accessLevelNumber: "",
      accessUnitNumber: "",
      assignedLevelNumber: "",
      assignedUnitNumber: "",
    });
    setAccStartDate({ startDate: null, dateStr: "" });
    setUnitNumber("");
    setSupportingFiles(null);
    setIsToggle(false);
    setItems([]);
    setNoError(true);
  };

  const handleNextButton = () => {
    if (validate()) {
      if (selectedItems.includes(unitNumber?.assigned_units_id)) {
        setNoError(true);
        setValue(1);
      } else {
        setValidationErr(
          "Please add the Default access unit number atleast one in access level field"
        );
      }
    }
  };

  const submitHandler = (e) => {
    setKeyPress(true);
    let roles = [];
    if (administratorCheck) {
      roles.push("administrator");
    }

    if (fireWardenCheck) {
      roles.push("firewarden");
    }

    let userData = JSON.parse(localStorage.getItem("userData"));
    // const level_array = selectedItems?.map((item) => item.assigned_units_id);

    let data = {
      email: onBoardDetails.email,
      access_start_date: moment(accStartDate.startDate).format("YYYY-MM-DD"),
      // job_title: onBoardDetails.jobTitle,
      last_name: onBoardDetails.last_name,
      first_name: onBoardDetails.first_name,
      level_no: onBoardDetails.accessLevelNumber.level_id,
      mobile_no: "",
      mobile_country_code: "",
      role: onBoardDetails.tenantRole,
      unit_no: unitNumber.unit_id,
      company_id: userData.company_id,
      created_by: userData.user_id,
      office_phone_number: onBoardDetails.mobile,
      office_country_code: onBoardDetails.mobile ? onBoardDetails.mobileCode : "",
      assigned_unit_id_str: JSON.stringify(selectedItems),
      // enable_fr: Number(0),
    };

    if (isToggle && supportingFiles?.size) {
      delete data?.enable_fr;
      const formData = new FormData();
      // formData.append("face_photo", supportingFiles?.file);
      // formData.append("enable_fr", Number(1));
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      addEmployee(formData, localStorage.getItem("userToken"));
    } else {
      addEmployee(data, localStorage.getItem("userToken"));
    }

    setLoaderStatus(true);
    setTimeout(() => {
      setLoaderStatus(false);
    }, 20000);
  };

  const handleAddUnits = (e) => {
    setItemsError("");
    setValidationErr("");
    setUnits(e.target.value);
  };

  const handleSubmit = () => {
    if (validate()) {
      navigate("/manage_employees/2", { state: true });
    } else {
      setValue(0);
    }
  };

  const handleChecked = () => {
    setChecked(!checked);
    setButtonDisabled(!buttonDisabled);
  };

  useEffect(() => {
    if (assignedCompanyUnits?.length > 0) {
      const uniqueUnits = [];
      const unitIds = new Set();
      assignedCompanyUnits.forEach((item) => {
        if (!unitIds.has(item.assigned_units_id)) {
          unitIds.add(item.assigned_units_id);
          uniqueUnits.push(item);
        }
      });
      setFilteredAssignedCompanyUnits(uniqueUnits);
    }
  }, [assignedCompanyUnits]);

  useEffect(() => {
    if (assignedUnitList?.length > 0)
      setAssignedCompanyUnits((prevUnits) => [
        ...prevUnits,
        ...assignedUnitList,
      ]);
  }, [assignedUnitList]);

  const handleToggleChange = () => {
    if (!isToggle) {
      setOpenModal(true);
    } else {
      setImageErr(false);
      setSupportingFiles(null);
      setIsToggle((prev) => !prev);
    }
  };

  const handleConfrim = () => {
    setImageErr(false);
    setSupportingFiles(null);
    setIsToggle((prev) => !prev);
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const ModalAlert = () => {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        sx={{ "& .MuiDialog-paper": { width: "60%" } }} // Fixed width for the dialog
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "25px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottom: "1px solid #e0e0e0", // Bottom border
            gap: "10px", // Gap between content
          }}
        >
          <Typography
            sx={{ fontWeight: "500", fontSize: "18px", color: "#0F2C6F" }}
          >
            {modalData?.fr_title ||
              "Do you want to turn on the facial recognition?"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              color: "#0F2C6F",
              marginTop: "15px",
            }}
            gutterBottom
          >
            {modalData?.fr_desc ||
              "  An update is available with new features, a faster experiences,fixes and more. Please update to continue using it."}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              borderRadius: "6px",
              p: "8px 10px",
              color: "#0F2C6F",
              borderColor: "#AAC3FF",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Inter",
              }}
            >
              {modalData?.fr_cancel_btn || "Cancel"}
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={handleConfrim}
            sx={{
              borderRadius: "6px",
              p: "8px 10px",
              textTransform: "capitalize",
              backgroundColor: "#123FA9",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Inter",
              }}
            >
              {modalData?.fr_confirm_btn || "Confirm and Turn On"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const IOSSwitch = styled((SwitchProps) => (
    <Switch
      checked={isToggle}
      onChange={handleToggleChange}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...SwitchProps}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#123FA9",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <React.Fragment>
      {loaderStatus && <Loader status={loaderStatus} />}
      {openModal && ModalAlert()}
      {snackbar.status && (
        <ToastAlert
          status={snackbar.status}
          message={snackbar.message}
          color={snackbar.color}
        />
      )}
      <Box sx={{ width: "100%", padding: "10px 0" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ background: "#FFFFFF" }}
          >
            <Tab
              label={
                <Box>
                  <span
                    style={{
                      backgroundColor: "#123FA9",
                      fontSize: "12px",
                      color: "#FFFFFF",
                      borderRadius: "50%",
                      padding: "3px 7px",
                    }}
                  >
                    1
                  </span>
                  <span
                    style={{
                      textTransform: "capitalize",
                      marginLeft: "3px",
                      color: "#123FA9",
                      fontSize: "14px",
                    }}
                  >
                    Details
                  </span>
                </Box>
              }
            />
            <Tab
              disabled={true}
              label={
                <Box>
                  <span
                    style={{
                      backgroundColor: "#123FA9",
                      fontSize: "12px",
                      color: "#FFFFFF",
                      borderRadius: "50%",
                      padding: "3px 7px",
                    }}
                  >
                    2
                  </span>
                  <span
                    style={{
                      textTransform: "capitalize",
                      marginLeft: "3px",
                      color: "#123FA9",
                      fontSize: "14px",
                    }}
                  >
                    Review
                  </span>
                </Box>
              }
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box
            sx={{
              background: "white",
              padding: { md: "25px", xs: "25px 15px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                my: 2,
              }}
            >
              <Box>
                <Typography
                  sx={{ color: "#0F2C6F", fontSize: "18px", fontWeight: 500 }}
                >
                  Personal Details
                </Typography>
                <Typography
                  sx={{
                    color: "#6F80A9",
                    fontSize: "14px",
                    fontWeight: 500,
                    mt: 1,
                  }}
                >
                  Tenant Manager will be the person who is authorised
                </Typography>
                <Typography
                  sx={{
                    color: "#6F80A9",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  to make payments on behalf of the company.
                </Typography>
              </Box>
              {/* <img
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "231px",
                  border: "1px solid #DBDFE9",
                }}
                src={supportingFiles?.src ? supportingFiles?.src : EmptyAvatar}
              /> */}
            </Box>

            <Box sx={{ padding: "24px 0" }}>
              <Grid container>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xs={12}
                  sx={{
                    paddingRight: { md: "100px", xs: "5px" },
                    padding: "10px 0",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#0F2C6F",
                      paddingBottom: "8px",
                    }}
                  >
                    First Name *
                  </InputLabel>
                  <TextField
                    name="first_name"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onChange={(e) => handleInputChange(e)}
                    value={onBoardDetails.first_name}
                    inputProps={{
                      sx: {
                        height: { md: "25px", sm: "20px", xs: "10px" },
                        fontSize: { md: "16px", sm: "14px", xs: "12px" },
                        color: "#0F2C6F",
                      },
                    }}
                  />
                  {onBoardErrors.first_name && (
                    <Typography sx={{ color: "#f44336" }}>
                      {onBoardErrors.first_name}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  sx={{
                    paddingRight: { md: "100px", xs: "5px" },
                    padding: "10px 0px 0px 0px",

                    width: "100%",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#0F2C6F",
                      paddingBottom: "8px",
                    }}
                  >
                    Last Name *
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    name="last_name"
                    value={onBoardDetails.last_name}
                    onChange={(e) => handleInputChange(e)}
                    inputProps={{
                      sx: {
                        height: { md: "25px", sm: "20px", xs: "10px" },
                        fontSize: { md: "16px", sm: "14px", xs: "12px" },
                        color: "#0F2C6F",
                      },
                    }}
                  />
                  {onBoardErrors.last_name && (
                    <Typography sx={{ color: "#f44336" }}>
                      {onBoardErrors.last_name}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  sx={{
                    paddingRight: { md: "100px", xs: "0px" },
                    width: "100%",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#0F2C6F",
                      padding: "12px 0 8px 0",
                    }}
                    onChange={(e) => handleInputChange(e)}
                  >
                    Work Email Address *
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    name="email"
                    onChange={(e) => handleInputChange(e)}
                    onFocus={() => {
                      setEmailBlur(false);
                    }}
                    onBlur={() => {
                      setEmailBlur(true);
                    }}
                    value={onBoardDetails.email}
                    inputProps={{
                      sx: {
                        height: { md: "25px", sm: "20px", xs: "10px" },
                        fontSize: { md: "16px", sm: "14px", xs: "12px" },
                        color: "#0F2C6F",
                      },
                    }}
                  />

                  {onBoardErrors.email && emailBlur && (
                    <Typography sx={{ color: "#f44336" }}>
                      {onBoardErrors.email}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  sx={{
                    paddingRight: { md: "100px", xs: "5px" },
                    // padding: "10px 0",

                    width: "100%",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#0F2C6F",
                      padding: "12px 0 8px 0",
                    }}
                  >
                    Tenant Role *
                  </InputLabel>

                  <Select
                    sx={{ width: "100%" }}
                    name="tenantRole"
                    value={onBoardDetails.tenantRole}
                    onChange={(e) => handleInputChange(e)}
                  >
                    {roleData.length > 0
                      ? roleData.map((item) => (
                          <MenuItem value={`${item.role}`}>
                            {item.role.toUpperCase()}
                          </MenuItem>
                        ))
                      : ""}
                  </Select>

                  {onBoardErrors.tenantRole && (
                    <Typography sx={{ color: "#f44336" }}>
                      {onBoardErrors.tenantRole}
                    </Typography>
                  )}
                </Grid>
                {onBoardDetails?.tenantRole === "Tenant Manager" && (
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    sx={{
                      paddingRight: { md: "100px", xs: "5px" },
                      width: "100%",
                      paddingTop: "10px",
                    }}
                  >
                    <InputLabel
                      style={{
                        padding: "12px 0 0px 0",
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#0F2C6F",
                      }}
                    >
                      Mobile Number
                    </InputLabel>
                    <Box
                      sx={{
                        border: "1px solid",
                        borderColor: "#c4c4c4",
                        borderRadius: "6px",
                        margin: { md: "8px 0", xs: "2px 0" },
                      }}
                    >
                      <PhoneInput
                        name="mobile"
                        inputProps={{
                          name: "mobile",
                          required: true,
                          autoComplete: "off",
                        }}
                        value={onBoardDetails.mobileNumber}
                        onChange={(mobile, country, event) => {
                          handleMobileInput(
                            mobile,
                            "mobileNumber",
                            country,
                            event
                          );
                        }}
                        onFocus={() => setMobileBlur(false)}
                        onBlur={() => setMobileBlur(true)}
                        country="sg"
                        inputStyle={{
                          width: "100%",
                          background: "#fff",
                          borderRadius: "6px",
                          outline: "none",
                          border: "none",
                          borderLeft: "white",
                          fontSize: "14px",
                        }}
                        containerStyle={{
                          margin: "inherit",
                          borderRight: "1px solid",
                          borderColor: "#c4c4c4",
                        }}
                        buttonStyle={{
                          border: "none",
                          background: "#fff",
                          borderLeft: "white",
                          borderRadius: "6px",
                        }}
                      />
                    </Box>
                    {onBoardErrors.mobileNumber && mobileBlur && (
                      <Typography sx={{ color: "#f44336" }}>
                        {onBoardErrors.mobileNumber}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid container>
                <Box sx={{ width: "100%", margin: "25px 0" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "#0F2C6F",
                    }}
                  >
                    Access Details *
                  </Typography>
                </Box>

                <Grid container sx={{ width: "100%", display: "flex" }}>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      paddingRight: { sm: "18px", xs: "5px" },
                      padding: "10px 0",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ position: "relative" }}>
                      <Typography
                        sx={{
                          color: "#0F2C6F",
                          fontSize: "14px",
                          fontWeight: 600,
                          mb: 1,
                        }}
                      >
                        Access Start Date *
                      </Typography>
                      <DatePicker
                        components={{
                          OpenPickerIcon: DatePickerIcon,
                        }}
                        disablePast
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            onKeyDown={(e) => preventDatePickerKeyboard(e)}
                          />
                        )}
                        inputFormat="dd/MM/yyyy"
                        value={accStartDate.startDate}
                        onChange={(newDate) => handleDateFormat(newDate)}
                      />
                    </Box>
                    {onBoardErrors.accessStartDate && (
                      <Typography sx={{ color: "#f44336" }}>
                        {onBoardErrors.accessStartDate}
                      </Typography>
                    )}
                  </Grid>

                  <Grid
                    item
                    sm={6}
                    xs={12}
                    sx={{
                      paddingRight: { md: "100px", xs: "5px" },
                      padding: "10px 0",

                      width: "100%",
                    }}
                  >
                    <InputLabel
                      sx={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#0F2C6F",
                        paddingBottom: "8px",
                      }}
                    >
                      Office Unit Number *
                    </InputLabel>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <FormControl
                        sx={{
                          position: "relative",
                        }}
                      >
                        <Select
                          name="accessLevelNumber"
                          onChange={(e) => {
                            setOnBoardDetails({
                              ...onBoardDetails,
                              accessLevelNumber: e.target.value,
                            });
                            setOnBoardErrors({
                              ...onBoardErrors,
                              accessLevelNumber: "",
                            });
                          }}
                          value={onBoardDetails.accessLevelNumber}
                          sx={{
                            height: { md: "inherit", sm: "55px", xs: "45px" },
                            width: "80px",
                            color: "#0F2C6F",
                            fontSize: "14px",
                            color: "#0F2C6F",
                          }}
                        >
                          {level.map((value, index) => (
                            <MenuItem
                              value={value}
                              key={index}
                              onClick={() =>
                                getUnitsByLevelid({ level_id: value.level_id })
                              }
                            >
                              {value.level_name}
                            </MenuItem>
                          ))}
                        </Select>
                        {onBoardErrors.accessLevelNumber && (
                          <Typography sx={{ color: "#f44336" }}>
                            {onBoardErrors.accessLevelNumber}
                          </Typography>
                        )}
                      </FormControl>

                      {unitData[0] === "No data found" ? (
                        <OutlinedInput
                          sx={{ ml: 10, width: "100%" }}
                          value="No units found"
                          disabled
                        />
                      ) : (
                        <FormControl
                          sx={{
                            display: "flex",
                            width: "60%",
                            marginLeft: "18px",
                          }}
                        >
                          <Select
                            name="unitNumber"
                            onChange={(e) => {
                              handleUnitNumber(e);
                            }}
                            sx={{
                              height: { md: "inherit", sm: "55px", xs: "45px" },
                              fontSize: "14px",
                              color: "#0F2C6F",
                            }}
                            value={unitNumber}
                          >
                            {unitData.map((value, index) => (
                              <MenuItem value={value} key={index}>
                                {value.unit_name}
                              </MenuItem>
                            ))}
                          </Select>

                          {onBoardErrors.accessUnitNumber && (
                            <Typography sx={{ color: "#f44336" }}>
                              {onBoardErrors.accessUnitNumber}
                            </Typography>
                          )}
                        </FormControl>
                      )}
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      paddingRight: { md: "100px", xs: "5px" },
                      padding: "10px 0",

                      width: "100%",
                    }}
                  >
                    <InputLabel
                      sx={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#0F2C6F",
                        paddingBottom: "8px",
                      }}
                    >
                      Access level and units *
                    </InputLabel>
                    <Box>
                      <Box sx={{ display: "flex", width: "100%" }}>
                        <FormControl sx={{ display: "flex", width: "50%" }}>
                          <Select
                            value={levels}
                            onChange={(e) => {
                              setLevels(e.target.value);
                              setValidationErr("");
                              setItemsError("");
                            }}
                            displayEmpty
                            placeholder="level"
                            style={{ marginRight: "10px" }}
                            sx={{
                              height: { md: "inherit", sm: "55px", xs: "45px" },
                              color: "#0F2C6F",
                              fontSize: "14px",
                              color: "#0F2C6F",
                            }}
                          >
                            {assignedLevelList?.map((value, index) => (
                              <MenuItem
                                value={value}
                                key={index}
                                onClick={() =>
                                  getAssignedUnit({ level_id: value.level_id })
                                }
                              >
                                {value.level_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl
                          sx={{
                            display: "flex",
                            width: "50%",
                            marginLeft: "18px",
                          }}
                        >
                          <Select
                            value={units}
                            onChange={handleAddUnits}
                            displayEmpty
                            placeholder="unit"
                            style={{ marginRight: "10px" }}
                            sx={{
                              height: { md: "inherit", sm: "55px", xs: "45px" },
                              color: "#0F2C6F",
                              fontSize: "14px",
                              color: "#0F2C6F",
                            }}
                          >
                            {assignedUnitList?.map((value, index) => (
                              <MenuItem value={value} key={index}>
                                {value.unit_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <IconButton
                          color="primary"
                          onClick={handleAddClick}
                          disabled={!levels || !units}
                        >
                          <RoundedPlusIcon />
                        </IconButton>
                      </Box>
                      {validationErr && (
                        <Typography sx={{ color: "#f44336" }}>
                          {validationErr}
                        </Typography>
                      )}
                      {itemsError != "" && (
                        <Typography sx={{ color: "#f44336" }}>
                          {itemsError}
                        </Typography>
                      )}
                      <Box
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {items.map((item, index) => (
                          <Chip
                            key={index}
                            sx={{
                              color: "#fff",
                              background: "#123FA9",
                              "& .MuiChip-deleteIcon": {
                                color: "#fff",
                                "&:hover": {
                                  color: "#B0B0B0",
                                },
                              },
                              fontSize: { md: "14px", xs: "10px" },
                            }}
                            label={item?.level_name}
                            onDelete={handleDelete(item?.assigned_units_id)}
                          />
                        ))}
                      </Box>
                    </Box>

                    <Grid sx={{ mt: 3 }} item xs={12} sm={6} />
                    {/* <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "fit-content",
                            borderRadius: "6px",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "-0.25px",
                            fontFamily: "inter",
                            color: "#0F2C6F",
                            padding: "0 8px",
                            backgroundColor: "#CFDDFE",
                          }}
                        >
                          NEW
                        </Typography>
                        <Box sx={{ display: "flex", gap: "20px" }}>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "-0.25px",
                              fontFamily: "inter",
                              color: "#0F2C6F",
                            }}
                          >
                            Facial Recognition
                          </Typography>
                          <IOSSwitch defaultChecked={false} />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "-0.25px",
                              fontFamily: "inter",
                              color: "#6F80A9",
                            }}
                          >
                            Automatically activate the facial recognition
                            feature for this tenant upon entering the building.
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "-0.25px",
                              fontFamily: "inter",
                              color: "#6F80A9",
                            }}
                          >
                            You can skip this step and let the tenant upload it
                            by themselves.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid> */}

                    {isToggle && (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                            mt: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "24px",
                              letterSpacing: "-0.25px",
                              fontFamily: "inter",
                              color: "#0F2C6F",
                            }}
                          >
                            Photo Requirements:
                          </Typography>
                          <ol style={{ color: "#0F2C6F" }}>
                            <li style={{ color: "inherit" }}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "-0.25px",
                                  fontFamily: "inter",
                                  color: "#0F2C6F",
                                }}
                              >
                                Please submit a single color photograph.
                              </Typography>
                            </li>
                            <li style={{ color: "inherit" }}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "-0.25px",
                                  fontFamily: "inter",
                                  color: "#0F2C6F",
                                }}
                              >
                                Ensure the photo was taken within the last six
                                months.
                              </Typography>
                            </li>
                            <li style={{ color: "inherit" }}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "-0.25px",
                                  fontFamily: "inter",
                                  color: "#0F2C6F",
                                }}
                              >
                                Use a clear image showing the entire face; avoid
                                selfies.
                              </Typography>
                            </li>
                            <li style={{ color: "inherit" }}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "-0.25px",
                                  fontFamily: "inter",
                                  color: "#0F2C6F",
                                }}
                              >
                                Do not alter the photo using computer software,
                                phone apps, or filters.
                              </Typography>
                            </li>
                            <li style={{ color: "inherit" }}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "-0.25px",
                                  fontFamily: "inter",
                                  color: "#0F2C6F",
                                }}
                              >
                                The background should be white or off-white,
                                free from shadows, texture, or lines.
                              </Typography>
                            </li>
                          </ol>
                          <Box sx={{ padding: "16px 24px" }}>
                            <Typography
                              sx={{ color: "#0F2C6F", fontSize: "16px", mb: 2 }}
                            >
                              Supporting Materials(optional)
                            </Typography>
                            {supportingFiles?.size ? (
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  border: "2px solid #80808021",
                                  mb: 0.5,
                                  borderRadius: "5px",
                                }}
                              >
                                <List
                                  sx={{
                                    width: "100%",
                                    bgcolor: "background.paper",
                                    p: 0,
                                  }}
                                >
                                  <ListItem
                                    sx={{ pl: 0 }}
                                    secondaryAction={
                                      <IconButton
                                        edge="end"
                                        aria-label="delete"
                                      >
                                        <Box
                                          component="img"
                                          src={AvatarRemove}
                                          onClick={removeSupportImage}
                                        />
                                      </IconButton>
                                    }
                                  >
                                    <ListItemText
                                      primary={
                                        <Box
                                          sx={{
                                            ml: 1,
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Box
                                            component="img"
                                            src={EmptyJpg}
                                            sx={{
                                              width: "40px",
                                              height: "48px",
                                            }}
                                          />
                                          <Typography variant="p" sx={{ m: 2 }}>
                                            {supportingFiles.fileName}
                                            <span
                                              style={{
                                                color: "gray",
                                                fontSize: "15px",
                                              }}
                                            >{`     (${Math.floor(
                                              supportingFiles?.size / 1024
                                            )} of ${Math.floor(
                                              supportingFiles?.size / 1024
                                            )}KB)`}</span>
                                          </Typography>
                                        </Box>
                                      }
                                      secondary={""}
                                    />
                                  </ListItem>
                                </List>
                              </Grid>
                            ) : (
                              <>
                                <Grid
                                  container
                                  sx={{
                                    backgroundColor: imageErr
                                      ? "#FEE4E2"
                                      : "#EAF0FF",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      minHeight: 250,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      onDragOver={handleDragImage}
                                      onDrop={handleDropImage}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          color: " #0F2C6F",
                                          fontWeight: 600,
                                        }}
                                      >
                                        Drag and drop files here
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "12px",
                                          color: "#0F2C6F",
                                          fontWeight: 400,
                                        }}
                                      >
                                        Files Supported: svg, .png, .jpg (Max 25
                                        MB)
                                      </Typography>
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          backgroundColor: "white",
                                          color: "#0F2C6F",
                                          fontSize: "14px",
                                          mt: 2,
                                          border: "1px solid #AAC3FF",
                                          borderRadius: " 6px",
                                          "&:hover": {
                                            backgroundColor: "white",
                                          },
                                        }}
                                        onClick={handleImage}
                                      >
                                        Choose file
                                        <input
                                          type="file"
                                          accept=".jpg,.jpeg,.png,.svg,.gif"
                                          ref={inputImg}
                                          onChange={handleInputImage}
                                          hidden
                                        />
                                      </Button>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Typography sx={{ color: "#f44336", mt: 2 }}>
                                  {imageErr}
                                </Typography>
                              </>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          sx={{ padding: "30px 0", background: "#F9FAFA" }}
        >
          <Box
            sx={{
              padding: { md: "30px", xs: "20px" },
              background: "#FFFFFF",
            }}
          >
            <Box
              sx={{
                alignItems: "start",
                display: "flex",
                flexDirection: "column",
              }}
            ></Box>
            {noError ? (
              <>
                <Typography
                  sx={{
                    fontSize: { md: "14px", xs: "12px" },
                    fontWeight: 500,
                    color: "#0F2C6F",
                  }}
                >
                  Please ensure that all data provided are accurate to prevent
                  any delays in processing. You will be notified within 1-3
                  working days once checks has been processed. Once employee is
                  onboarded, they will receive an email in their work email
                  provided to download and install the Singland App for access.
                </Typography>
                <Grid
                  container
                  sx={{
                    border: "1px solid #DBDFE9",
                    borderRadius: "6px",
                    width: "80%",
                    pb: 3,
                    my: 3,
                    ml: 0.5,
                  }}
                  spacing={2}
                >
                  {supportingFiles?.size && (
                    <Grid item xs={12} justifyContent="start">
                      <img
                        style={{
                          width: "120px",
                          height: "120px",
                          borderRadius: "50%",
                          border: "1px solid #DBDFE9",
                        }}
                        src={
                          supportingFiles?.src
                            ? supportingFiles?.src
                            : EmptyAvatar
                        }
                        alt="Personnel Avatar"
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "14px", color: "#0F2C6F" }}>
                      Access Start Date
                    </Typography>
                    <Typography sx={{ fontSize: "12px", color: "#0F2C6F" }}>
                      {accStartDate.dateStr}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "12px", color: "#0F2C6F" }}>
                      1 personnel
                    </Typography>
                    <Typography sx={{ fontSize: "12px", color: "#0F2C6F" }}>
                      Office Unit : #
                      {`${onBoardDetails.accessLevelNumber.level_name}-${unitNumber.unit_name}`}
                    </Typography>
                  </Grid>
                </Grid>

                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ padding: { md: "16px", xs: "4px" } }}>
                        <TableCell>
                          {" "}
                          <Checkbox
                            size="small"
                            checked={checked}
                            onClick={(e) => handleChecked(e)}
                          />{" "}
                        </TableCell>
                        <StyledTableCell>Employee Name</StyledTableCell>
                        <StyledTableCell>Work Email</StyledTableCell>
                        {onBoardDetails?.mobile && (
                          <StyledTableCell>Mobile Number</StyledTableCell>
                        )}
                        <StyledTableCell>Tenant Role</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Checkbox
                            size="small"
                            checked={checked}
                            onClick={(e) => handleChecked(e)}
                          />
                        </TableCell>
                        <StyledTableCell component="th" scope="row">
                          {`${onBoardDetails.first_name}  ${onBoardDetails.last_name}`}
                        </StyledTableCell>
                        <StyledTableCell>
                          {onBoardDetails.email}
                        </StyledTableCell>
                        {onBoardDetails?.mobile && (
                          <StyledTableCell>
                            +{onBoardDetails.mobileNumber}
                          </StyledTableCell>
                        )}
                        <StyledTableCell>
                          {onBoardDetails.tenantRole}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Box
                sx={{
                  textAlign: "center",
                  color: "#0F2C6F",
                  padding: "100px 0",
                  fontWeight: 500,
                  fontSize: { md: "24px", sm: "18px", xs: "14px" },
                }}
              >
                There is no data to display
              </Box>
            )}
          </Box>
        </TabPanel>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: 5,
          pb: 12,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <RefreshIcon />
          <Typography sx={{ color: "#9AA6C3", fontSize: "14px", ml: 1 }}>
            Last updated at {time.timeStr}
          </Typography>
        </Box>

        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#FFFFFF",
              color: "#0F2C6F",
              textTransform: "none",
              mr: 5,
              "&:hover": { backgroundColor: "#FFFFFF" },
              height: "50px",
              border: "1px solid #AAC3FF",
              borderRadius: "6px",
            }}
            onClick={handleBackButton}
          >
            Clear All
          </Button>
          {value === 0 ? (
            <Button
              variant="contained"
              sx={{
                height: "50px",
                background: "#0F2C6F",
                "&:hover": { background: "#0F2C6F" },
                textTransform: "none",
                borderRadius: "6px",
              }}
              onClick={() => handleNextButton()}
              type=""
            >
              Continue
            </Button>
          ) : (
            <Button
              disabled={buttonDisabled}
              variant="contained"
              sx={{
                height: "50px",
                background: "#0F2C6F",
                textTransform: "none",
              }}
              onClick={() => submitHandler()}
            >
              Submit
            </Button>
          )}
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CalendarPicker
              disablePast
              onChange={(newDate) => handleDateFormat(newDate)}
              type="text"
            />
          </LocalizationProvider>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    userMenus: state.UserReducer.userMenus,
    empAddStatus: state.CommonReducer.empAddStatus,
    levelDatas: state.CommonReducer.tenantOccupiedLeves,
    unitDatas: state.CommonReducer.tenantOccupiedUnits,
    roleData: state.CommonReducer.roleData,
    levelList: state.BMSStaffReducer.levelList,
    assignedLevelList: state.BMSStaffReducer.assignedLevelList,
    assignedUnitList: state.BMSStaffReducer.assignedUnitList,
    assignedUnitsList: state.BMSStaffReducer.assignedUnitsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addEmployee: (data, token) =>
      dispatch(commonAction.manageEmployee(data, token)),
    getAllLevels: () => dispatch(commonAction.getTenantAccessLevel()),
    getUnitsByLevelid: (data) =>
      dispatch(commonAction.getTenantAccessUnit(data)),
    setStatusNull: () => dispatch(commonAction.setStatusNull()),
    getAllRoles: () => dispatch(commonAction.getAllRoles()),
    getAssignedLevel: () => dispatch(BmsStaffAction.getAssignedLevel()),
    getAssignedUnit: (data) => dispatch(BmsStaffAction.getAssignedUnit(data)),
    getStaffLevel: () => dispatch(BmsStaffAction.getStaffLevel()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicTabs);
